@import 'src/assets/variables';
@import 'src/assets/mixins';

.response-field-array {
  display: flex;
  flex-direction: column;

  &__label {
    display: flex;
    align-items: center;
    height: 40px;
    font-size: 14px;
    line-height: 19px;
    margin-top: 11px;
  }

  &__field {
    display: flex;
    align-items: center;
    position: relative;
    height: 42px;
    margin-top: 11px;
    $field: &;

    &__buttons {
      display: flex;
      margin-left: 8px;
    }
  }
}
