.campaign-create-targets {
  display: flex;
  flex-direction: column;

  &__title-link {
    font-size: 14px;
  }

  &__infographic {
    display: flex;
    margin: auto auto 0;
  }

  &__rerun-info {
    display: flex;
    text-align: center;
    margin: 0 auto;
    max-width: 80%;
  }

  &__tags {
    display: flex;
    margin-top: auto;
  }
}
