@import 'src/assets/variables';
@import 'src/assets/mixins';

.edit-node-action-form {
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100%;

  &__title {
    display: flex;
    flex-direction: column;
    letter-spacing: 0.15px;
    font-size: 18px;
    line-height: 25px;

    @include themify() {
      color: ('color-white');
    }
  }

  &__sub-title {
    font-size: 16px;
    line-height: 40px;
    letter-spacing: 0.15px;
    margin: 0 0 12px 0;
  }

  &__sub-sub-title {
    font-size: 14px;
    line-height: 40px;
    letter-spacing: 0.15px;
    margin: 0 0 12px 0;
  }

  &__buttons {
    display: flex;
    justify-content: flex-end;
    margin-top: auto;

    & button {
      min-width: 175px;
    }

    &__right {
      display: flex;
      margin-left: auto;

      & > *:nth-child(2) {
        margin-left: 12px;
      }
    }
  }

  &__row {
    display: flex;
    align-items: center;
    margin-bottom: 12px;

    &__label {
      display: flex;
      flex: 3;
      font-size: 14px;
      line-height: 19px;
      letter-spacing: 0.15px;
      margin-right: 25px;

      @include themify() {
        color: t('color-grey');
      }
    }

    &__button {
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex: 7;
      font-size: 14px;
      line-height: 19px;
      letter-spacing: 0.15px;
      cursor: pointer;
      text-align: left;

      &:disabled {
        cursor: not-allowed;
      }

      &__icon {
        margin-left: 10px;

        @include themify() {
          filter: t('img-invert');
        }
      }

      @include themify() {
        color: t('color-white');
        border-bottom: 1px solid t('color-grey');
      }
    }
  }

  &__switch {
    flex: 7;
  }
}
