@import 'src/assets/mixins';

.mkit-create-contact-form {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  font-family: 'notosans-regular';

  @include themify() {
    color: t('color-grey');
  }

  &__header {
    display: flex;
    flex-direction: column;
    line-height: 19px;
    letter-spacing: 0.15px;

    h1 {
      font-size: 22px;
      line-height: 30px;

      @include themify() {
        color: t('color-white');
      }
    }

    &__subheader {
      font-size: 14px;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    flex: 1;
  }

  &__buttons {
    display: flex;
    justify-content: space-between;
    margin-top: 30px;
  }

  .form-field {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;

    & > span {
      width: 73%;
    }
  }
}
