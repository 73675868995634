@import 'src/assets/variables';
@import 'src/assets/mixins';

.progress {
  display: flex;
  align-items: center;
  height: 24px;
  min-height: 24px;
  padding: 2px;
  margin-top: 2px;
  box-sizing: border-box;
  border-radius: 12px;
  position: relative;
  overflow: hidden;
  justify-content: space-between;
  font-size: 12px;
  line-height: 25px;
  $progress: &;

  &__label {
    margin-left: 12px;
    z-index: 1;

    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;

    &_first-capital {
      text-transform: uppercase;
    }
  }

  &__percent {
    margin-right: 12px;
    z-index: 1;
    font-family: $primary-font-bold;
  }

  &__bar {
    position: absolute;
    height: 20px;
    border-radius: 12px;
    transition: width 3s ease-in-out;
    animation: 0.5s switcher ease-in-out;
    transform-origin: left;
  }

  @include themify() {
    &_blue {
      background-color: rgba(t('accent-color'), 0.2);

      #{$progress}__bar {
        background-color: t('accent-color');
      }
    }

    &_green {
      background-color: rgba(t('color-green'), 0.2);

      #{$progress}__bar {
        background-color: t('color-green');
      }
    }

    &_red {
      background-color: rgba(t('color-attention'), 0.2);

      #{$progress}__bar {
        background-color: t('color-attention');
      }
    }

    &_grey {
      background-color: rgba(t('color-grey'), 0.2);

      #{$progress}__bar {
        background-color: t('color-grey');
      }
    }
  }
}
