.two-fa-voice-screen-container {
	display: flex;
	flex: 1;
	position: relative;
	overflow: hidden;
	padding: 1px;

	> div {
		display: flex;
		flex: 1;
	}
}
