@import 'src/assets/variables';
@import 'src/assets/mixins';

.mkit-ag-column-header {
  display: flex;
  align-items: center;
  // flex-direction: row-reverse;
  width: 100%;

  .mkit-ag-header-cell-label {
    display: flex;
    align-self: stretch;
    flex: unset;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;

    .mkit-ag-header-cell-text {
      font-size: 14px;
      font-family: $primary-font-regular;
      font-variant: small-caps;
    }

    &_button {
      cursor: pointer;
    }
  }

  .ag-header-sort-indicator {
    @include themify() {
      filter: t('img-invert');
    }
  }

  &__filter {
    margin-left: 15px;
    cursor: pointer;
    width: 14px;
    height: 16px;
    min-width: 14px;
    opacity: 0;
    background-image: url(../../../assets/icons/watering-can.svg);
    background-position: center;
    background-repeat: no-repeat;

    @include themify() {
      filter: t('img-invert');
    }

    &:hover {
      opacity: 1;
    }
  }

  &__reset-filter {
    display: none;
    width: 20px;
    height: 20px;
    margin-left: 5px;
    opacity: 0.6;
    cursor: pointer;
    transition: opacity 0.3s ease-in-out;

    @include themify() {
      filter: t('img-invert');
    }

    &:hover {
      opacity: 1;
    }
  }
}

.mkit-ag-table .ag-header-cell {
  &:not(:last-of-type) {
    border-right: none;
  }
  // когда меню открыто, иконка фильтра не прозрачная
  &.ag-column-menu-visible .mkit-ag-column-header__filter {
    opacity: 1;
  }

  // когда фильтр применен, иконка фильтра меняется и становится прозрачной на 0.6
  &.ag-header-cell-filtered .mkit-ag-column-header__filter {
    opacity: 0.6;
    background-image: url(../../../assets/icons/filter.svg);
  }

  &.ag-column-menu-visible {
    background-color: initial;
  }

  &:hover .mkit-ag-column-header__filter {
    opacity: 0.6;

    &:hover {
      opacity: 1;
    }
  }

  &.ag-header-cell-filtered .mkit-ag-column-header__reset-filter {
    display: flex;
    @include themify() {
      filter: t('img-invert');
    }
  }
}

.mkit-ag-header-sort-indicator {
  display: none;
  width: 10px;
  min-width: 10px;
  height: 10px;
  background: url('../../../assets/icons/sort-arrow.svg') center no-repeat;
  background-size: 100% 100%;
  margin-left: 10px;

  &_asc,
  &_desc {
    display: block;
  }

  &_desc {
    transform: rotate(180deg);
  }
}
