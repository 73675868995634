@import 'src/assets/variables';
@import 'src/assets/mixins';

.carousel {
  @include themify() {
    &__buttons {
      display: flex;
      justify-content: space-between;
      align-items: center;
      // margin: 24px 0 10px 0;
      font-size: 18px;
      line-height: 25px;

      &__prev,
      &__next {
        display: flex;
        align-items: center;
        width: 25px;
        height: 25px;
        cursor: pointer;
        opacity: 0.6;
        transition: opacity 0.3s ease-in-out;

        &__icon {
          margin: 6px;
          width: 8px;
          height: 12px;
          align-self: center;
          filter: t('img-invert');
        }

        &:hover {
          opacity: 1;
        }

        &:disabled {
          opacity: 0.6;
          cursor: default;
        }
      }

      &__prev__icon {
        margin-left: 12px;
        transform: rotate(180deg);
      }
    }
  }
}
