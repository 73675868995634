@import 'src/assets/variables';
@import 'src/assets/mixins';

.mkit-whats-app-template-card {
  display: flex;
  flex-direction: column;
  padding: 18px;
  overflow-y: auto;
  flex: 1;

  &__buttons,
  &__text-block,
  &__header {
    display: flex;
    width: 100%;
    padding: 5px;
    box-sizing: border-box;
    background-color: $color-white;
    border-radius: 8px;
    border: 2px solid $color-border;

    &_open-bottom {
      border-radius: 8px 8px 0 0;
      border-bottom: none;
    }
  }

  &__buttons,
  &__text-block {
    &_open-top {
      border-radius: 0 0 8px 8px;
      border-top: none;
    }

    &_open-bottom {
      border-radius: 8px 8px 0 0;
      border-bottom: none;
    }

    &_open {
      border-radius: 0;
      border-top: none;
      border-bottom: none;
    }
  }

  &__footer {
    font-size: 12px;
    color: $color-grey_light;
  }

  &__buttons {
    flex-direction: column;
    border-top: 1px solid $color-border;
    border-radius: 0 0 8px 8px;

    &_reply {
      background-color: transparent;
      border-radius: 0;
      border: none;
      padding: 5px 0;
    }

    &__item {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 5px 10px;
      color: $accent-color;
      font-family: $roboto-font-bold;
      margin-bottom: 3px;

      &_reply {
        background-color: $color-grey;
        border-radius: 8px;
        padding: 8px 10px;
      }
    }
  }

  &__title {
    font-size: 16px;
    font-family: $roboto-font-bold;
    text-transform: capitalize;

    &:first-of-type {
      margin-top: 10px;
    }
  }

  &__row {
    display: flex;
    padding-bottom: 5px;

    &__label {
      margin-right: 8px;
    }
  }
}
