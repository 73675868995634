@import 'src/assets/variables';
@import 'src/assets/mixins';

body {
  display: flex;
  margin: 0;
  // font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  //   'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  //   sans-serif;
  // font-family: 'notosans-regular';
  //font-family: Helvetica, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: $primary-font-regular !important;
  letter-spacing: 0.15px;

  // background: linear-gradient(0deg, #2B313E, #2B313E);
  @include themify() {
    background: t('primary-color');
    color: t('color-white');
  }

  ::-webkit-scrollbar,
  &::-webkit-scrollbar {
    width: 10px;
    height: 10px;
  }

  ::-webkit-scrollbar-corner,
  &::-webkit-scrollbar-corner,
  ::-webkit-scrollbar-track,
  &::-webkit-scrollbar-track {
    background-color: transparent;
  }

  ::-webkit-scrollbar-thumb,
  &::-webkit-scrollbar-thumb {
    border-radius: 5px;
  }

  @include themify() {
    ::-webkit-scrollbar-thumb,
    &::-webkit-scrollbar-thumb {
      background-color: t('color-dark-grey-opacity');
    }
  }

  //@media (max-width: 1380px) {
  //  zoom: 77%;
  //}

  div[class*="bp3-portal"] {
    button,
    input,
    textarea {
      font-family: var(--inter) !important;
    }
  }
}

#root {
  display: flex;
  flex: 1;
  min-width: 0;
  background-color: initial !important;
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  word-wrap: break-word;
  // word-break: break-all;
}

a {
  text-decoration: underline;
  letter-spacing: 0.15px;

  @include themify() {
    color: t('accent-color');
  }

  &:hover {
    text-decoration: none;
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

h1, h2, h3, h4, h5, h6 {
  @include themify() {
    color: t('color-white');
  }
}

h1 {
  font-size: 22px;
  font-weight: normal;
  margin-bottom: 17px;
}

.Toastify {
  &__toast-container {
    min-width: 172px;
    max-width: 300px;

    &--bottom-center {
      left: 72px !important;
      transform: none;
    }
  }

  &__progress-bar {
    height: 2px;
    top: 0;

    @include themify() {
      background-color: $color-white;
    }
  }

  &__close-button {
    color: transparent !important;
    width: 12px;
    height: 12px;
    background: url("assets/icons/thin-cross.svg") center no-repeat !important;
    opacity: 0.5;
    margin: 2px 2px 0 0;

    &:hover {
      opacity: 1;
    }
  }

  &__toast-body {
    display: flex;
    position: relative;
    padding: 15px 10px;
    letter-spacing: 0.15px;
    font-size: 14px;
    line-height: 19px;
    word-break: break-word;

    @include themify() {
      color: $color-white;
    }
  }

  &__toast {
    box-sizing: border-box;
    border-top: none;
    border-radius: 10px !important;

    @include themify() {
      background: t('color-white');
    }

    &--success {
      @include themify() {
        background-color: t('color-green') !important;
      }
    }

    &--warning {
      @include themify() {
        background-color: t('accent-color') !important;
      }
    }

    &--error {
      @include themify() {
        background-color: t('color-attention') !important;
      }
    }
  }
}

.muuri-dragbox {
  position: fixed;
  left: 0;
  top: 0;
  width: 0;
  height: 0;
  z-index: 1000;
}
.muuri {
  min-height: 100%;
}
.muuri-item {
  border: 2px dashed transparent;
  border-radius: 2px;
  cursor: grab;

  &-dragging {
    cursor: grabbing;
  }
}

// todo убрать, когда выпилим либу antd с её вездесущими стилями
button,
input,
textarea {
  border: unset;
  background-color: unset;
  font-family: $primary-font-regular !important;
}

button:focus,
textarea:focus,
input:focus {
  outline: unset;
}
//

@font-face {
  font-family: 'notosans-italic';
  font-style: normal;
  font-weight: normal;
  src: local('NotoSans-Italic'), url('fonts/Noto_Sans/NotoSans-Italic.ttf') format('truetype');
}

@font-face {
  font-family: 'notosans-regular';
  font-style: normal;
  font-weight: normal;
  src: local('NotoSans-Regular'), url('fonts/Noto_Sans/NotoSans-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'notosans-bold';
  font-style: normal;
  font-weight: normal;
  src: local('NotoSans-Bold'), url('fonts/Noto_Sans/NotoSans-Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'roboto-regular';
  font-style: normal;
  font-weight: normal;
  src: local('Roboto-Regular'), url('fonts/Roboto/Roboto.ttf') format('truetype');
}

@font-face {
  font-family: 'roboto-italic';
  font-style: normal;
  font-weight: normal;
  src: local('Roboto-Italic'), url('fonts/Roboto/Robotoitalic.ttf') format('truetype');
}

@font-face {
  font-family: 'roboto-bold';
  font-style: normal;
  font-weight: normal;
  src: local('Roboto-Bold'), url('fonts/Roboto/Robotobold.ttf') format('truetype');
}

@keyframes shine {
  0% {
    opacity: 0.1;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0.1;
  }
}

.splash-screen {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: #202024;

  &__logo {
    position: relative;
    overflow: hidden;

    &::before {
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      background-image: linear-gradient(45deg, transparent, rgba(255, 255, 255, 1), transparent);
      animation: shine 2s linear infinite;
      z-index: 1;

      -webkit-mask-image: url('./assets/icons/logo-splash.svg');
      mask-image: url('./assets/icons/logo-splash.svg');
    }
  }
}

.toast {
  z-index: 9999 !important;
}

.app-spinner {
  flex: 1;
}
