@import 'src/assets/variables';
@import 'src/assets/mixins';

.time-picker-field {
  display: flex;
  align-items: center;
  $time-picker-field: &;

  &_disabled {
    opacity: 0.6;
    cursor: not-allowed;
  }

  &__label {
    font-size: 14px;
    line-height: 19px;
    margin-right: 8px;
  }

  @include themify() {
    &__label {
      color: t('color-grey');
    }

    &__input {
      border-color: t('color-grey');

      &__overlay {
        background-color: t('secondary-color');

        &_primary-color {
          background-color: t('primary-color');
        }

        &__time-border {
          background-color: t('primary-white-color');
        }
      }
    }
  }

  &__input {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex: 1;
    height: 36px;
    padding: 6px 8px;
    border: 1px solid;
    border-radius: 4px;
    position: relative;

    input {
      width: 100%;
      font-family: $primary-font-regular;
    }

    &__icon {
      width: 24px;
      height: 24px;

      @include themify() {
        filter: t('img-white');
      }
    }

    &__overlay {
      position: absolute;
      left: 0;
      right: 0;
      top: 36px;
      z-index: 2;
      border-radius: 4px;
      display: none;
      flex-direction: column;
      cursor: default;
      padding: 9px 12px;
      touch-action: none;
      $overlay: &;

      &__time {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 18px;
      }

      &__time-border {
        width: 1px;
        height: 16px;
        cursor: default;
        opacity: 0.4;
      }

      &_without-timezone #{$overlay}__time {
        margin-bottom: 0;
      }
    }
  }

  &_open #{$time-picker-field} {
    &__input__overlay {
      display: flex;
    }
  }
}
