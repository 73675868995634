@import 'src/assets/variables';
@import 'src/assets/mixins';

.mkit-list-filter-form {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 192px;
  max-height: 300px;
  width: 268px;
  padding: 12px;

  @include themify() {
    background: t('primary-color');
  }

  &__title {
    font-family: $primary-font-italic;
    font-size: 14px;
    margin-bottom: 12px;

    @include themify() {
      color: t('color-white');
    }
  }

  &__fields-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow-y: auto;
  }

  &__buttons {
    margin-top: 12px;
    display: flex;

    > button:first-child {
      margin-right: 13px;
    }
  }
}
