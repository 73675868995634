@import 'src/assets/mixins';

.side-menu {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 72px;
  z-index: 4;
  background-color: var(--bg15);
  @include themify() {
    box-shadow: t('box-shadow');
  }
  $side-menu: &;

  &__logo {
    padding: 21px 12px;
    margin-bottom: 9px;
    overflow: hidden;

    > img {
      height: 31px;
      max-width: 100%;
      object-fit: contain;
    }
  }

  &__button-open {
    position: absolute;
    top: 65px;
    right: -12px;
    box-sizing: border-box;
    border-radius: 12px;
    width: 24px;
    height: 24px;
    cursor: pointer;
    z-index: 2;
    display: none;
    background-color: var(--grey0);
    border: 1px solid var(--grey10);
    $button-open: &;

    &__icon {
      margin: -1px;
      transform: rotate(-90deg);
      fill: var(--grey70);
      transition: fill 0.2s;
    }

    &:hover #{$button-open}__icon {
      fill: var(--grey90);
    }
  }

  &__separation {
    flex: 1;
  }

  &:hover #{$side-menu} {
    &__button-open {
      display: flex;
    }
  }

  &_open {
    width: 227px;

    #{$side-menu}__button-open__icon {
      transform: rotate(90deg);// translateX(-1px);
    }
  }
}
