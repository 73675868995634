@import 'src/assets/variables';
@import 'src/assets/mixins';

.option {
  display: flex;
  align-items: center;
  min-height: 36px;
  padding: 2px 9px;
  cursor: pointer;
  box-sizing: border-box;

  @include themify() {
    background-color: t('primary-color');
    color: t('color-white');
  }

  &__icon {
    max-width: 20px;
    margin-right: 10px;

    @include generator-img-filters();
  }

  @include themify() {
    &:hover {
      background-color: t('color-dark-grey-opacity');
    }
  }

  &_italic {
    font-family: $primary-font-italic;
  }
}
