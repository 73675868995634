@import 'src/assets/variables';
@import 'src/assets/mixins';

.card {
  @include themify() {
    display: flex;
    flex-direction: column;
    flex: 1;
    padding: 24px;
    font-family: $primary-font-regular;
    background-color: t('secondary-color');
    box-shadow: t('box-shadow');
    color: t('color-white');

    &__header {
      display: flex;
      align-items: center;
      margin-bottom: 23px;

      &__title {
        font-size: 16px;
        line-height: 22px;
        flex: 1;
        color: t('color-white');

        // todo убрать, когда выпилим либу antd с её вездесущими стилями
        margin-bottom: 0;
      }

      &__menu {
        opacity: 0.6;
        transition: opacity 0.3s ease-in-out;
        cursor: pointer;
        padding: 0 10px;
        filter: t('img-invert');

        &:hover {
          opacity: 1;
        }
      }
    }
  }
}
