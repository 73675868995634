@import 'src/assets/mixins';

.tabs{
  @include themify() {
    .ant-tabs-bar {
      border-color: t('color-grey');
    }

    .ant-tabs-tab {
      color: rgba(t('color-grey'), 0.8);

      &:hover {
        color: t('color-grey');
      }

      &.ant-tabs-tab-active {
        color: t('color-white');
      }
    }
  }

  .ant-tabs-ink-bar {
    height: 4px;
  }

  .ant-tabs {
    display: flex;
    flex-direction: column;
    flex: 1;
    max-width: 100%;
    padding-bottom: 0;
    overflow-y: hidden;
  }

  .ant-tabs-content {
    flex: 1;
    min-height: 1px;
    padding-bottom: 0;
  }

  .ant-tabs-tabpane {
    display: flex;
  }
}
