@import 'src/assets/variables';
@import 'src/assets/mixins';

.operation-cell-renderer {
  display: flex;
  align-items: center;
  font-size: 14px;
  line-height: 19px;
  letter-spacing: 0.15px;

  &__count {
    margin-left: 3px;

    @include themify() {
      color: t('color-grey');
    }
  }
}
