@import 'src/assets/variables';
@import 'src/assets/extend';
@import 'src/assets/mixins';

.mkit-file-message {
  @extend %rcs-message;

  &__row {
    display: flex;
    align-items: center;
    margin-bottom: 24px;
  }

  &__staple-button {
    width: 56px;
    height: 56px;
    border-radius: 50%;
    box-sizing: border-box;
    background: url("../../../../assets/icons/staple-dark.svg") center no-repeat;
    background-size: 11px 22px;
    margin-right: 8px;
    cursor: pointer;
    transition: border 0.2s ease-in-out;

    @include themify() {
      border: 2px solid t('color-light-grey');

      &:hover {
        border: 2px solid t('color-deep-grey');
      }
    }
  }

  &__info {
    display: flex;
    flex-direction: column;
    font-size: 14px;
    line-height: 19px;
    letter-spacing: 0.15px;

    @include themify() {
      color: t('primary-color');
    }

    span {
      max-width: 240px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}
