@import 'src/assets/variables';
@import 'src/assets/mixins';

.action-node {
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  border-radius: 6px;
  width: 400px;
  min-height: 102px;
  border: solid 2px;
  box-sizing: border-box;
  background-color: $white;
  $action-node: &;

  @include themify() {
    border-color: t('fff-00-222430');
  }

  &__label {
    position: absolute;
    top: -28px;
    border: 2px solid #fff;
    border-radius: 8px 8px 0 0;
    font-weight: 600;
    font-size: 10px;
    line-height: 14px;
    padding: 5px 48px;
    left: 50%;
    transform: translate(-50%);
    text-transform: uppercase;
    white-space: nowrap;
    color: $primary;

    &_start {
      background-color: $green;
    }

    &_failover {
      background-color: $orange;
    }

    &_response-timeout {
      background-color: $pink;
    }

    &_stuck-timeout {
      background-color: $blue;
    }

    &_opt-out {
      background-color: $red;
    }

    @include themify() {
      border-color: t('fff-00-222430');
    }
  }

  &__header {
    display: flex;
    align-items: center;
    position: relative;
    padding: 9px 15px;
    border-radius: 4px 4px 0 0;
    margin-bottom: 2px;

    @include themify() {
      background-color: t('222430-08-222430');
    }

    &__ports-in {
      display: flex;
      justify-content: space-between;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;

      &__left {
        justify-content: flex-start;
        align-items: center;
        margin-left: -1px;

        .port {
          transform: translate(-50%, 0);
        }
      }

      &__center {
        justify-content: center;
        align-self: flex-start;
        margin-top: -1px;

        .port {
          transform: translate(0, -50%);
        }
      }

      &__right {
        justify-content: flex-end;
        align-items: center;
        margin-right: -1px;

        .port {
          transform: translate(50%, 0);
        }
      }

      &__left,
      &__center,
      &__right {
        display: flex;
        flex: 1;

        .port-in__circle {
          opacity: 0;
        }

        &:hover .port-in__circle {
          opacity: 1;
        }
      }
    }

    &__icon {
      width: 24px;
      height: 24px;
      margin-right: 8px;
      pointer-events: none;
    }

    &__label {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      color: $white;
    }
  }

  &__content {
    display: flex;
    justify-content: center;
    flex-direction: column;
    position: relative;
    white-space: pre-wrap;
    flex: 1;

    &__suggestions {
      margin-top: 17px;
      margin-bottom: 10px;

      &__item {
        display: flex;
        align-items: center;
        font-size: 12px;
        line-height: 14px;

        &:not(:last-of-type) {
          margin-bottom: 8px;
        }
      }

      &__button {
        border: 1px dashed rgba(0, 0, 0, 0.2);
        box-sizing: border-box;
        border-radius: 8px;
        margin-right: 8px;
        padding: 8px;

        img {
          margin-right: 4px;
        }
      }
    }

    &__dynamic-message {
      min-width: 1px;
      $dynamic-message: &;

      &_textmessage #{$dynamic-message} {
        &__image,
        &__content__title {
          display: none;
        }
      }

      &_richcardmessage #{$dynamic-message} {
        &__image {
          height: 139px;

          &__current {
            width: 100%;
          }

          &__prev,
          &__next {
            display: none;
          }
        }
      }

      &_richcardcarousel #{$dynamic-message} {
        &__image {
          height: 128px;

          &__current {
            width: 100%;
            margin: 0 12px;
          }
        }
      }

      &__image {
        display: flex;

        &__prev,
        &__current,
        &__next {
          background-color: $grey;
        }

        &__prev,
        &__next {
          flex: 1;
        }

        &__current {
          flex: 8;
        }
      }

      &__content {
        padding: 10px;

        &__title {
          height: 19px;
          width: 50%;
          margin-top: 2px;
          margin-bottom: 12px;
        }

        &__text {
          height: 15px;
          width: 100%;
        }

        &__text,
        &__title {
          background-color: $grey;
        }

        & > *:last-child {
          margin-top: 4px;
          width: 70%;
        }
      }
    }

    &__static-message {
      display: flex;
      flex: 1;
      flex-direction: column;
      min-width: 1px;
      color: $primary;

      &__images {
        display: flex;
        overflow: hidden;
        pointer-events: none;
        $images: &;

        &__image {
          object-fit: cover;
          height: 139px;

          &:not([src]) {
            background: url("../../../assets/icons/rcs/image-placeholder.svg") $grey center no-repeat;
            //background-color: $grey;
          }

          &:nth-child(2) {
            margin: 0 12px;

            &:not([src]) {
              min-width: calc(100% + 12px);
            }
          }
        }

        &_one &__image:nth-child(1) {
          width: 100%;
        }

        &_two &__image:nth-child(1) {
          width: 90%;
          min-width: 90%;
        }

        &_three {
          justify-content: center;

          #{$images}__image {
            &:nth-child(1):not([src]) {
              min-width: calc(100% + 12px);
            }

            &:nth-child(2) {
              width: 80%;

              &:not([src]) {
                min-width: calc(80% + 12px);
              }
            }

            &:nth-child(3):not([src]) {
              min-width: calc(100% + 12px);
            }
          }
        }
      }

      &__content {
        display: flex;
        flex-direction: column;
        padding: 10px;

        &__title {
          font-size: 16px;
          line-height: 19px;
          font-family: $primary-font-bold;
          margin-bottom: 6px;
        }

        &__description {
          font-size: 14px;
          line-height: 18px;
        }
      }
    }

    &__others {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 10px;
      font-family: $primary-font-bold;
      pointer-events: none;
      color: $primary;

      &__illustration {
        width: 48px;
        height: 48px;
        filter: brightness(0);
      }

      &__label {
        font-size: 16px;
        line-height: 19px;
        font-family: $primary-font-bold;
        pointer-events: none;
        color: $primary;
      }
    }

    &__text {
      display: flex;
      flex: 1;
      font-size: 14px;
      line-height: 18px;
      margin: 9px 11px 11px 11px;
      height: 54px;
      max-height: 54px;
      box-sizing: border-box;
      white-space: pre;
      overflow: hidden;
      color: $primary;
    }

    &__additional-block {
      display: flex;
      flex-direction: column;
      justify-content: center;
      height: 42px;
      padding: 0 12px;
      border-radius: 0 0 6px 6px;

      &:empty {
        height: 0;
      }

      background-color: #d6d6d6;

      &__row {
        display: flex;
        font-size: 10px;
        line-height: 18px;
        white-space: pre;

        @include themify() {
          color: #575962;

          &__value {
            color: $primary;
          }

          &__variable {
            color: #02c92a;
          }
        }
      }
    }

    &__crop-block {
      height: 40px;
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      border-radius: 7px;

      @include themify() {
        //border-color: t('color-white');
        background: linear-gradient(180deg, rgba($white, 0) 0%, $white 100%);
      }
    }
  }

  &:hover #{$action-node} {
    &__clone,
    &__delete {
      opacity: 1;
    }
  }

  &__clone,
  &__delete {
    position: absolute;
    opacity: 0;
    top: -34px;
    right: -30px;
    height: 32px;
    width: 32px;
    background-position: center;
    background-repeat: no-repeat;
    cursor: pointer;

    &_hover {
      opacity: 1;
    }

    @include themify() {
      filter: t('img-white');
    }
  }

  &__clone {
    right: 0;
    top: -30px;
    height: 24px;
    width: 24px;
    background-size: 16px 16px;
    background-image: url('../../../assets/icons/copy.svg');
  }

  &__delete {
    background-image: url('../../../assets/icons/cross-dark.svg');
  }

  &_selected {
    border-radius: 10px;

    @include themify() {
      box-shadow: -5px 10px 65px 15px t('accent-color');
      border: 4px solid t('accent-color');
    }
  }

  &_handover-to-human #{$action-node}__header {
    // todo important, no dark theme yet
    background-color: #38a113 !important;
  }

  &_reset-chat #{$action-node}__header {
    // todo important, no dark theme yet
    background-color: #ea26ca !important;
  }

  &_opt-out #{$action-node}__header {
    // todo important, no dark theme yet
    background-color: $red;
  }
}
