@import 'src/assets/variables';
@import 'src/assets/mixins';

.opt-out-field-array {
  display: flex;
  flex-direction: column;

  &__field {
    display: flex;
    align-items: center;
    margin-bottom: 8px;

    div {
      &:not(:last-child) {
        margin-right: 12px;
      }
    }
  }

  &__info {
    margin-bottom: 8px;
    font-size: 12px;

    @include themify() {
      color: t('color-grey');
    }
  }
}
