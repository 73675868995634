@import 'src/assets/variables';
@import 'src/assets/mixins';

.code-editor-field {
  display: flex;
  flex-direction: column;
  flex: 1;

  &__header {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;

    &__label {
      @include themify() {
        color: t('color-grey');
      }
    }

    &__buttons {
      display: flex;
      align-items: center;

      &__button {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 8px;
        border-radius: 4px;
        cursor: pointer;
        min-width: 116px;

        &:not(:last-of-type) {
          margin-right: 20px;
        }

        @include themify() {
          background-color: t('primary-color');
          color: t('color-grey');
        }

        &_active {
          @include themify() {
            background-color: t('accent-color');
            color: t('color-white');
          }
        }
      }
    }
  }

  &__input {
    display: flex;
    width: 100% !important;
    height: 300px !important;
    margin-top: 20px;
    border-radius: 5px;

    @include themify() {
      border: 1px solid t('color-grey');
    }
  }
}
