@import 'src/assets/variables';
@import 'src/assets/mixins';

.mkit-geolocation-element {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-height: 139px;
  padding: 8px;
  position: relative;
  grid-area: geolocation;
  border-radius: 8px;
  background-color: $color-white;
  cursor: pointer;

  @include themify() {
    border: 2px solid $color-light-grey;
  }

  &_with-bg {
    justify-content: flex-start;
    background-image: url("../../../../assets/icons/test.png");
    background-size: 100% 120%;
    background-position: center;
    background-origin: border-box;
    background-repeat: no-repeat;
  }

  &_in-template {
    border-radius: 6px;

    @include themify() {
      border: none;
    }
  }

  &__image {
    height: 48px;
    width: 48px;
    filter: invert(1);
  }

  &__values {
    display: flex;
    flex-direction: column;
    margin-top: auto;
    font-size: 12px;

    &__row {
      display: flex;

      &__label {
        font-family: $primary-font-bold;
        margin-right: 5px;
      }

      &:not(:last-child) {
        margin-bottom: 3px;
      }
    }
  }
}
