@import 'src/assets/mixins';
@import 'src/assets/variables';

.attribute-card {
  display: flex;
  align-items: center;
  padding: 13px 24px;
  $attribute-card: &;

  &__left {
    display: flex;
    flex-direction: column;
    flex: 1;

    &__title {
      font-size: 14px;
      line-height: 19px;
      letter-spacing: 0.15px;
      font-family: $primary-font-bold;
    }

    &__sub-title {
      margin-top: 12px;
      font-size: 12px;
      line-height: 16px;
      letter-spacing: 0.15px;
      font-family: $primary-font-regular;

      @include themify() {
        color: t('color-grey');
      }
    }
  }

  &__actions {
    display: flex;
    visibility: hidden;

    & > *:not(:last-child) {
      margin-right: 3px;
    }
  }

  &:hover {
    @include themify() {
      background-color: rgba(t('primary-color'), 0.7);
    }

    #{$attribute-card}__actions {
      visibility: visible;
    }
  }
}
