@import 'src/assets/variables';
@import 'src/assets/mixins';

.constructor-field {
  display: flex;
  align-items: center;
  margin-bottom: 12px;

  @include themify() {
    color: t('color-grey');
  }

  &__label {
    display: flex;
    flex: 3;
    margin-right: 12px;
  }

  &__content {
    display: flex;
    align-items: center;
    flex: 7;

    &__icon {
      margin-left: 10px;
    }
  }
}
