@import 'src/assets/variables';
@import 'src/assets/mixins';

.port-out {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  $port: &;

  &_connected #{$port}__circle_right {
    visibility: hidden;
  }

  &_connected#{$port}_left #{$port}__circle_left,
  &_connected#{$port}_right #{$port}__circle_right,
  &_connected#{$port}_bottom #{$port}__circle_bottom {
    visibility: visible;

    @include themify() {
      background-color: t('color-white');
    }
  }

  &__circle {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 14px;
    height: 14px;
    border: 2px solid;
    border-radius: 50%;
    cursor: pointer;
    visibility: hidden;

    @include themify() {
      border-color: t('color-white');
      background-color: t('primary-color');
    }

    &_right {
      visibility: visible;
      transform: translate(50%, 0);
      margin-right: -1px;
    }

    &_bottom {
      align-self: flex-end;
      transform: translate(0, 50%);
      margin-bottom: -1px;
    }

    &_left {
      transform: translate(-50%, 0);
      margin-left: -1px;
    }
  }

  &_end #{$port}__circle {
    width: 24px;
    height: 24px;
    background-color: $red-2;

    &::after {
      content: '';
      position: absolute;
      width: 8px;
      height: 8px;
      background-color: $white;
    }
  }
}
