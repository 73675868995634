@import 'src/assets/variables';
@import 'src/assets/mixins';

%chip {
  @include themify() {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 2px;
    background-color: t('color-dark-grey-opacity');
    color: t('color-grey');
    padding: 5px 8px;
    font-variant: small-caps;
    font-size: 14px;
    line-height: 19px;
    letter-spacing: 0.15px;
  }
}

%rcs-message {
  @include themify() {
    display: flex;
    flex-direction: column;
    flex: 1;
    padding: 38px;
    color: t('color-black');
  }
}

%rcs-suggestions {
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  &:not(:empty) {
    margin-bottom: 24px;
  }

  div {
    margin: 2px 4px 2px 4px;
  }
}

%warning-modal {
  &__description {
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: $primary-font-regular;
    font-size: 14px;
    line-height: 19px;
    text-align: center;
    white-space: pre-wrap;
    min-height: 100px;

    @include themify() {
      color: t('color-grey');
    }
  }

  &__buttons {
    display: flex;
    justify-content: space-between;
  }
}
