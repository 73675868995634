@import 'src/assets/variables';
@import 'src/assets/mixins';
@import 'src/assets/keyframes';

.ant-table-wrapper {
  overflow: hidden;
  height: 100%;

  .ant-spin-container::after {
    background: transparent;
  }

  .ant-spin-blur {
    opacity: 1;
  }

  .ant-spin-dot-spin {
    transform: none;
    animation: none;
  }

  .ant-spin-dot {
    display: flex;
    width: auto;
    height: auto;
    margin: -50px !important;

    .ant-spin-dot-item {
      display: none;
    }

    @include themify() {
      &::after {
        content: '';
        border-radius: 50%;
        border: 10px solid t('accent-color');
        animation: spin 0.8s 0.1s ease-in-out infinite both;
        height: 70px;
        width: 70px;
        border-top-color: transparent;
        border-bottom-color: transparent;
      }
    }
  }

  .ant-table-header.ant-table-hide-scrollbar,
  .ant-table-body {
    @include themify() {
      background: t('secondary-color') !important;
    }
  }

  .ant-table-row[data-row-key$="_last"] {
    td {
      border-bottom: none;
    }
  }

  tr {
    .buttons-cell-renderer {
      visibility: hidden;
    }

    &:hover {
      .buttons-cell-renderer {
        visibility: visible;
      }
    }
  }
  .ant-table-row-only-one-td {
    td {

      &:hover {
        background: none!important;
      }
    }
  }

  .ant-table-thead > tr > th,
  .ant-table-tbody > tr > td {
    height: 36px;
    padding: 0 !important;
    position: relative;
    font-family: $primary-font-regular;
    letter-spacing: 0.15px;

    @include themify() {
      color: t('color-white');
    }

    &:not(:last-child) {
      padding-right: 24px !important;
    }

    &:first-child {
      padding-left: 12px !important;
    }

    &:last-child {
      padding-right: 12px !important;
    }
  }

  .ant-table-thead > tr > th {
    @include themify() {
      border-color: t('color-grey');
    }
  }
  .ant-table-tbody > tr > td {
    @include themify() {
      border-color: rgba(t('color-basic-inactive'), 0.2);

      .ant-table-cell-sup {
        color: t('color-deep-grey');
        font-size: 9px;
        text-transform: lowercase;
        margin-left: 2px;
        white-space: nowrap;
      }
    }
  }

  .ant-table-thead {
    tr {
      height: 36px;

      th {
        position: sticky;
        top: 0;
        z-index: 2;
        font-variant: small-caps;
        // font-family: $primary-font-regular;
        font-size: 14px;
        font-weight: normal;

        @include themify() {
          border-bottom: 1px solid rgba(t('color-white'), 0.3);
          color: t('color-grey');
        }
      }
    }
  }

  .ant-table-tbody > tr:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected) > td {
    @include themify() {
      background: t('color-bg-table-hover');
    }
  }

  .ant-table-align-right {
    text-align: right;
  }

  .ant-spin-nested-loading,
  .ant-spin-container,
  .ant-table,
  .ant-table-content,
  .ant-table-scroll,
  .ant-table-body {
    height: 100%;
  }

  .ant-table-empty .ant-table-body {
    height: unset;
  }

  .ant-table-placeholder {
    display: flex;
    align-items: center;
    justify-content: center;
    height: calc(100% - 35px);
    border-top: none;
    border-bottom: none;
    background: unset;

    @include themify() {
      color: t('color-grey');
    }

    .ant-empty-image {
      display: none;
    }
  }

  .ant-spin-nested-loading > div > .ant-spin {
    max-height: unset;
  }

  .ant-table-row-expand-icon {
    position: absolute;
    width: 12px;
    height: 12px;
    margin-top: 4px;
    right: 0;
    border: none;
    background-color: transparent;

    @include themify() {
      &::after {
        content: '';
        display: flex;
        width: 12px;
        height: 12px;
        background: url("../../assets/icons/arrow_down.png") center no-repeat;
        background-size: 100%;
        filter: t('img-invert');
        cursor: pointer;
      }
    }
  }

  .ant-table-row-expanded {
    &::after {
      transform: rotate(180deg);
    }
  }
}
