.account-screen-container {
  display: flex;
  flex: 1;
  position: relative;

  &__build-info {
    position: absolute;
    right: 10px;
    bottom: 10px;
    opacity: 0.5;
    color: var(--grey100);
    white-space: break-spaces;
    font-size: 10px;
    line-height: 126%;
  }
}
