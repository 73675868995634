@import 'src/assets/variables';
@import 'src/assets/mixins';

.rcs-block {
  display: flex;
  flex-direction: column;
  min-height: 100%;
  overflow: auto;
  $block: &;

  &__header {
    font-size: 18px;
    line-height: 25px;
    margin-bottom: 10px;

    @include themify() {
      color: t('color-white');
    }
  }

  &__tools,
  &__time-tools {
    display: flex;
    align-items: center;
    margin-bottom: 12px;
  }

  &__time-tools {
    margin-bottom: 24px;
  }

  &__content-wrapper {
    display: flex;
    flex-direction: column;
    width: 419px;
    height: 650px;
    overflow-y: hidden;
    background: url("../../../assets/icons/phone-bg.svg") no-repeat 100%;
    margin: auto;
    padding: 17px 18px 0;

    &_new-design {
      width: 422px;
      height: 650px;
      background: url("../../../assets/icons/phone_new.svg") no-repeat 100%;
      padding: 17px 20px 0 17px;

      /* hack will fixed in lib soon */
      .constructor-editor {
        background-color: var(--componentBg);
        border-radius: 0;
      }
    }

    &__header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 49px;
      width: 100%;

      &_new-design {
        height: 49px;
        background-color: var(--grey5);
        padding: 0 15px;
        border-radius: 30px 30px 0 0;
      }
    }
  }

  &__label {
    font-size: 14px;
    line-height: 19px;
    letter-spacing: 0.15px;
    margin-right: 22px;
    min-width: 140px;

    @include themify() {
      color: t('color-grey');
    }
  }

  &__buttons {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &_viber #{$block}__content-wrapper__header {
    border-radius: 20px 20px 0 0;

    @include themify() {
      background-color: t('color-dark-blue');
    }

    &_new-design {
      border-radius: 30px 30px 0 0;
      background: #675DA9 !important;
    }
  }
}
