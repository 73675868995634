@import 'src/assets/variables';
@import 'src/assets/mixins';

.error-tooltip {
  @include themify() {
    display: flex;
    padding: 15px 20px;
    line-height: 19px;
    font-size: 14px;
    text-transform: capitalize;
    opacity: 1;
    color: $color-white;
    background: t('color-attention');

    &.place-right {
      &::after {
        border-right-color: t('color-attention');
      }
    }

    &.place-top {
      &::after {
        border-top-color: t('color-attention');
      }
    }

    &.place-bottom {
      &::after {
        border-bottom-color: t('color-attention');
      }
    }

    &.place-left {
      &::after {
        border-left-color: t('color-attention');
      }
    }
  }
}
