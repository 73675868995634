@import 'src/assets/variables';
@import 'src/assets/mixins';

.mkit-edit-contact-form {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  font-family: $primary-font-regular;

  @include themify() {
    color: t('color-grey');
  }

  &__header {
    display: flex;
    flex-direction: column;
    letter-spacing: 0.15px;
    font-size: 18px;
    line-height: 25px;

    @include themify() {
      color: ('color-white');
    }
  }

  &__content {
    display: flex;
    flex: 1;
    flex-direction: column;
    overflow-y: auto;

    &__opt-out {
      display: flex;
      align-items: center;
      height: 40px;
      font-size: 16px;
      line-height: 22px;
      width: 175px;
      margin-top: 12px;
    }

    &__channels {
      display: flex;
      align-self: flex-start;
      flex-direction: column;
      width: 100%;

      &__selected-item-current {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 21px;
      }

      &__selected-item {
        display: flex;
        align-items: center;
        margin-bottom: 21px;
        align-self: flex-start;
        width: 175px;
      }

      &__selected-item-icon-wrapper {
        position: relative;
      }

      &__selected-item-icon-lock {
        position: absolute;
        right: -1px;
        bottom: -2px;
        width: 8px;
        height: 10px;

        @include themify() {
          filter: t('img-white');
        }
      }

      &__selected-item-text {
        display: flex;
        flex: 1;
        font-size: 14px;
        line-height: 19px;
        white-space: nowrap;
        padding-left: 10px;
      }

      &__selected-item-text-current {
        display: flex;
        flex: 1;
        font-size: 14px;
        line-height: 19px;
        white-space: nowrap;
        padding-left: 10px;
      }

      &__selected-item-right {
        display: flex;
        width: 73%;
      }

      &__add {
        display: flex;
        flex-direction: column;
        align-self: flex-start;
        padding: 20px;
        border-radius: 4px;
        width: 175px;

        @include themify() {
          background-color: t('primary-color');
        }
      }

      &__add-item {
        display: flex;
        align-items: center;
        cursor: pointer;
        text-align: left;
        height: 19px;

        &:not(:first-child) {
          margin-top: 17px;
        }
      }

      &__add-item-icon {
        margin-right: 10px;
      }
    }
  }

  &__buttons {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    margin-top: 30px;

    &__group {
      display: flex;
    }
  }

  .form-field {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 10px 0;

    & > span {
      width: 73%;
    }
  }
}
