@import 'src/assets/mixins';
@import 'src/assets/variables';
@import 'src/assets/keyframes';

.dialogue-script-block {
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100%;

  &__add-button,
  &__setting-button {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    box-shadow: none;
    cursor: pointer;

    @include themify() {
      filter: t('img-white');
    }
  }

  &__add-button {
    position: absolute;
    bottom: -8px;
    left: 2px;
    background: url('../../../assets/icons/plus-in-circle.svg') center no-repeat;
  }

  &__setting-button {
    position: absolute;
    top: -8px;
    left: 2px;
    background: url('../../../assets/icons/gear-white.svg') center no-repeat;
  }

  &__fake-button {
    width: calc(100% + 50px);
    height: 70px;
    box-shadow: none;
    position: absolute;
    top: -10px;
    bottom: 0;
    left: -60px;
    z-index: 50;
    right: 0;
    cursor: pointer;

    &_selected {
      @include themify() {
        background-color: rgba(t('accent-color'), 0.2);
      }
    }

    &:hover {
      @include themify() {
        background-color: rgba(t('primary-color'), 0.7);
      }
    }
  }

  .rst {
    &__rowWrapper:hover .rst__rowToolbar {
      visibility: visible;
    }

    &__row {
      &_show-tools {
        .rst__rowToolbar {
          visibility: visible !important;
        }
      }

      &_unconditional {
        .rst__moveHandle {
          background-image: url('../../../assets/icons/step-line.svg');
          @include themify() {
            background-color: t('accent-color');
          }
        }
      }

      &_condition {
        .rst__moveHandle {
          background-image: url('../../../assets/icons/tree.svg');
          @include themify() {
            background-color: t('accent-color');
          }
        }
      }

      &_user-reply {
        .rst__moveHandle {
          background-image: url('../../../assets/icons/end-step.svg');
          @include themify() {
            background-color: t('accent-color');
          }
        }
      }

      &_last-step {
        .rst__moveHandle {
          @include themify() {
            background-color: t('color-attention');
          }
        }
      }

      &_active-step {
        .rst__moveHandle {
          @include themify() {
            border: none;
            position: relative;

            &::after {
              content: '';
              position: absolute;
              left: -3px;
              top: -3px;
              width: 54px;
              height: 54px;
              border: 2px dashed t('color-orange');
              border-radius: 50%;
              animation: spin 4s linear infinite;
            }
          }
        }
      }

      &_edit {
        .rst__moveHandle {
          background-image: url('../../../assets/icons/pencil-white.svg');

          @include themify() {
            background-color: t('color-green');
          }
        }
      }
    }

    &__moveHandle {
      width: 48px;
      height: 48px;
      border-width: 2px;
      background-image: none;

      @include themify() {
        border-color: t('color-white');
        border-radius: 50%;
        box-shadow: none;
        background-color: t('accent-color');
      }
    }

    &__rowToolbar {
       visibility: hidden;
    }

    &__rowContents {
      position: relative;
      color: transparent;
      background-color: transparent;
      border: none;
      min-width: 24px;
    }

    &__rowLabel {
      margin-left: 20px;
    }

    &__rowTitle {
      font-family: $primary-font-regular;
      font-size: 14px;
      font-weight: normal;

      @include themify() {
        color: t('color-white');
      }
    }

    &__collapseButton,
    &__expandButton {
      @include themify() {
        background-color: t('accent-color');
        border: 1px solid t('accent-color');
        box-shadow: none;
      }
    }

    &__lineChildren,
    &__lineFullVertical,
    &__lineHalfHorizontalRight {
      @include themify() {
        &::before,
        &::after {
          background-color: t('accent-color');
        }
      }
    }
  }
}
