@import 'src/assets/variables';
@import 'src/assets/mixins';
@import 'src/assets/keyframes';

.canvas-widget {
  position: relative;
  display: flex;
  height: 100%;

  &__header-wrapper {
    height: 36px;
    display: flex;
    align-items: center;
    position: fixed;
    top: 24px;
    right: 24px;
    gap: 15px;
  }

  &__switch-wrapper {
    display: flex;
    align-items: center;
    height: 30px;
    position: initial;

    @include themify() {
      &::after {
        content: '';
        position: absolute;
        width: calc(100% + 20px);
        height: 60px;
        border-radius: 50%;
        right: -10px;
        top: -15px;
        box-shadow: -1px 2px 16px 3px $accent-color;
        opacity: 0;
        animation: 1s blinker linear 2;
        z-index: -1;
      }
    }
  }

  svg:not(:root) {
    overflow: initial;
  }

  &__elements {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 152px;
    min-width: 112px;
    position: absolute;
    bottom: 0;
    right: 0;

    &__action,
    &__condition {
      display: flex;
      width: 100%;
      padding: 4px 10px;
      border-radius: 8px;
      box-sizing: border-box;
      align-items: center;
      flex-direction: column;
      white-space: nowrap;
      cursor: grab;
      position: relative;

      @include themify() {
        border: 1px dashed t('accent-color');

        &::after {
          content: '';
          position: absolute;
          left: 3px;
          right: 3px;
          top: 3px;
          bottom: 3px;
          border-radius: 8px;
          background-color: rgba(t('accent-color'), 0.15);
        }
      }

      &__icon {
        width: 36px;
        height: 36px;
      }
    }

    &__action {
      @include themify() {
        color: t('fff');
      }

      &__icon {
        @include themify() {
          filter: t('img-white');
        }
      }
    }

    &__condition {
      margin-top: 19px;

      @include themify() {
        color: t('accent-color');
      }
    }
  }

  &__zoom {
    display: flex;
    align-items: center;
    position: absolute;
    left: 0;
    bottom: 0;

    &__current {
      font-size: 16px;
      line-height: 19px;
      letter-spacing: 0.15px;
      margin: 0 4px;

      @include themify() {
        color: t('color-white');
      }
    }
  }

  .diagram-container {
    //background: #333333;
    width: 100%;
    height: 100%;
  }

  .port {
    cursor: pointer;
  }
}
