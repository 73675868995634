@import 'src/assets/variables';
@import 'src/assets/mixins';

.tab-list-item {
  display: flex;
  position: relative;
  padding: 15px 25px;
  box-sizing: border-box;
  white-space: nowrap;
  letter-spacing: 0.15px;
  font-size: 14px;
  line-height: 19px;
  cursor: pointer;
  // margin-top: 9px;
  background-color: transparent;
  user-select: none;
  border: none;
  outline: none;

  @include themify() {
    color: t('color-white');
  }

  &_active {
    &::after {
      content: '';
      width: 100%;
      position: absolute;
      bottom: -1px;
      z-index: 100;
      left: 0;
      right: 0;
      height: 5px;
    }

    @include themify() {
      &::after {
        background: t('accent-color');
      }
    }
  }
}

.menu-item-wrapper,
.active {
  background-color: transparent;
  user-select: none;
  border: none;
  outline: none;
}
