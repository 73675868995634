@import 'src/assets/mixins';

.mkit-add-media-form {
  display: flex;
  flex-direction: column;
  height: 100%;

  &__header {
    font-size: 18px;
    line-height: 25px;
    font-family: notosans-regular;
    margin-bottom: 8px;

    @include themify() {
      color: t('color-white');
    }
  }

  &__buttons-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: auto;
  }
}
