@import 'src/assets/variables';
@import 'src/assets/mixins';

.mkit-ag-column-header {
  display: flex;
  align-items: center;
  // flex-direction: row-reverse;
  width: 100%;

  .ag-header-cell-label {
    flex: unset;
  }

  &__filter {
    margin-left: 15px;
    cursor: pointer;
    width: 14px;
    height: 16px;
    min-width: 14px;
    opacity: 0;
    background-image: url(../../../assets/icons/watering-can.svg);
    background-position: center;
    background-repeat: no-repeat;

    &:hover {
      opacity: 1;
    }
  }

  &__reset-filter {
    display: none;
    width: 20px;
    height: 20px;
    margin-left: 5px;
    opacity: 0.6;
    cursor: pointer;
    transition: opacity 0.3s ease-in-out;

    &:hover {
      opacity: 1;
    }
  }

  .ag-header-cell {
    // когда меню открыто, иконка фильтра не прозрачная
    &.ag-column-menu-visible .ag-column-header__filter {
      opacity: 1;
    }

    // когда фильтр применен, иконка фильтра меняется и становится прозрачной на 0.6
    &.ag-header-cell-filtered .ag-column-header__filter {
      opacity: 0.6;
      background-image: url(../../../assets/icons/filter.svg);
    }

    &:hover .ag-column-header__filter {
      opacity: 0.6;

      &:hover {
        opacity: 1;
      }
    }

    &.ag-header-cell-filtered .ag-column-header__reset-filter {
      display: flex;
      @include themify() {
        filter: t('img-invert');
      }
    }
  }
}
