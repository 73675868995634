@import 'src/assets/variables';
@import 'src/assets/mixins';

.port-in {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 44px;
  height: 44px;
  border-radius: 22px;
  $port-in: &;

  &__circle {
    width: 14px;
    height: 14px;
    border-radius: 12px;
    border: 2px solid;

    @include themify() {
      border-color: t('color-white');
      background-color: t('primary-color');
    }
  }

  &_end #{$port-in}__circle {
    width: 24px;
    height: 24px;

    @include themify() {
      background-color: t('color-attention');

      &::after {
        content: '';
        position: absolute;
        width: 8px;
        height: 8px;
        background-color: t('color-white');
      }
    }
  }

  &_connected #{$port-in}__circle {
    opacity: 1 !important;

    @include themify() {
      background-color: t('color-white');
    }
  }
}
