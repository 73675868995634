@import 'src/assets/mixins';

.mkit-pagination {
  font-family: $primary-font-regular;

  @include themify() {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    color: t('color-white');
    font-size: 14px;

    &__limit-field-wrapper {
      min-width: 62px;
      margin-left: 10px;
    }

    &__page-info {
      margin: 0 10px;
    }

    &__page-control {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-right: 5px;

      &__page-field-wrapper {
        min-width: 62px;
        margin: 0 12px;
      }

      &__button {
        cursor: pointer;
        height: 8px;
        width: 12px;
        opacity: 0.6;
        transition: opacity 0.3s ease-out 0.1s;
        transform: rotate(90deg);
        filter: t('img-invert');

        &:hover {
          opacity: 1;
        }

        &_right {
          transform: rotate(270deg);
        }
      }
    }
  }
}
