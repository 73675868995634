.dialogue-templates-screen-container {
  display: flex;
  flex-direction: column;
  min-height: 100%;
  width: 100%;

  & > div:first-child {
    display: flex;
    flex: 1;
  }
}
