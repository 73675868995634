@import 'src/assets/variables';
@import 'src/assets/mixins';

.mkit-create-action-form {
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;

  &__title {
    font-weight: 500;
    margin-bottom: 24px;
  }

  &__subtitle {
    font-size: 16px;
    line-height: 22px;
    margin-bottom: 24px;
  }

  &__row {
    display: flex;
    align-items: center;

    &__label {
      display: flex;
      flex: 3;
      font-size: 14px;
      line-height: 19px;
      letter-spacing: 0.15px;
      margin-right: 25px;

      @include themify() {
        color: t('color-grey');
      }
    }

    &__button {
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex: 7;
      font-size: 14px;
      line-height: 19px;
      letter-spacing: 0.15px;
      cursor: pointer;
      text-align: left;

      &:disabled {
        cursor: not-allowed;
        opacity: 0.6;
      }

      &__icon {
        margin-left: 10px;

        @include themify() {
          filter: t('img-invert');
        }
      }

      @include themify() {
        color: t('color-white');
        border-bottom: 1px solid t('color-grey');
      }
    }

    &__dropdown {
      img {
        @include themify() {
          filter: t('img-invert');
        }
      }
    }
  }

  &__buttons {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: auto;
  }
}
