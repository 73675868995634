@import 'src/assets/variables';
@import 'src/assets/keyframes';
@import 'src/assets/mixins';

.spinner {
  display: flex;
  position: relative;
  height: auto;
  min-width: 14px;
  min-height: 14px;
  margin-left: 5px;

  &_full-size {
    width: 100%;
    height: 100%;
  }

  &_page-loader {
    height: 100vh;
  }

  $r: 20px;
  $r_small: 7px;
  $diam: $r * 2;
  $diam_small: $r_small * 2;
  $b: $diam / 7;
  $b_small: $diam_small / 7;

  &__inner {
    display: flex;
    position: absolute;
    justify-content: center;
    align-items: center;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    &::after {
      content: '';
      border-radius: 50%;
      border: $b solid;
      animation: spin 0.8s 0.1s ease-in-out infinite both;
      height: $diam;
      width: $diam;
    }

    @include themify() {
      &::after {
        border-color: t('accent-color');
        border-top-color: transparent;
        border-bottom-color: transparent;
      }
    }

    &_small {
      &::after {
        height: $diam_small;
        width: $diam_small;
        border-width: $b_small;
      }
    }

    &_white {
      @include themify() {
        &::after {
          border-color: t('fff');
          border-top-color: transparent;
          border-bottom-color: transparent;
        }
      }
    }
  }
}
