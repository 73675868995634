@import 'src/assets/variables';
@import 'src/assets/mixins';

.mkit-date-picker-field {
  display: flex;
  min-width: 1px;
  align-items: center;
  justify-content: space-between;
  font-family: $primary-font-regular;
  $date-field: &;

  &__label {
    font-size: 14px;
    line-height: 19px;
    letter-spacing: 0.15px;

    @include themify() {
      color: t('color-grey');
    }
  }

  &:focus-within {
    #{$date-field}__label {
      opacity: 1;
    }
  }

  .ant-calendar-picker-input {
    background-color: transparent !important;
  }

  .ant-calendar-picker-input[disabled] {
    background-color: transparent;
  }

  .ant-calendar-input {
    background-color: transparent !important;
  }
}

.datepicker {
  width: 100%;

  &_underline {
    .ant-calendar-picker-input {
      border: none;
      border-radius: 0;
      background-color: transparent;
      user-select: none;
      outline: none;

      @include themify() {
        border-bottom: t('border-style');
        color: t('color-white');
      }

      &:hover {
        text-underline: none;

        @include themify() {
          border-bottom-color: t('color-white');
        }
      }
    }
  }

  .ant-calendar-picker-input {
    @include themify() {
      color: t('color-white');
      border-color: t('color-grey');

      &:hover {
        border-color: t('color-white');
      }

      &::placeholder {
        font-family: notosans-italic;
        font-size: 14px;
        line-height: 19px;
        letter-spacing: 0.15px;
        border-color: transparent;
        color: t('color-grey');
      }
    }
  }

  .anticon {
    @include themify() {
      filter: t('img-invert');
    }
  }
}

.datepicker-dropdown {
  font-family: 'notosans-regular';

  .ant-calendar {
    border: none;

    @include themify() {
      box-shadow: t('box-shadow');
    }

    &-panel {
      @include themify() {
        background: t('primary-color');
      }
    }

    &-input {
      @include themify() {
        color: t('color-white');

        &::placeholder {
          opacity: .6;
          font-family: notosans-italic;
          font-size: 14px;
          line-height: 19px;
          letter-spacing: 0.15px;
          color: t('color-white');
        }
      }

      &-wrap {
        border: none;
      }
    }

    &-selected-day {
      .ant-calendar-date {
        @include themify() {
          background-color: t('accent-color');
          color: t('color-white');
        }
      }
    }

    &-date:hover {
      @include themify() {
        background-color: transparent;
        border: t('border-style');
        color: t('color-white');
      }
    }

    &-ok-btn {
      text-decoration: none;

      @include themify() {
        background-color: t('accent-color');
        color: $color-white;
      }

      &-disabled {
        pointer-events: none;
        opacity: .6;
      }
    }

    &-header {
      @include themify() {
        color: t('color-white');

        @each $interval in (year, month, decade) {
          .ant-calendar-#{$interval}-panel {
            background-color: t('primary-color');

            .ant-calendar-#{$interval}-panel-#{$interval} {
              color: t('color-white');

              &:hover {
                background-color: t('color-dark-grey-opacity');
              }
            }

            .ant-calendar-#{$interval}-panel-selected-cell * {
              color: t('secondary-color');
            }
          }
        }

        .ant-calendar-month-panel-year-select,
        .ant-calendar-year-panel-decade-select,
        .ant-calendar-decade-panel-century,
        .ant-calendar-month-select,
        .ant-calendar-year-select,
        .ant-calendar-day-select {
          color: t('color-white');
        }

        .ant-calendar-month-select,
        .ant-calendar-year-select,
        .ant-calendar-day-select {
          &:hover {
            color: t('accent-color');
          }
        }
      }
    }

    &-body {
      @include themify() {
        color: t('color-white');
      }
    }

    &-date {
      @include themify() {
        color: t('color-white');
      }
    }
  }

  @include themify() {
    .ant-calendar-time-picker-select {
      background-color: t('primary-color');
      color: t('color-white');
    }

    .ant-calendar-time-picker-select-option-selected,
    .ant-calendar-time-picker-select li:hover {
      background-color: t('color-dark-grey-opacity');
    }
  }
}

.mkit-date-picker-field .ant-calendar-picker-input[disabled] {
  opacity: 0.4 !important;

  &:hover {
    border-color: rgba(255, 255, 255, 0.6) !important;
  }
}

.ant-calendar-time {
  .ant-calendar-time-picker-btn-disabled {
    @include themify() {
      color: t('color-grey') !important;
    }
  }
}
.ant-calendar-picker-icon svg {
  @include themify() {
    fill: t('color-white');
  }
}
.ant-calendar-picker-clear {
  border-radius: 50% !important;

  @include themify() {
    fill: t('color-white');
  }
}
