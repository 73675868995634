@import 'src/assets/variables';
@import 'src/assets/mixins';

.mkit-dropdown-field {
  position: relative;
  display: flex;
  flex-direction: row;
  flex: 1;
  max-height: 36px;
  justify-content: space-between;
  font-family: $primary-font-regular;
  $mkit-dropdown-field: &;

  &_disabled {
    opacity: 0.6;
    cursor: not-allowed;

    #{$mkit-dropdown-field} {
      &__label-wrapper,
      &__select-wrapper {
        cursor: not-allowed;
      }
    }
  }

  &__label-wrapper {
    display: flex;
    align-items: center;

    &__label {
      font-size: 14px;
      letter-spacing: 0.15px;
      line-height: 19px;
      padding: 8px 8px 8px 0;
      opacity: 0.6;
      transition: opacity 0.3s ease-in-out;

      @include themify() {
        color: t('color-white');
      }
    }

    &__icon {
      margin-right: 15px;
      max-width: 20px;
      height: auto;
    }
  }

  &__select-wrapper {
    flex: 1;
    padding: 8px;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    transition: opacity 0.3s ease-in-out;

    @include themify() {
      border-bottom: t('border-style');
    }

    &__selected-value {
      display: flex;
      align-items: center;
      font-size: 14px;
      letter-spacing: 0.15px;
      width: 100%;

      @include themify() {
        color: t('color-white');
      }

      &_placeholder {
        font-family: $primary-font-italic;
        //opacity: 0.6;
      }

      &__image {
        max-height: 20px;
        max-width: 20px;
        margin-right: 10px;

        @include themify() {
          filter: t('img-invert');
        }
      }
    }

    &__img {
      width: 12px;
      @include themify() {
        filter: t('img-invert');
      }
    }
  }

  &-active &__label {
    opacity: 1;
  }

  &-active &__select-wrapper {
    color: 1;
  }

  &-active &__select-wrapper__img {
    transform: rotateX(180deg);
  }

  &__block {
    display: none;
    position: absolute;
    left: 0;
    right: 0;
    top: 36px;
    max-height: 300px;
    overflow-y: auto;
    z-index: 100;

    @include themify() {
      background-color: t('primary-color');
    }

    &_open {
      display: block;
    }

    &_top {
      bottom: 36px;
      top: unset;
    }

    &__value {
      display: flex;
      align-items: center;
      font-size: 14px;
      letter-spacing: 0.15px;
      height: 35px;
      padding-left: 9px;
      cursor: pointer;
      transition: background-color 0.3s ease-in-out;

      @include themify() {
        color: t('color-white');
        opacity: 0.6;

        &:hover {
          background-color: t('color-dark-grey-opacity');
          opacity: 0.8;
        }
      }


      &__image {
        max-width: 20px;
        max-height: 20px;
        margin-right: 10px;

        @include themify() {
          filter: t('img-invert');
        }
      }
    }
  }

  &_white #{$mkit-dropdown-field} {
    &__select-wrapper {
      opacity: unset;

      &__selected-value {
        opacity: unset;
        white-space: nowrap;
        margin-right: 5px;
      }
    }
  }

  &_full-white {
    min-height: 46px;
    border-radius: 4px;

    @include themify() {
      background: $color-white;
      border: 1px solid t('color-border');
    }

    #{$mkit-dropdown-field} {
      &__select-wrapper {
        border-bottom: none;
        padding: 11px 19px;
        line-height: 22px;
        width: 100%;
        box-sizing: border-box;

        &__selected-value {
          font-size: 18px;
          font-family: $primary-font-regular;

          @include themify() {
            color: t('dark-blue-color');
          }

          &_placeholder {
            opacity: 0.8;
          }
        }

        &__img {
          filter: invert(1);
        }
      }

      &__block {
        width: calc(100% + 2px);
        top: 50px;
        left: -1px;
        height: auto;
        max-height: 300px;
        background: $color-white;
        border: 1px solid $color-border;

        &__value {
          color: $primary-color;
          opacity: 0.8;
          transition: background-color 0.3s ease-in-out;

          &:hover {
            opacity: 1;
            background-color: $color-dark-opacity;
          }
        }
      }
    }
  }

  &_border {
    #{$mkit-dropdown-field} {
      &__label-wrapper {
        flex: 3;
        margin-right: 32px;
        font-size: 14px;
        line-height: 19px;
        letter-spacing: 0.15px;
      }

      &__select-wrapper {
        flex: 7;
        border-radius: 4px;
        min-height: 36px;

        @include themify() {
          border: t('border-style');
        }
      }

      &__block {
        left: 34%;
        top: 43px;
      }
    }
  }

  &_no-border #{$mkit-dropdown-field} {
    &__select-wrapper {
      border-bottom: none;
    }

    &__block {
      top: 35px;
    }
  }

  &_grey-border {
    #{$mkit-dropdown-field} {
      &__select-wrapper {
        border-radius: 4px;

        @include themify() {
          border: t('border-style');
        }

        &__selected-value {
          text-overflow: ellipsis;
          overflow: hidden;
          max-width: calc(100% - 20px);
          white-space: nowrap;
        }
      }

      &__block {
        height: auto;
        max-height: 300px;

        &_top {
          // top: -120px;
          bottom: 46px;
        }
      }
    }
  }

  &_error {
    @include themify() {
      border-color: t('color-attention');

      #{$mkit-dropdown-field} {
        &__select-wrapper {
          &__selected-value {
            color: t('color-attention');

            &_placeholder {
              opacity: 1;
              color: t('color-attention');
            }
          }
        }
      }
    }
  }
}
