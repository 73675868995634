@import 'src/assets/variables';
@import 'src/assets/mixins';

.mkit-ag-table {
  height: 100%;
  width: 100%;

  .ag-center-cols-container {
    min-width: 100%;
  }

  .ag-cell-value {
    font-family: $primary-font-regular;
    font-size: 14px;
    letter-spacing: 0.15px;

    @include themify() {
      color: t('color-white');
    }
  }

  .ag-cell-auto-height {
    height: auto;
  }

  .ag-overlay-no-rows-center {
    @include themify() {
      color: t('color-white');
    }
  }

  .ag-overlay-loading-wrapper {
    @include themify() {
      &::after {
        content: '';
        border-radius: 50%;
        border: 10px solid t('accent-color');
        animation: spin 0.8s 0.1s ease-in-out infinite both;
        height: 70px;
        width: 70px;
        border-top-color: transparent;
        border-bottom-color: transparent;
      }
    }
  }
  .ag-overlay-loading-center {
    display: none;
  }

  .ag-header-row,
  .ag-row {
    .ag-selection-checkbox,
    .ag-header-select-all {
      margin-right: 8px;
      background-color: transparent;

      .ag-checkbox-input-wrapper {
        border-radius: 4px;
        width: 24px;
        height: 24px;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: border-color 0.3s ease-in-out;
        opacity: 0.6;
        background-color: transparent;
        position: relative;

        @include themify() {
          border: t('border-style');
        }

        &.ag-checked {
          @include themify() {
            border-color: t('color-white');

            &::after {
              filter: t('img-white');
            }
          }

          &::after {
            position: absolute;
            content: '';
            display: flex;
            width: 12px;
            height: 10px;
            background: url("../../assets/icons/check-dark.svg") center no-repeat;
            background-size: 16px;
          }
        }

        .ag-checkbox-input {
          width: 24px;
          height: 24px;
          z-index: 2;
          cursor: pointer;
          opacity: 0;
        }
      }
    }
  }

  .ag-header-row {
    .ag-header-cell {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      padding: 0 12px;

      @include themify() {
        color: t('color-grey');
      }

      &.ag-header-cell-sortable {
        cursor: default;
      }

      .ag-header-cell-resize {
        @include themify() {
          &::after {
            background-color: rgba(t('color-white'), 0.3);
          }
        }

        &::after {
          content: "";
          position: absolute;
          z-index: 1;
          display: block;
          left: calc(50% - 1px);
          width: 1px;
          height: 30%;
          top: 35%;
        }
      }

      .ag-react-container {
        width: 100%;
      }
    }
  }

  .ag-header {
    .ag-pinned-right-header .mkit-ag-column-header {
      justify-content: flex-end;
    }
  }

  .ag-row {
    transition: background 0.3s ease-in-out;
    display: flex;
    align-items: center;

    @include themify() {
      border-bottom: 1px solid rgba(t('color-basic-inactive'), 0.2);
    }

    &.ag-row-selected {
      @include themify() {
        background: t('accent-color');
      }
    }

    .ag-cell {
      position: unset;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      user-select: text;
      cursor: initial;
      padding: 0 12px;

      .ag-react-container {
        display: flex;
        align-items: center;
        height: 100%;
      }

      // for dropdown cell
      &.ag-cell-dropdown {
        overflow: visible;
      }

      &.appearing-cell {
        display: none;
      }
    }

    &.ag-row-hover {
      @include themify() {
        background: t('color-dark-grey-opacity');
      }

      .appearing-cell {
        display: unset;
      }
    }

    // for dropdown cell
    z-index: 0;

    // for dropdown cell
    &.ag-row-focus {
      z-index: 1;
    }
  }

  .ag-header-viewport,
  .ag-pinned-right-header {
    @include themify() {
      border-bottom: 1px solid t('color-grey');
    }
  }

  .ag-menu {
    overflow-y: unset;
    width: 258px;
  }

  &_without-selected {
    .ag-row {
      &.ag-row-selected {
        background: unset !important;
      }

      &.ag-row-hover {
        @include themify() {
          background: t('color-dark-grey-opacity') !important;
        }
      }
    }
  }

  .ag-react-container {
    @include themify() {
      color: t('color-white');
      font-family: $primary-font-regular;
    }
  }

  .ag-header-cell-filtered {
    background-color: initial;
  }
}
