@import 'src/assets/variables';
@import 'src/assets/mixins';

.payment-result-modal {
  display: flex;
  flex-direction: column;

  &__title {
    display: flex;
    align-items: center;
    font-size: 18px;
    font-family: $primary-font-regular;
    height: 20px;
  }

  &__icon {
    margin-right: 10px;
  }

  &__description {
    display: flex;
    flex: 1;
    padding: 24px 0;
    font-family: $primary-font-regular;
    font-size: 14px;
    line-height: 22px;
    white-space: pre-line;

    @include themify() {
      color: t('color-grey');
    }
  }
}
