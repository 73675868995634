@import 'src/assets/variables';
@import 'src/assets/mixins';

.basic-button {
  height: 36px;
  min-width: 110px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  font-family: $primary-font-regular;
  line-height: 19px;
  padding: 9px;
  transition:
    opacity 0.3s ease-in-out,
    background 0.3s ease-in-out;
  background-color: transparent;
  user-select: none;
  border: none;
  outline: none;
  opacity: 0.6;
  position: relative;
  $basic-button: &;

  &__text {
    display: flex;
  }

  // color: t('color-white');
  &_primary {
    @include themify() {
      background: t('primary-lime-color');
      opacity: 1;
      color: t('color-white');
    }
  }

  &_secondary {
    @include themify() {
      background: t('secondary-color');
      opacity: 1;
      color: t('color-white');
    }
  }

  &_accent {
    @include themify() {
      color: $color-white;
      background: t('accent-color');
      opacity: 1;
    }
  }

  &_warn {
    @include themify() {
      color: $color-white;
      background: t('color-attention');
      opacity: 1;
    }
  }

  &_cancel {
    @include themify() {
      background: t('primary-lime-color');
      opacity: 1;
      color: t('primary-white-color');

      #{$basic-button}__img {
        filter: t('img-grey-blue');
      }
    }
  }

  &_secondary-cancel {
    @include themify() {
      background: t('secondary-color');
      opacity: 1;
      color: t('primary-white-color');

      #{$basic-button}__img {
        filter: t('img-grey-blue');
      }
    }
  }

  &:hover {
    opacity: 1;
  }

  &:disabled {
    opacity: 0.6;
    cursor: not-allowed;

    &:hover {
      opacity: 0.6;
    }
  }

  &__img {
    margin-right: 12px;
    // width: 18px;
    max-height: 18px;
    // @include themify() {
    //   filter: t('img-invert');
    // }

     @include generator-img-filters();
  }

  &_new-style {
    padding: 6px 8px;
    align-items: center;
    line-height: normal;

    #{$basic-button} {
      &__text {
        font-size: 14px;
        line-height: 19px;
      }

      &__img {
        height: 24px;
        min-height: 24px;
        width: 24px;
        margin-right: 9px;
      }
    }
  }

  &__content {
    display: flex;
    align-items: center;

    &_hidden {
      opacity: 0;
    }
  }

  &__loader-wrapper {
    display: flex;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
  }

  &_transparent {
    height: 24px;
    padding: 0;

    @include themify() {
      color: t('color-white');
    }
  }
}
