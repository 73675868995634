@import 'src/assets/variables';
@import 'src/assets/mixins';

.time-picker {
  width: 100%;

  &__popup {

    font-family: notosans-regular;
    font-size: 14px;
    color: $color-white;

    .ant-time-picker-panel-input{
      &::placeholder {
        opacity: .6;
        font-family: notosans-italic;
        font-size: 14px;
        line-height: 19px;
        letter-spacing: 0.15px;
      }

      @include themify() {
        color: t('color-white');
      }
    }

    .ant-time-picker-panel-inner {
      left: 1px;
      top: 2px;
      border-radius: 4px;
    }

    .ant-time-picker-panel-input-wrap {
      border-radius: 4px 0 0 4px;
      border-width: 1px 0 1px 1px;
      border-style: solid;

      @include themify() {
        border-color: t('color-grey');
      }
    }

    .ant-time-picker-panel-combobox {
      @include themify() {
        background-color: t('primary-color');

        li {
          color: t('color-white');
        }
      }
    }

    .ant-time-picker-panel-input {
      background-color: transparent;
    }

    .ant-time-picker-panel-input-wrap{
      padding: 7px 2px 6px 12px;

      @include themify {
        background-color: t('secondary-color');
      }
    }

    .ant-time-picker-panel-select{
      @include themify() {
        border-left: t('border-style');

        &:first-child{
          border: none;
        }
      }
    }

    .ant-time-picker-panel-select{
      @include themify() {
        li:hover{
          background-color: t('color-dark-grey-opacity');
        }
      }
    }

    li.ant-time-picker-panel-select-option-selected{
      @include themify() {
        color: t('color-white');
        background-color: t('color-dark-grey-opacity');
      }
    }
  }

  .ant-time-picker-input{
    &::placeholder {
      font-family: notosans-italic;
      font-size: 14px;
      line-height: 19px;
      letter-spacing: 0.15px;
    }

    @include themify() {
      color: t('color-grey');
      border-color: t('color-grey');
    }
  }

  .ant-time-picker-icon {
    @include themify() {
      filter: t('img-invert');
    }
  }
}
