@import 'src/assets/variables';
@import 'src/assets/mixins';

.campaign-card-content {
  &__channel {
    display: flex;
    flex-direction: column;
    margin-bottom: auto;
    margin-top: 12px;
  }

  &__row {
    display: flex;
    justify-content: space-between;
    font-size: 14px;
    line-height: 25px;
    letter-spacing: 0.15px;

    @include themify() {
      color: t('color-grey');
    }
  }

  &__link-statistic {
    max-height: 140px;
    min-height: 60px;
    overflow-y: scroll;
  }

  &__channels-distribution {
    margin-top: 5px;

    &__progress {
      display: flex;
      align-items: center;
      height: 24px;
      margin-top: 2px;
      box-sizing: border-box;
      border-radius: 12px;
      position: relative;
      overflow: hidden;
      justify-content: space-between;
      font-size: 12px;
      line-height: 25px;

      @include themify() {
        border: 2px solid rgba(t('color-white'), 0.3);
        background: t('color-dark-grey-opacity');
      }

      &__bar {
        position: absolute;
        height: 20px;
        border-radius: 12px;

        &_vk {
          border-radius: 0;
          position: relative;
          background-color: #4c75a3;
        }

        &_sms,
        &_rcs {
          border-radius: 0;
          position: relative;

          @include themify() {
            background-color: t('color-white');
          }
        }

        &_viber {
          border-radius: 0;
          position: relative;
          background-color: #8e24aa;
        }
      }
    }
  }

  &__channels {
    display: flex;
    align-items: center;
    justify-content: center;

    &__item {
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;
      font-size: 14px;
      line-height: 19px;
      letter-spacing: 0.15px;
      font-family: $primary-font-bold;
      margin: 0 10px;
      height: 61px;

      &__icon {
        flex: 1;

        &_vk {
          width: 30px;
        }

        &_rcs {
          width: 30px;
        }

        &_viber,
        &_sms {
          height: 30px;
        }

        &_rcs,
        &_sms {
          @include themify() {
            filter: t('img-invert');
          }
        }
      }
    }
  }

  &__status {
    display: flex;
    align-items: center;
    text-transform: uppercase;
    margin: 10px 0 7px 0;
    $status: &;

    @include themify() {
      color: t('color-grey');

      &::before,
      &::after {
        background-color: t('color-grey');
      }
    }

    &::before,
    &::after {
      height: 1px;
      flex: 1;
      content: '';
    }

    &::before {
      margin-right: 12px;
    }

    &::after {
      margin-left: 12px;
    }

    &_in_progress {
      @include themify() {
        color: t('color-orange');

        &::before,
        &::after {
          background-color: t('color-orange');
        }
      }
    }

    &_failed {
      @include themify() {
        color: t('color-attention');

        &::before,
        &::after {
          background-color: t('color-attention');
        }
      }
    }

    &_complete {
      @include themify() {
        color: t('color-green');

        &::before,
        &::after {
          background-color: t('color-green');
        }
      }
    }

    &_scheduled {
      @include themify() {
        color: t('accent-color');

        &::before,
        &::after {
          background-color: t('accent-color');
        }
      }
    }
  }

  &__statistic {
    display: flex;

    &__item {
      display: flex;
      flex: 1;
      align-items: center;
      flex-direction: column;

      &__icon {
        flex: 1;
        height: 15px;
        margin: 5px 0 4px 0;

        @include themify() {
          filter: t('img-white');
        }
      }

      &__title {
        font-size: 12px;
        line-height: 16px;
        letter-spacing: 0.15px;
        margin-top: 4px;

        @include themify() {
          color: t('color-grey');
        }
      }

      &__count {
        font-size: 22px;
        line-height: 30px;
      }
    }
  }
}
