@import 'src/assets/variables';

.templates-screen {
  display: flex;
  flex-direction: column;
  min-height: 100%;
  width: 100%;

  & > div {
    flex: 1;
  }
}
