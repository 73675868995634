@import 'src/assets/variables';
@import 'src/assets/mixins';

.actions-field-array {
  display: flex;
  flex-direction: column;
  $field: &;

  &__field {
    display: flex;
    align-items: center;
    margin-bottom: 8px;

    div {
      max-width: 152px;

      &:not(:last-child) {
        margin-right: 12px;
      }
    }
  }
}
