@import 'src/assets/variables';
@import 'src/assets/mixins';

.present-block {
  display: flex;
  align-items: center;

  &__label {
    font-size: 14px;
    font-family: $primary-font-regular;
    white-space: nowrap;

    @include themify() {
      color: t('color-grey');
    }
  }
}
