@import 'src/assets/variables';
@import 'src/assets/mixins';
@import 'src/assets/keyframes';

.check-query-item {
  display: flex;
  align-items: center;

  &:not(:last-child) {
    margin-bottom: 15px;
  }

  &__circle {
    width: 24px;
    height: 24px;
    margin-right: 15px;
    border-radius: 50%;

    &_success {
      @include themify() {
        background-color: t('color-green');
      }
    }

    &_failure {
      cursor: pointer;

      @include themify() {
        background-color: t('color-attention');
      }
    }

    &_warning {
      cursor: pointer;

      @include themify() {
        background-color: t('color-orange');
      }
    }
  }

  &__text {
    font-size: 16px;
  }

  &__spinner {
    display: flex;
    text-align: center;
    margin-right: 15px;

    &__inner {
      position: relative;
      display: inline-block;
      width: 24px;
      height: 24px;
      border-radius: 50%;

      &_in-progress {
        animation: 1s  infinite turner linear;
      }

      @include themify() {
        background: linear-gradient(to right, t('accent-color') 50%, transparent 0);

        &::after {
          content: '';
          position: absolute;
          left: 0;
          right: 0;
          top: 0;
          bottom: 0;
          width: 70%;
          height: 70%;
          margin: auto;
          border-radius: 50%;
          background-color: t('primary-color');
        }
      }
    }
  }
}
