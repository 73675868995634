.sender-status-cell-renderer {
  display: flex;
  flex-direction: row;
  gap: 8px;
}

.moderation {
  color: #069697;
}
.pending {
  color: #D6981B;
}
.approved {
  color: #43A047;
}
.rejected {
  color: #F44336;
}
.draft {
  color: #5C6BC0;
}
