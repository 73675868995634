@import 'src/assets/mixins';
@import 'src/assets/variables';

.scenarios-screen {
  display: flex;
  flex-direction: column;
  height: 100%;

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 22px;
    line-height: 30px;
  }

  &__tab {
    display: flex;
    align-items: center;
    margin-right: 20px;
    font-family: $primary-font-regular;
    font-weight: normal;

    button {
      position: absolute;
      right: 5px;
      width: 20px;
      min-width: 20px;
      height: 20px;

      img {
        width: 15px;
      }
    }
  }
}

.ant-tabs-tabpane-active {
  height: 100% !important;
}
