@import 'src/assets/variables';

@mixin themify() {
  @each $theme, $map in $themes {
    .theme_#{$theme},
    .theme_#{$theme} & {
      $theme-map: () !global;
      @each $key, $submap in $map {
        $value: map-get(map-get($themes, $theme), '#{$key}');
        $theme-map: map-merge($theme-map, ($key: $value)) !global;
      }
      @content;
      $theme-map: null !global;
    }
  }
}

@function t($key) {
  @return map-get($theme-map, $key);
}

@mixin generator-img-filters(
  $prefix: '_filter',
  $list: ('black', 'white', 'invert', 'light', 'black-white', 'white-black', 'grey-blue'),
  $onlyList: ('only-black', 'only-white', 'only-invert', 'only-light', 'only-black-white'),
) {
  @include themify() {
    @each $type in $list {
      &#{$prefix}-#{$type} {
        filter: t('img-' + $type);
      }
    }

    @each $type in $onlyList {
      &#{$prefix}-#{$type} {
        filter: map-get(map-get($themes, 'dark'), 'img-white');
      }
    }
  }
}
