@import 'src/assets/mixins';

.mkit-autocomplete {

    &__input-wrapper {
        display: flex;
        align-items: center;

        img {
            opacity: .6;
            transition: opacity .3s ease-in-out;
        }
        &__prefix-icon {
            width: 12px;
            height: 12px;
            margin: 0 5px;
        }
        &__suffix-icon {
            width: 14px;
            height: 14px;
            margin-left: auto;
            opacity: .6 !important;
            cursor: pointer;
            &:hover {
                opacity: 1 !important;
            }
        }

        input {
            width: calc(100% - 36px);
            height: 24px;
            outline: none;
            background: none;
            border: none;
            transition: all .3s ease-in-out;
            letter-spacing: .55px;
            line-height: 16px;
            font-size: 12px;

            @include themify() {
                color: t('color-grey');

                &:focus{
                    color: rgba(255, 255, 255, .8);
                }
            }
        }
    }

    &__value-wrapper {
        display: flex;
        padding: 0 18px;
        line-height: 24px;
        font-size: 12px;
        transition: all .05s ease-in-out;
        cursor: pointer;

        @include themify() {
            background: t('primary-color');
            color: t('color-grey');

            &_highlighted {
                background: t('color-dark-grey-opacity');
                color: rgba(t('color-white'), .8);
            }
        }
    }
}
