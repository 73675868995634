.users-container {
  display: flex;
  flex-direction: column;
  min-height: 100%;
  width: 100%;

  > div {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    flex: 1;
  }
}
