@import 'src/assets/mixins';

.mkit-checkbox-field {
  display: flex;
  flex-flow: row;
  cursor: pointer;
  align-items: center;
  transition: opacity 0.3s ease-in-out;
  opacity: 0.6;
  background-color: transparent;
  border-color: transparent;
  position: relative;
  $field: &;

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  &:focus-within {
    opacity: 0.8;
  }

  &_disabled {
    opacity: 0.5;
    cursor: not-allowed;

    #{$field}__radio, input {
      cursor: not-allowed;
    }
  }

  &_checked {
    opacity: 1;
  }

  &_mirror {
    flex-direction: row-reverse;
    justify-content: flex-end;

    #{$field}__label {
      margin-left: unset;
      margin-right: 10px;
    }
  }

  &__radio {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    width: 24px;
    height: 24px;
    min-width: 24px;

    @include themify() {
      border: 1px solid t('color-white');
    }

    &__img {
      width: 18px;
      height: 18px;

      @include themify() {
        filter: t('img-invert');
      }
    }
  }

  &__icon {
    margin-left: 8px;
    width: 18px;
  }

  &__label {
    font-family: notosans-regular;
    font-size: 14px;
    margin-left: 8px;
    line-height: 19px;

    @include themify() {
      color: t('color-white');
    }
  }
}
