// fonts
$primary-font-regular: 'notosans-regular';
$primary-font-italic: 'notosans-italic';
$primary-font-bold: 'notosans-bold';
$roboto-font-regular: 'roboto-regular';
$roboto-font-italic: 'roboto-italic';
$roboto-font-bold: 'roboto-bold';

// colors
$color-grey: rgba(255, 255, 255, 0.6);
$color-grey_light: rgba(0, 45, 62, 0.6);
$color-dark-grey-opacity: rgba(0, 0, 0, 0.4);
$color-dark-grey-opacity_light: rgba(77, 138, 160, 0.2);
$color-dark-opacity: rgba(0, 0, 0, 0.1);
$color-dark-grey: #1a1d25;
$color-attention: #ff4040;
$color-attention_light: #dc3535;
$color-attention-2: #ff4646;
$color-basic-inactive: #aaadb2;
$color-basic-inactive_light: #002d3e;
$color-border: #c4c4c4;
$color-light-grey: #d6d6d6;
$color-gainsboro: #ebf0f5;
$color-deep-grey: #999;
$color-green: #38a213;
$color-green_light: #2ba300;
$color-orange: #eaa726;
$color-orange_light: #de9c1c;
$color-black: #000;
$color-black_light: #002d3e;
$color-white: #fff;
$color-white_light: #002d3e;
$color-bg-table-hover: rgba(#191b1e, 0.7);
$color-bg-table-hover_light: rgba(77, 138, 160, 0.2);
$color-dark-blue: #36498b;
$color-purple: #6959d5;
$color-lime: #dbe8ec;
$color-primary-light: rgb(43, 49, 62);

$color-basic-active: #002d3e;

$primary-white-color: #a7a7ac;
$primary-white-color_light: #5f7b87;

//$color-grey-08: #41434d;
//$color-grey-08_light: #fafbfc;
//$color-grey-07: #bdbdc1;
//$color-grey-07_light: #fafbfc;

$primary-color: #222430;
$primary-color_light: #eef1f4;
$secondary-color: #2b313e;
$secondary-color_light: #fff;
$accent-color: #0c91c0;
$accent-color_light: #4d8aa0;
$dark-blue-color: #2e3344;
$viber-text-bg-color: #3f4c5c;
$dropdown-item-hover: #0e0f11;
$inline-calendar-color: rgba(#575962, 0.5);
$bg-whole-menu-wrapper_light: #002d3e;
$bg-pivot-table-level-1: #353b48;
$bg-pivot-table-level-1_light: rgba($bg-pivot-table-level-1, 0.1);
$bg-pivot-table-level-2: #3f4552;
$bg-pivot-table-level-2_light: rgba($bg-pivot-table-level-2, 0.15);
$bg-pivot-table-level-3: #494f5c;
$bg-pivot-table-level-3_light: rgba($bg-pivot-table-level-3, 0.2);
$bg-pivot-table-level-4: #535966;
$bg-pivot-table-level-4_light: rgba($bg-pivot-table-level-4, 0.25);

// styles
$border-style: 1px solid $color-grey;
$box-shadow: 4px 0 4px rgba(0, 0, 0, 0.1);
// get inline styles
@function border-style($color) {
  @return 1px solid $color;
}
@function box-shadow($color) {
  @return 4px 0 4px $color;
}

// font sizes
//$fontSizeExtraSmall: 12px;
//$fontSizeSmall: 14px;
//$fontSizeMedium: 16px;
//$fontSizeLarge: 18px;
//$fontSizeExtraLarge: 20px;
//$fontSizeModalTitle: 24px;
//$fontSizeGreat: 32px;
//$fontSizeTitanic: 40px;

$green: #1bd741;
$orange: #f3b71a;
$red: #f46060;
$red-2: #ff4040;
$blue: #2fcac1;
$pink: #f85eba;
$white: #fff;
$grey: #c2cdd1;
$primary: #222430;

// new-colors
$d-222430-08-222430: #41434d;
$l-222430-08-222430: #325664;
$d-fff-07-222430: #bdbdc1;
$l-fff-07-222430: #fafbfc;
$d-fff-00-222430: #fff;
$l-fff-00-222430: #002d3e;

$d-fff: #fff;
$l-fff: #002d3e;
$d-002d3e: #002d3e;
$l-002d3e: #fff;
$l-075E55: #075e55;
$l-EFE6DD : #efe6dd;
$d-CED2D8: #ced2d8;
$d-262a37: #262a37;
$l-262a37: #fff;
$l-262a37-1-fff: #dfe4eb;
//  возможно сделаем через to
// (dark/light)-(цвет)-(прозрачность)-(цвет бекграунда)-to-(цвет в светлой теме)
// $d-fff-00-222430-to-002d3e
//$d-fff: #fff;
//$l-fff: #002d3e;
//$d-fff-00-222430: #fff;
//$l-fff-00-222430: #002d3e;

$themes: (
  dark: (
    '222430-08-222430': $d-222430-08-222430,
    fff-07-222430: $d-fff-07-222430,
    fff-00-222430: $d-fff-00-222430,
    fff: $d-fff,
    d-ced2d9: $d-CED2D8,
    d-262a37: $d-262a37,
    '262a37-1-fff': $d-262a37,

    color-grey: $color-grey,
    color-dark-grey-opacity: $color-dark-grey-opacity,
    color-dark-grey: $color-dark-grey,
    color-attention: $color-attention,
    color-attention-2: $color-attention-2,
    color-basic-inactive: $color-basic-inactive,
    color-border: $color-border,
    color-light-grey: $color-light-grey,
    color-deep-grey: $color-deep-grey,
    color-green: $color-green,
    color-orange: $color-orange,
    color-black: $color-black,
    color-white: $color-white,
    color-bg-table-hover: $color-bg-table-hover,
    color-dark-blue: $color-dark-blue,
    color-purple: $color-purple,
    primary-color: $primary-color,
    secondary-color: $secondary-color,
    accent-color: $accent-color,
    dark-blue-color: $dark-blue-color,
    color-basic-active: $color-basic-active,
    viber-text-bg-color: $viber-text-bg-color,
    dropdown-item-hover: $dropdown-item-hover,
    inline-calendar-color: $inline-calendar-color,
    bg-whole-menu-wrapper: $secondary-color,
    color-tree-dropdown: $color-grey,
    bg-chat-header: $color-dark-grey,
    bg-dropdown: $color-dark-grey,
    bg-main-menu-hover: $primary-color,
    primary-white-color: $primary-white-color,
    primary-lime-color: $primary-color,
    bg-drawer: $color-primary-light,

    bg-pivot-table-level-1: $bg-pivot-table-level-1,
    bg-pivot-table-level-2: $bg-pivot-table-level-2,
    bg-pivot-table-level-3: $bg-pivot-table-level-3,
    bg-pivot-table-level-4: $bg-pivot-table-level-4,

    dialog-bg: linear-gradient(90deg, rgba($accent-color, 0.6) 0%, rgba($accent-color, 0.4) 50%, rgba($accent-color, 0.1) 100%),

    border-style: border-style($color-grey),
    box-shadow: box-shadow(rgba(0, 0, 0, 0.1)),
    img-invert: none,
    img-black: brightness(0),
    img-white: brightness(0) invert(1),
    img-light: brightness(0) invert(1),
    img-black-white: brightness(0) invert(1),
    img-white-black: invert(0),
    img-grey-blue: none,
    auth-background: 'background-dark.png',
  ),
  light: (
    '222430-08-222430': $l-222430-08-222430,
    fff-07-222430: $l-fff-07-222430,
    fff-00-222430: $l-fff-00-222430,
    fff: $l-fff,
    d-ced2d9: $d-CED2D8,
    d-262a37: $l-262a37,
    '262a37-1-fff': $l-262a37-1-fff,

    color-grey: $color-grey_light,
    color-dark-grey-opacity: $color-dark-grey-opacity_light,
    color-dark-grey: $color-dark-grey,
    color-attention: $color-attention_light,
    color-attention-2: $color-attention-2,
    color-basic-inactive: $color-basic-inactive_light,
    color-border: $color-border,
    color-light-grey: $color-light-grey,
    color-deep-grey: $color-deep-grey,
    color-green: $color-green_light,
    color-orange: $color-orange_light,
    color-black: $color-black_light,
    color-white: $color-white_light,
    color-bg-table-hover: $color-bg-table-hover_light,
    color-dark-blue: $color-dark-blue,
    color-purple: $color-purple,
    primary-color: $primary-color_light,
    secondary-color: $secondary-color_light,
    accent-color: $accent-color_light,
    dark-blue-color: $dark-blue-color,
    viber-text-bg-color: $viber-text-bg-color,
    dropdown-item-hover: $dropdown-item-hover,
    inline-calendar-color: $inline-calendar-color,
    bg-whole-menu-wrapper: $bg-whole-menu-wrapper_light,
    color-tree-dropdown: $color-white_light,
    bg-chat-header: $color-lime,
    bg-dropdown: $primary-color_light,
    bg-main-menu-hover: $color-dark-grey-opacity_light,
    primary-white-color: $primary-white-color_light,
    auth-background: 'background-light.png',
    primary-lime-color: $color-lime,
    bg-drawer: $color-white,

    bg-pivot-table-level-1: $bg-pivot-table-level-1_light,
    bg-pivot-table-level-2: $bg-pivot-table-level-2_light,
    bg-pivot-table-level-3: $bg-pivot-table-level-3_light,
    bg-pivot-table-level-4: $bg-pivot-table-level-4_light,

    dialog-bg: linear-gradient(90deg, rgba($accent-color_light, 0.6) 0%, rgba($accent-color_light, 0.4) 50%, rgba($accent-color_light, 0.1) 100%),

    border-style: border-style($color-grey_light),
    box-shadow: box-shadow(rgba(0, 0, 0, 0.1)),
    img-invert: brightness(0.6),
    img-black: none,
    img-white: none,
    img-light: brightness(0) invert(1),
    img-black-white: brightness(0) invert(0.7),
    img-white-black: invert(1),
    img-grey-blue: invert(45%) sepia(29%) saturate(302%) hue-rotate(153deg) brightness(95%) contrast(94%),
  ),
);
