@import 'src/assets/mixins';

.attribute-manager {
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100%;

  &__title {
    display: flex;
    flex-direction: column;
    letter-spacing: 0.15px;
    font-size: 18px;
    line-height: 25px;

    @include themify() {
      color: t('color-white');
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    flex: 1;
    overflow-y: auto;
  }

  &__buttons {
    display: flex;
    justify-content: space-between;
  }
}
