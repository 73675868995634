.mkit-dnd-suggestions-outside {
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  &:not(:empty) {
    margin-top: 24px;
  }

  &__wrapper {
    cursor: grab;
    user-select: none;
    z-index: 999999;
    margin: 2px 4px 2px 4px;
  }
}
