.create-report-form {
  display: flex;
  flex-direction: column;
  height: 100%;

  &__description {
    margin: 10px 0 15px;
    opacity: 0.8;
  }
}
