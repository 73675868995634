@import 'src/assets/variables';
@import 'src/assets/mixins';

.wechat-block {
  @include themify() {
    display: flex;
    flex-direction: column;
    min-height: 100%;
    overflow: auto;

    &__header {
      font-size: 18px;
      line-height: 25px;
      color: t('color-white');
      margin-bottom: 10px;
    }

    &__tools,
    &__time-tools {
      display: flex;
      align-items: center;
      margin-bottom: 12px;
    }

    &__time-tools {
      margin-bottom: 24px;
    }

    &__content-wrapper {
      display: flex;
      flex-direction: column;
      width: 419px;
      height: 650px;
      overflow-y: hidden;
      background: url("../../../assets/icons/phone-bg.svg") no-repeat 100%;
      margin: auto;
      padding: 17px 18px;

      &_new-design {
        width: 422px;
        height: 650px;
        background: url("../../../assets/icons/phone_new.svg") no-repeat 100%;
        padding: 17px 20px 0 17px;

        .constructor-editor {
          background-color: #EFEFEF;
          border-radius: 0;
        }

        /* hack will fixed in lib soon */
        .mkit-constructor-editor {
          border-radius: 0;
        }
      }

      &__header {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        height: 48px;
        width: 100%;

        &_new-design {
          height: 49px;
          background-color: #2FBC01;
          padding: 0 15px;
          border-radius: 30px 30px 0 0;
        }
      }
    }

    &__label {
      font-size: 14px;
      line-height: 19px;
      letter-spacing: 0.15px;
      margin-right: 22px;
      color: t('color-grey');
      min-width: 140px;
    }

    &__buttons {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }
}
