@import 'src/assets/variables';
@import 'src/assets/mixins';

.pivot-table {
  flex: 1;

  table {

    .ant-table-thead > tr > th,
    .ant-table-tbody > tr > td {
      padding: 0 !important;
      position: relative;
      border-right: 1px solid;
      background-color: unset !important;
      white-space: nowrap;

      @include themify() {
        border-color: rgba(t('color-white'), 0.2) !important;
        border-bottom: 1px solid rgba(t('color-white'), 0.2) !important;
      }

      &:not(:last-child) {
        padding: 0 !important;
      }

      &:first-child {
        padding: 0 25px 0 0 !important;
      }

      &:last-child {
        padding: 0 !important;
        border-right: none !important;
      }
    }

    .ant-table-thead > tr > th {
      font-variant: small-caps;

      @include themify() {
        border-bottom: 2px solid rgba(t('color-white'), 0.4) !important;
      }
    }

    .ant-table-align-right {
      text-align: right;
    }
    .ant-table-thead > tr > th {
      &:last-child,
      &:not(:last-child) {
        padding-left: 12px !important;
      }
      &:first-child {
        padding-left: 0 !important;
      }
    }
  }

  .ant-table-row-cell-last {
    border-right: none !important;
  }

  .ant-table-wrapper,
  .ant-spin-nested-loading,
  .ant-spin-container,
  .ant-table,
  .ant-table-content,
  .ant-table-scroll,
  .ant-table-body {
    height: 100%;
  }

  .ant-table-empty .ant-table-body {
    height: unset;
  }

  .ant-table-placeholder {
    display: flex;
    align-items: center;
    justify-content: center;
    height: calc(100% - 35px);
    border-bottom: none;
  }

  .ant-spin-nested-loading > div > .ant-spin {
    max-height: unset;
  }

  .ant-table-row-level-1 {
    //background-color: rgb(43, 49, 62) !important;
    @include themify() {
      background-color: t('bg-pivot-table-level-1') !important;
    }
  }

  .ant-table-row-level-2 {
    @include themify() {
      background-color: t('bg-pivot-table-level-2') !important;
    }
  }

  .ant-table-row-level-3 {
    @include themify() {
      background-color: t('bg-pivot-table-level-3') !important;
    }
    //background-color: rgba(255, 255, 255, 0.3) !important;
  }

  .ant-table-row-level-4 {
    @include themify() {
      background-color: t('bg-pivot-table-level-4') !important;
    }
  }

  .ant-empty-description {
    display: none;
  }
}
