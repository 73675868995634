@import 'src/assets/mixins';

.ant-drawer-body {
  width: 100%;
  height: 100%;
}

.ant-drawer-content {
  @include themify() {
    background-color: t('bg-drawer');
  }
}

.ant-drawer-close {
  width: 16px;
  height: 16px;
  top: 24px;
  right: 24px;
  line-height: unset;
  transition: color .3s ease-in-out;

  @include themify() {
    color: t('color-grey');

    &:hover {
      color: t('color-white') !important;
    }
  }

  i {
    width: 100%;
    height: 100%;
    line-height: unset;
    vertical-align: unset;
    font-size: 14px;

    svg {
      font-size: 14px;
    }
  }
}
