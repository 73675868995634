@import 'src/assets/variables';
@import 'src/assets/mixins';

.mkit-image-element {
  display: flex;
  width: 100%;
  max-height: 400px;
  min-height: 139px;
  position: relative;
  grid-area: image;
  cursor: pointer;
  $image-wrapper: &;

  &:hover #{$image-wrapper} {
    &__thumbnail,
    &__cross-button,
    &__height-tools {
      display: flex;
    }
  }

  &_with-cursor {
    cursor: pointer;
  }

  &_vertical,
  &_left,
  &_right {
    height: 100%;

    @include themify() {
      border: 2px solid t('color-light-grey');
    }
  }

  &_vertical {
    border-radius: 8px 8px 0 0;

    @include themify() {
      border-bottom: none;
    }

    #{$image-wrapper}__image {
      border-radius: 6px 6px 0 0;
    }
  }

  &_left {
    border-radius: 8px 0 0 8px;
    width: 130px;
    margin-left: auto;

    @include themify() {
      border-right: none;
    }

    #{$image-wrapper} {
      &__image {
        border-radius: 6px 0 0 6px;
      }

      &__add-icon {
        margin-right: 4px;
      }
    }
  }

  &_right {
    border-radius: 0 8px 8px 0;
    width: 130px;

    @include themify() {
      border-left: none;
    }

    #{$image-wrapper} {
      &__image {
        border-radius: 0 6px 6px 0;
      }

      &__add-icon {
        margin-right: 4px;
      }
    }
  }

  &_border {
    @include themify() {
      border: 2px dotted $color-light-grey;
    }
  }

  &_square,
  &_square-border {
    height: 359px;
    width: 359px;
    min-height: 359px;
    border-radius: 0 10px 10px 10px;

    #{$image-wrapper} {
      &__image {
        border-radius: 0 10px 10px 10px;
      }
    }
  }

  &_square-border {
    border-radius: 0 10px 0 0;

    #{$image-wrapper}__image {
      border-radius: 0 10px 0 0;
    }

    &:empty {
      border-bottom: none;
    }
  }

  &_small {
    height: 140px;
  }

  &_medium {
    height: 200px;
  }

  &_height {
    height: 300px;
  }

  &_in-template {
    margin-bottom: 0;

    #{$image-wrapper}__image {
      border-radius: 6px;
    }

    @include themify() {
      border: none;
    }
  }

  &_any-file-in-template,
  &_any-file {
    border-radius: 8px;

    @include themify() {
      border: 2px solid $color-light-grey;
    }

    #{$image-wrapper}__image {
      border-radius: 6px;
    }
  }

  &_any-file-in-template {
    border-radius: 6px;
    margin-bottom: 0;

    @include themify() {
      border: none;
    }
  }

  &__icons-row {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
  }

  &__add-icon {
    width: 40px;
    height: 40px;
    margin-left: 15px;
  }

  &__thumbnail {
    display: none;
    position: absolute;
    bottom: 10px;
    left: 10px;
    border-radius: 8px;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    object-fit: cover;
    max-width: 36px;
    max-height: 36px;
    width: 100%;
    height: 100%;
    transition: max-height 0.1s linear, max-width 0.1s linear;

    &:hover {
      max-width: 66px;
      max-height: 66px;
    }

    @include themify() {
      background-color: $color-white;
    }
  }

  &__height-tools {
    display: none;
    align-items: flex-end;
    justify-content: space-between;
    border-radius: 8px;
    width: 80px;
    box-sizing: border-box;
    padding: 6px;
    position: absolute;
    top: 10px;
    left: 10px;

    @include themify() {
      background-color: $color-white;
    }
  }

  &__image {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }

  &__cross-button {
    display: none;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    position: absolute;
    border-radius: 50%;
    width: 32px;
    height: 32px;
    padding: 10px;
    box-sizing: border-box;
    right: 10px;
    top: 10px;

    @include themify() {
      background: $color-white;
    }

    img {
      width: 18px;
      height: 18px;
    }
  }
}
