@import 'src/assets/variables';
@import 'src/assets/mixins';

.mkit-table {
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 24px;
  min-height: 1px;
  z-index: 1;

  @media (max-width: 1380px) {
    zoom: 77%;
  }

  @include themify() {
    background: t('secondary-color');
    box-shadow: t('box-shadow');
  }

  &__header {
    display: flex;
    align-items: center;
    margin-bottom: 8px;
    justify-content: space-between;
    font-size: 18px;
    line-height: 25px;
    font-family: $primary-font-regular;

    @include themify() {
      color: t('color-white');
    }

    &__left {
      &__title {
        margin: 0 24px 0 0;
        font-size: 18px;
        line-height: 25px;
      }

      &__component {
        display: flex;
      }
    }

    &__left,
    &__right {
      display: flex;
      align-items: center;
    }

    &__right {
      &:empty {
        display: none;
      }

      @include themify() {
        &__dropdown {
          width: 24px;
          height: 24px;
          margin-left: 24px;
          opacity: 0.6;
          transition: opacity 0.3s ease-in-out;
          cursor: pointer;
          filter: t('img-invert');

          &:hover {
            opacity: 1;
          }
        }
      }
    }
  }

  &__pagination {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding-top: 12px;

    @include themify() {
      border-top: t('border-style');
    }
  }

  .ant-table-pagination {
    display: none;
  }

  @include themify() {
    .ant-table-row-cell-break-word,
    .ant-table-thead > tr > th {
      background-color: t('secondary-color');
      color: t('color-white');
    }

    .table table .ant-table-tbody > tr > td {
      background: t('secondary-color');
      color: t('color-white');
    }
  }
}
