@import 'src/assets/variables';
@import 'src/assets/mixins';

.campaign-card {
  &__rollback {
    width: 21px;
    height: 18px;
    margin-right: 8px;

    @include themify() {
      filter: t('img-invert');
    }
  }

  &__carousel {
    &__title {
      display: flex;
      justify-content: space-between;
      flex: 1;
      font-size: 14px;
      line-height: 19px;
      letter-spacing: 0.15px;
      margin: 0 16px;

      @include themify() {
        color: t('color-grey');
      }
    }
  }

  &__show-all {
    font-family: $primary-font-regular;
    letter-spacing: 0.15px;
    font-size: 14px;
    line-height: 19px;
    text-decoration-line: underline;
    cursor: pointer;

    @include themify() {
      color: t('accent-color');
    }
  }
}
