@import 'src/assets/variables';
@import 'src/assets/mixins';
@import 'src/assets/extend';

.info-modal {
  &__description {
    @extend %warning-modal__description;
  }

  &__buttons {
    justify-content: flex-end;
    @extend %warning-modal__buttons;
  }
}
