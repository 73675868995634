@import 'src/assets/variables';

.basic-field-array {
  display: flex;
  flex-direction: column;

  &__field {
    display: flex;
    align-items: center;
    margin-top: 10px;

    & > *:first-child {
      flex: 1;
    }
  }
}
