@import 'src/assets/mixins';

.mkit-icon-button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  min-width: 32px;
  height: 32px;
  border-radius: 2px;
  cursor: pointer;
  $icon-button: &;

  &:hover {
    #{$icon-button}__image {
      opacity: 1;
    }
  }

  &_selected {
    @include themify() {
      background-color: t('color-black');
    }

    #{$icon-button}__image {
      opacity: 1;

      @include themify() {
        filter: t('img-light');
      }
    }
  }

  &__image {
    opacity: 0.6;
    transition: opacity 0.3s ease-in-out;

    // @include themify() {
    //   filter: t('img-invert');
    // }

    @include generator-img-filters();
  }

  &:disabled {
    cursor: not-allowed;
    opacity: 0.4;

    &:hover {
      #{$icon-button}__image {
        opacity: 0.6;
      }
    }
  }
}
