@import 'src/assets/mixins';

.mkit-custom-view-wrapper {
  @include themify() {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    color: t('color-grey');
    font-family: 'notosans-regular';

    &__header {
      display: flex;
      flex-direction: column;
      line-height: 19px;
      letter-spacing: .15px;

      h1 {
        font-size: 22px;
        line-height: 30px;
        color: t('color-white');
      }

      &__subheader {
        font-size: 14px;
      }
    }

    &__content-wrapper {
      display: flex;
      flex-direction: column;
      margin-top: 24px;
      overflow: hidden;
      flex-grow: 1;

      &__values-list {
        margin-top: 30px;
        overflow-y: scroll;
      }
    }

    &__buttons-wrapper {
      display: flex;
      justify-content: space-between;
      margin-top: 30px;
    }
  }
}
