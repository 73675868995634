@import 'src/assets/variables';
@import 'src/assets/mixins';

.tree-dropdown-field {
  display: flex;
  flex-direction: column;
  padding: 0 10px;
  max-height: 36px;
  overflow-y: hidden;
  transition: max-height 0.3s;
  font-size: 14px;
  line-height: 36px;
  $dropdown: &;

  @include themify() {
    color: t('color-tree-dropdown');
  }

  &_selected {
    max-height: 100%;
    overflow-y: visible;

    #{$dropdown}__button {
      @include themify() {
        color: t('color-white');
      }

      &::before {
        transform: rotate(90deg);
      }
    }
  }

  &__button {
    &::before {
      content: '';
      width: 7px;
      height: 12px;
      background: url("../../../assets/icons/arrow-right.svg") no-repeat center;
      opacity: 0.6;
      background-size: 100%;
      transition: transform 0.3s ease-in-out;
      margin-right: 16px;
    }

    @include themify() {
      &::before {
        filter: t('img-invert');
      }
    }
  }

  &__sub-item {
    padding: 10px 0 10px 28px;
    margin-left: 22px;

    @include themify() {
      &:hover {
        background-color: t('color-dark-grey-opacity');
      }

      &_selected {
        background-color: t('color-dark-grey-opacity');
        color: t('color-white');
      }
    }
  }

  &__button,
  &__sub-item {
    display: flex;
    align-items: center;
    cursor: pointer;
    transition: color 0.3s ease-in-out;
    letter-spacing: 0.15px;
    text-align: left;

    @include themify() {
      &:hover {
        color: t('color-white');
      }
    }
  }
}
