@import 'src/assets/variables';
@import 'src/assets/mixins';

.response-group-field-array {
  display: flex;
  flex-direction: column;
  $basic-field-array: &;

  &__fields {
    //display: flex;
    //align-items: center;
  }

  & > button {
    align-self: flex-start;
    margin-top: 24px;
  }
}
