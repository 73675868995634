@import 'src/assets/variables';
@import 'src/assets/mixins';

.whats-app-template-block {
  @include themify() {
    display: flex;
    flex-direction: column;
    min-height: 100%;
    overflow: auto;

    &__header {
      font-size: 18px;
      line-height: 25px;
      color: t('color-white');
      margin-bottom: 30px;
      padding: 24px 24px 0;
    }

    &__row {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 24px;
    }

    &__content-wrapper {
      display: flex;
      flex-direction: column;
      width: 419px;
      height: 650px;
      overflow-y: hidden;
      background: url("../../../assets/icons/phone-bg.svg") no-repeat 100%;
      margin: auto auto 0 auto;
      padding: 17px 18px 0;

      &__header {
        display: flex;
        align-items: center;
        height: 48px;
        width: 100%;
        background-color: $l-075E55;
        border-radius: 22px 22px 0 0;
        font-family: $primary-font-bold;
        color: $color-white;
      }
    }

    &__hint {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 12px 5px;
      box-sizing: border-box;
      color: t('color-grey');
      width: 100%;
      margin-bottom: auto;
      background-color: t('color-dark-grey-opacity');

      &__image {
        margin-right: 10px;
        opacity: 0.6;
      }
    }

    &__buttons {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 24px 24px;
    }
  }
}
