@import 'src/assets/variables';
@import 'src/assets/mixins';

.card-display {
  display: flex;
  flex-direction: column;
  min-height: 100%;
  overflow: auto;
  position: relative;
  $block: &;

  &_whats-app {
    #{$block} {
      &__content-wrapper {
        &__header {
          background-color: $l-075E55;
          border-radius: 22px 22px 0 0;
        }
      }
    }
  }

  &__header {
    font-size: 18px;
    line-height: 25px;
    margin-bottom: 10px;

    @include themify() {
      color: t('color-white');
    }
  }

  &__content-wrapper {
    display: flex;
    flex-direction: column;
    width: 419px;
    height: 646px;
    overflow-y: hidden;
    background: url("../../../assets/icons/phone-bg-large.svg");
    background-size: 419px 646px;
    margin: auto;
    padding: 17px 18px 19px;

    &__header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 50px;
      width: 100%;

      .basic-button {
        color: $primary-color_light;
      }

      &__trap {
        display: flex;
        align-items: center;
        width: 188px;
        font-size: 14px;
        line-height: 19px;
        letter-spacing: 0.15px;
        cursor: pointer;
        color: $primary-color_light;

        &__icon {
          margin-right: 10px;
        }

        &::after {
          content: '';
          height: 7px;
          width: 12px;
          background: url("../../../assets/icons/arrow_down.png") no-repeat 100%;
          margin: auto 20px auto auto;
        }
      }
    }
  }
}
