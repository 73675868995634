@import 'src/assets/variables';
@import 'src/assets/mixins';

.mkit-constructor-editor {
  display: flex;
  max-height: 584px;
  flex: 1;
  border-radius: 0 0 22px 22px;
  font-family: $roboto-font-regular;

  @include themify() {
    color: t('color-black');
  }

  &_milk-bg {
    background-color: $l-EFE6DD;
  }

  &__notice-message {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
    font-size: 16px;

    @include themify() {
      color: t('color-deep-grey');
    }
  }

  &__choose-type {
    display: grid;
    grid-gap: 24px;
    grid-template-columns: 72px 72px;
    grid-auto-columns: auto;
    margin: auto;
  }

  ::-webkit-scrollbar,
  &::-webkit-scrollbar {
    width: 0;
    height: 0;
  }
}
