.mkit-edit-tags-group {
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;

    &__tags {
        margin: -4px;
        display: flex;
        align-items: center;
        flex-wrap: wrap;

        & > * {
            padding: 4px;
        }
    }
}
