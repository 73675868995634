@import 'src/assets/mixins';

.date-field {
  position: relative;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;

  &:focus-within &__label {
    opacity: .8;
  }

  &:focus-within  &__input-wrapper__input {
    opacity: .8;
  }

  &:focus-within  &__input-wrapper {
    opacity: .8;
  }

  &__label {
    font-family: 'notosans-regular';
    font-size: 14px;
    letter-spacing: 0.15px;
    line-height: 19px;
    padding: 8px 8px 8px 0;
    transition: opacity .3s ease-in-out;

    @include themify() {
      color: t('color-white');
      opacity: .6;
    }
  }

  &__input-wrapper {
    padding: 8px;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
    min-width: 200px;
    transition: opacity .3s ease-in-out;

    @include themify() {
      border-bottom: t('color-white') solid 1px;
      opacity: .6;
    }

    &__input {
      -webkit-appearance: none;
      outline: none;
      border: none;
      background: none;
      font-family: notosans-regular;
      font-size: 14px;
      letter-spacing: 0.15px;
      width: 100px;
      transition: opacity .3s ease-in-out;

      @include themify() {
        color: t('color-white');
        opacity: .6;

        &::placeholder {
          color: t('color-white');
          opacity: .6;
        }
      }

      &::placeholder {
        font-family: notosans-italic;
        font-size: 14px;
        line-height: 19px;
        letter-spacing: 0.15px;
      }
    }

    &__img {
      height: 18px;
      width: 18px;
      cursor: pointer;

      @include themify() {
        filter: t('img-white');
      }
    }
  }

  &__calendar {
    display: none;
    z-index: 100;
    position: absolute;
    right: 0;
    top: 40px;

    &-open {
      display: block;
    }
  }
}
