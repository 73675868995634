

.mkit-tags-group {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    $tags-group: &;

    &_detail #{$tags-group}__tags {
       flex-wrap: wrap;
    }

    &__tags {
        margin: -4px;
        // margin: 1em 0;
        display: flex;
        // flex-wrap: wrap;
        align-items: center;

        & > * {
            padding: 4px;
        }
    }
}
