@import 'src/assets/variables';
@import 'src/assets/mixins';
@import 'src/assets/keyframes';

.test-services-layout {
  display: flex;
  flex-direction: column;

  &__header {
    font-size: 22px;
    line-height: 30px;
    margin-bottom: 24px;
  }

  &__wrapper {
    display: grid;
    width: 100%;
    grid-gap: 24px;
    grid-template-columns: repeat(auto-fill, minmax(330px, 1fr));
    grid-auto-columns: auto;
  }

  &__category-header {
    font-size: 18px;
    line-height: 26px;
    margin-bottom: 20px;
  }

  &__tests-block {
    display: flex;
    flex-direction: column;
    padding-left: 20px;
    margin-bottom: 24px;
  }
}
