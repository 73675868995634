@import 'src/assets/variables';
@import 'src/assets/mixins';

.condition-field-array {
  display: flex;
  flex-direction: column;
  $basic-field-array: &;

  &__fields {
    &__field {
      margin-bottom: 24px;

      &:not(:first-child) {
        margin-top: 12px;
      }

      &__condition{
        display: flex;
        align-items: center;
        position: relative;
        height: 42px;
      }
    }
  }

  & > button {
    align-self: flex-start;
  }
}
