@import 'src/assets/variables';
@import 'src/assets/mixins';

.adding-funds-form {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  font-family: $primary-font-regular;

  @include themify() {
    color: t('color-grey');
  }

  &__header,
  &__label {
    display: flex;
    flex-direction: column;
    letter-spacing: 0.15px;
    font-size: 18px;
    line-height: 25px;

    @include themify() {
      color: t('color-white');
    }
  }

  &__row {
    display: flex;
    align-items: center;
    margin-bottom: 15px;

    &__label {
      margin-right: 65px;
    }

    &__logo {
      width: 120px;
      height: 36px;
      border-radius: 4px;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    height: 100%;
  }

  &__buttons {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    margin-top: 30px;

    &__group {
      display: flex;
    }
  }

  .form-field {
    display: flex;
    justify-content: space-between;

    & > span {
      width: 73%;
    }
  }
}
