@import 'src/assets/variables';
@import 'src/assets/mixins';

.mkit-file-field {
  display: flex;
  flex-direction: column;
  margin-top: 5px;
  margin-bottom: 5px;

  &__label {
    font-family: $primary-font-regular;
    font-size: 14px;
    line-height: 16px;
    margin-bottom: 8px;

    @include themify() {
      color: t('color-grey');
    }
  }

  &__input-wrapper {
    box-sizing: border-box;
    border-radius: 4px;
    opacity: 0.6;
    cursor: pointer;
    display: inline-block;
    position: relative;
    width: 100%;
    padding: 8px;
    font-size: 14px;
    line-height: 19px;
    transition: opacity 0.3s ease-in-out;
    font-family: $primary-font-italic;

    @include themify() {
      color: t('color-white');
      border: 1px solid t('color-white');
    }

    &:hover {
      opacity: 1;
    }

    &::after {
      content: '';
      position: absolute;
      width: 4px;
      height: 16px;
      right: 17px;
      top: 10px;
      background: url("../../../assets/icons/3dots.svg") no-repeat center;
      background-size: 100%;
      transform: rotate(90deg);
    }

    @include themify() {
      &::after {
        filter: t('img-invert');
      }
    }

    &__input {
      display: none;
    }
  }

  &__loaded-file {
    display: flex;
    align-items: center;
    font-size: 14px;
    line-height: 19px;
    letter-spacing: 0.15px;
    font-family: $primary-font-italic;

    @include themify() {
      color: t('color-white');
    }

    &:first-of-type {
      margin-top: 30px;
    }

    &:not(:last-of-type) {
      margin-bottom: 8px;
    }
  }
}
