@import 'src/assets/variables';

.hint {
  display: flex;
  position: absolute;
  align-items: center;
  border-radius: 10px;
  padding: 5px 20px 5px 10px;
  height: auto;
  min-width: 90px;
  max-width: 200px;
  background-color: $accent-color;
  box-sizing: border-box;
  flex-wrap: wrap;
  z-index: 200;

  &::before {
    content: '';
    width: 12px;
    height: 12px;
    background: url("../../assets/icons/thin-cross.svg") center no-repeat;
    opacity: 0.5;
    position: absolute;
    top: 8px;
    right: 10px;
    cursor: pointer;

    &:hover {
      opacity: 1;
    }
  }

  &::after {
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
  }

  &_right {
    margin-left: auto;

    &::after {
      right: -5px;
      border-left: 10px solid $accent-color;
    }
  }

  &_left {
    margin-right: auto;

    &::after {
      left: -5px;
      border-right: 10px solid $accent-color;
    }
  }

  &__circle {
    position: absolute;
    width: 100px;
    height: 100px;
    border-radius: 50%;
    border: 3px solid $accent-color;
    right: 20px;
    top: -15px;
    box-shadow: -5px 10px 65px 15px $accent-color;
    z-index: 50;
  }
}
