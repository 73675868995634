@import 'src/assets/variables';
@import 'src/assets/mixins';

.mkit-add-tag {

    &__wrapper {
        width: 24px;
        height: 24px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: border .3s ease-in-out;
        cursor: pointer;

        @include themify() {
            border: t('border-style');
        }

        &:hover {
            img {
                opacity: 1;
            }
        }

        &_opened {
            width: auto;
            height: 24px;
            border-radius: 8px;
            padding: 3px 5px;
            cursor: default;
            border: none;

            @include themify() {
                background-color: t('accent-color');
            }

            img {
                opacity: 1;
            }
        }

        &_incorrect {
            border: 1px solid #d33a3d !important;

            img {
                opacity: .6 !important;
            }
        }
    }

    &__button {
        width: 16px;
        height: 16px;
        border-radius: 50%;
        opacity: .6;
        transform: rotate(45deg);
        transition: all .3s ease-in-out;

        @include themify() {
            filter: t('img-white');
        }

        &:hover {
            opacity: .8;
        }
    }
}
