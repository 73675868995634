.row {
  display: contents;

  > div {
    grid-column: auto;
    padding: 12px 6px;
    display: flex;
    background-color: #fff;
    transition: background-color 0.2s ease-in-out;
    border-left: 1px solid var(--grey0);
    white-space: nowrap;

    &:empty::before {
      content: '—';
      color: var(--grey40);
    }
  }
}

.fakeCell {
  position: relative;
  padding: 12px 0 !important;
}

.button {
  color: var(--blue70);
  cursor: pointer;
  position: relative;
  user-select: none;

  &:hover {
    color: var(--blue50);
  }
}

.overlay {
  display: flex;
  flex-direction: column;
  position: absolute;
  right: 0;
  padding: 8px;
  box-shadow: var(--shadow2dp);
  z-index: 2;
  background-color: var(--bg15);
}

.line {
  display: flex;
  align-items: center;
  gap: 8px;

  > span:nth-child(1) {
    color: var(--grey50);

    &::after {
      content: ':';
    }
  }

  > span:nth-child(2) {
    color: var(--grey100);
  }
}

.missMatchVersion {
  color: var(--red60);
}
