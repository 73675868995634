@import 'src/assets/variables';

.status-cell-renderer {
  display: flex;
  align-items: center;
  font-size: 14px;
  line-height: 19px;
  letter-spacing: 0.15px;

  &__icon {
    margin-right: 10px;
  }
}
