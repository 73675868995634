@import 'src/assets/mixins';

.infographics{
  text-align: center;
  letter-spacing: 0.15px;

  &__image{
    height: 14px;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    margin-bottom: 4px;

    @include generator-img-filters();
  }

  &__title{
    font-size: 12px;
    line-height: 16px;
    margin-bottom: 2px;
  }

  &__text{
    font-size: 8px;
    line-height: 16px;
    margin-bottom: 3px;
  }

  &__numbers{
    align-items: center;
    display: flex;
    justify-content: center;
    font-size: 22px;
    line-height: 30px;
  }

  &--big{
    .infographics__image{
      height: 29px;
      margin-bottom: 0;
    }

    .infographics__title{
      font-size: 22px;
      line-height: 30px;
    }

    .infographics__text{
      font-size: 14px;
      line-height: 19px;
    }

    .infographics__numbers{
      font-size: 50px;
      line-height: 68px;
      height: 68px;
    }
  }
}
