@import 'src/assets/variables';
@import 'src/assets/mixins';

.dropdown-specify-field {
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  box-sizing: border-box;
  position: relative;

  $field: &;

  &__button-wrapper {
    display: flex;
  }

  &__label {
    font-size: 14px;
    margin-bottom: 15px;
    font-family: $primary-font-regular;
  }

  &__control {
    display: flex;
    flex: 1;
    align-items: center;
    // css-hack width display flex
    min-width: 10px;
    border-radius: 5px;
    height: 36px;
    min-height: 36px;
    box-sizing: border-box;
    position: relative;
    z-index: 1;
    $button: &;

    // todo убрать, когда выпилим либу antd с её вездесущими стилями
    background: transparent;

    @include themify() {
      border: t('border-style');
    }

    &__input {
      display: flex;
      width: 100%;
      flex-direction: row;
      align-items: center;
      background: transparent;
      min-width: 20px;
      box-sizing: border-box;
      margin-right: 30px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      z-index: 1;
      color: inherit;
      letter-spacing: 0;
      padding: 0 9px;
      font-family: $primary-font-regular;
      font-size: 14px;

      // todo убрать, когда выпилим либу antd с её вездесущими стилями
      border: none;

      &:disabled {
        cursor: not-allowed;
      }

      @include themify() {
        &::placeholder {
          font-family: $primary-font-italic;
          color: t('color-grey');
        }
      }
    }

    &__button {
      cursor: pointer;
      position: absolute;
      right: 14px;
      height: 15px;
      width: 15px;
      background: url("../../../assets/icons/right.svg") center no-repeat;
      transform: rotate(90deg);
      background-size: 7px 12px;
      opacity: 0.6;
      transition:
        transform 0.3s ease-in-out,
        opacity 0.3s ease-in-out;
      margin-bottom: -1px;

      @include themify() {
        filter: t('img-invert');
      }
    }

    &:hover {
      #{$button}__button {
        opacity: 1;
      }

      input {
        &::placeholder {
          opacity: 1;
        }
      }
    }
  }

  &__overlay {
    width: 100%;
    top: 36px;
    box-sizing: border-box;
    max-height: 0;
    display: flex;
    flex-direction: column;
    position: absolute;
    z-index: 20;
    visibility: hidden;
    opacity: 0;
    transition:
      visibility 0.3s ease-in-out,
      max-height 0.3s ease-in-out,
      opacity 0.3s ease-in-out,
      top 0.3s ease-in-out;
    overflow: auto;

    @include themify() {
      background-color: t('bg-dropdown');
    }

    &__item {
      display: flex;
      align-items: center;
      flex-direction: row;
      height: 36px;
      min-height: 36px;
      font-size: 14px;
      padding: 0 9px;
      cursor: pointer;
      box-sizing: border-box;
      font-family: $primary-font-regular;
      transition: background-color 0.3s ease-in;
      // todo убрать, когда выпилим либу antd с её вездесущими стилями
      border: none;
      background-color: unset;

      &__text {
        text-align: left;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }

      @include themify() {
        &:hover {
          background-color: t('color-dark-grey-opacity');
        }
      }
    }
  }

  &__error-text {
    transition: opacity 0.1s ease-in-out;
    position: absolute;
    bottom: -20px;
  }

  &_disabled#{$field} &__button {
    opacity: 0.5;
    cursor: not-allowed;

    &::after {
      opacity: 0.3;
    }
  }

  &_open #{$field} {
    &__overlay {
      top: 36px;
      visibility: visible;
      max-height: 320px;
      opacity: 1;
      z-index: 999;

      &:empty {
        display: none;
      }
    }

    &__button__button {
      transform: rotate(270deg);
    }
  }
}
