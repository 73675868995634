@import 'src/assets/variables';
@import 'src/assets/mixins';

.edit-node-condition-form {
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100%;

  &__title {
    display: flex;
    flex-direction: column;
    letter-spacing: 0.15px;
    font-size: 18px;
    line-height: 25px;

    @include themify() {
      color: ('color-white');
    }
  }

  &__sub-title {
    font-size: 16px;
    line-height: 40px;
    letter-spacing: 0.15px;
    margin: 0 0 12px 0;
  }

  &__buttons {
    display: flex;
    justify-content: flex-end;
    margin-top: auto;

    & button {
      min-width: 175px;
    }

    &__right {
      display: flex;
      margin-left: auto;

      & > *:nth-child(2) {
        margin-left: 12px;
      }
    }
  }
}
