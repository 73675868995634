@import 'src/assets/variables';
@import 'src/assets/mixins';

.mkit-viber-picture-card {
  @include themify() {
    display: flex;
    flex-direction: column;
    padding: 12px;
    flex: 1;

    &__input-wrapper {
      display: flex;
      flex-direction: column;
      border: 2px solid t('color-light-grey');
      padding: 10px 12px;
      border-radius: 0 0 10px 10px;
      box-sizing: border-box;
    }
  }
}
