.contacts-container {
  display: flex;
  flex-direction: column;
  min-height: 100%;
  width: 100%;

  .contacts {
    flex: 1;
  }
}
