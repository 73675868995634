@import 'src/assets/mixins';

.report-block {
  @include themify() {
    &__dropdown {
      opacity: 0.6;
      transition: opacity 0.3s ease-in-out;
      cursor: pointer;
      padding: 0 10px;
      margin-left: 10px;
      filter: t('img-invert');

      &:not(&_disabled):hover {
        opacity: 1;
      }

      &_disabled {
        cursor: not-allowed;
      }
    }
  }

  &__loading-label {
    line-height: 19px;
    font-size: 14px;
    margin-left: 5px;
    opacity: 0.6;

    @include themify() {
      color: t('color-white');
    }
  }
}
