@import 'src/assets/variables';
@import 'src/assets/mixins';

.mkit-switch-field {
  display: flex;
  min-height: 23px;

  $field: &;

  @include themify() {
    &:hover {
      #{$field}__label {
        color: t('color-white');
      }
    }
  }

  &_off {
    .react-switch-bg {
      @include themify() {
        box-shadow: 0 0 0 1px t('color-grey');
      }
    }
  }

  &__label {
    font-size: 14px;
    transition: color 0.3s ease-in-out;
    margin-right: 15px;

    @include themify() {
      color: t('color-grey');
    }
  }
}
