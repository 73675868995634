@import 'src/assets/variables';
@import 'src/assets/mixins';

.mkit-template-block {
  display: flex;
  flex-direction: column;
  min-height: 100%;
  overflow: auto;
  $block: &;

  &__header {
    font-size: 18px;
    line-height: 25px;
    margin-bottom: 10px;

    @include themify() {
      color: t('color-white');
    }
  }

  &__tools {
    display: flex;
    flex-direction: column;
    margin-bottom: 12px;
  }

  &__content-wrapper {
    display: flex;
    flex-direction: column;
    width: 419px;
    height: 650px;
    overflow-y: hidden;
    background: url("../../../assets/icons/phone-bg.svg") no-repeat 100%;
    margin: auto;
    padding: 17px 18px 0;

    &__header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 49px;
      width: 100%;

      .basic-button {
        color: $primary-color_light;
      }

      &__trap {
        display: flex;
        align-items: center;
        width: 188px;
        font-size: 14px;
        line-height: 19px;
        letter-spacing: 0.15px;
        cursor: pointer;

        color: $primary-color_light;

        &__icon {
          margin-right: 10px;
        }

        &::after {
          content: '';
          height: 7px;
          width: 12px;
          background: url("../../../assets/icons/arrow_down.png") no-repeat 100%;
          margin: auto 20px auto auto;
        }
      }
    }
  }

  &__label {
    font-size: 14px;
    line-height: 19px;
    letter-spacing: 0.15px;
    margin-right: 22px;
    white-space: pre-wrap;

    @include themify() {
      color: t('color-grey');
    }
  }

  &__buttons {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;

    &__group {
      display: flex;
    }
  }

  &_viber #{$block}__content-wrapper__header {
    border-radius: 20px 20px 0 0;

    @include themify() {
      background-color: t('color-dark-blue');
    }
  }
}
