@import 'src/assets/mixins';

.register-template-form {
  display: flex;
  flex-direction: column;
  flex: 1;

  &__header {
    font-size: 18px;
    line-height: 25px;
    margin-bottom: 20px;
  }

  &__actions-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: auto;
  }

  &__hint {
    font-size: 12px;
    margin-bottom: 16px;

    @include themify() {
      color: t('color-grey');
    }
  }

  &__row {
    display: flex;
    align-items: center;
    justify-content: space-between;

    &__header {
      display: flex;
      font-size: 16px;
      font-family: $primary-font-bold;
    }
  }
}
