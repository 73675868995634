@import 'src/assets/variables';
@import 'src/assets/mixins';

.mkit-modal {
  animation: show 0.5s;
  position: fixed;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10005;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: auto;

  &__header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  @include themify() {
    background-color: rgba(#000, 0.5);
  }

  &__dialog {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-width: 50vw;
    max-width: 80vw;
    min-height: 50vh;
    max-height: 75vh;
    border-radius: 4px;
    padding: 24px;
    position: relative;
    cursor: default;

    @include themify() {
      background-color: t('secondary-color');
    }

    &__warning {
      display: flex;
      align-items: center;
      font-size: 18px;
      font-family: $primary-font-regular;
      height: 20px;

      &__icon {
        margin-right: 10px;
      }
    }
  }
}
