@import 'src/assets/variables';
@import 'src/assets/mixins';

.reset-password-form {
  display: flex;
  width: 460px;
  flex-direction: column;
  margin-bottom: 10px;

  &__row {
    display: flex;
    align-items: center;
    justify-content: space-between;

    &__captcha-wrapper {
      display: flex;
      flex: 1;
      align-items: center;
      margin-right: 6px;
      height: 44px;
      justify-content: space-between;

      @include themify() {
        background-color: rgba(255, 255, 255, 0.8);
      }

      &__captcha {
        margin: 0 auto;
      }
    }
  }
}
