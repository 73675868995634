@import 'src/assets/variables';
@import 'src/assets/mixins';

.email-block {
  @include themify() {
    display: flex;
    flex-direction: column;
    min-height: 100%;
    overflow: auto;

    &__header {
      font-size: 18px;
      line-height: 25px;
      color: t('color-white');
      margin-bottom: 10px;
    }

    &__tools,
    &__time-tools {
      display: flex;
      align-items: center;
      margin-bottom: 12px;
    }

    &__time-tools {
      margin-bottom: 24px;
    }

    &__label {
      font-size: 14px;
      line-height: 19px;
      letter-spacing: 0.15px;
      margin-right: 22px;
      color: t('color-grey');
      min-width: 140px;
    }

    &__buttons {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }

  > div[class*=Content_constructor] {
    padding: 24px 1px;
    flex: 1;
  }
  div[class*=Preview_preview] {
    max-height: calc(100vh - 500px);
    overflow-y: auto;
  }
}
  