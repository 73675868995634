@import 'src/assets/mixins';

.ant-dropdown {
  border-radius: 4px;
  z-index: 50;

  @include themify() {
    background: t('primary-color');
  }

  &-menu {
    @include themify {
      background-color: unset;
    }

    &-item {
      padding: 10px 20px;
      background: inherit;
      font-family: notosans-regular;
      font-size: 14px;
      line-height: 19px;
      letter-spacing: .15px;
      transition: color .3s ease-in-out;

      @include themify() {
        color: t('color-grey');
      }

      &:hover {
        @include themify() {
          background-color: t('color-dark-grey-opacity');
          color: t('color-white');
        }

        img {
          opacity: 1;
        }
      }

      img {
        opacity: .6;
        margin-right: 10px;
        transition: opacity .3s ease-in-out;
      }
    }
  }
}
