@import 'src/assets/variables';
@import 'src/assets/mixins';

.tabs {
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow-y: hidden;
  position: relative;

  &__list {
    display: flex;
    align-items: center;
    overflow-x: auto;

    @include themify() {
      border-bottom: t('border-style');
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    flex: 1;
    overflow-y: auto;
  }

  &__arrow-left,
  &__arrow-right {
    width: 12px;
    height: 12px;
    cursor: pointer;
    opacity: 0.8;
    transition: opacity 0.3s ease-in-out;

    &:hover {
      opacity: 1;
    }
  }

  &__arrow-left {
    transform: rotate(180deg);
    margin-right: 5px;
  }

  &__arrow-right {
    margin-left: 5px;
  }
}

.scroll-menu-arrow--disabled {
  display: none;
}
