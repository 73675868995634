@import 'src/assets/mixins';

.inline-calendar {

  &__days {
    width: 100%;
    border: none;

    @include themify() {
      background-color: t('secondary-color');
    }

    .react-calendar__month-view__days__day {
      border-radius: 2px;
      height: 24px;
      line-height: 24px;
      margin-right: 2px;
      margin-bottom: 2px;
      padding: 0;
      max-width: calc(14.2857% - 2px) !important;
      position: relative;

      @include themify() {
        color: t('color-grey');
        background: t('inline-calendar-color');
      }

      &:after {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        border: 2px solid;
        border-radius: 2px;
        pointer-events: none;
        opacity: 0;
        transition: opacity .3s ease-out;

        @include themify() {
          border-color: t('color-white');
        }
      }
    }

    .react-calendar__month-view__days__day--neighboringMonth {
      opacity: 0;
      pointer-events: none;
    }

    .react-calendar__tile:enabled:hover, .react-calendar__tile:enabled:focus {
      @include themify() {
        background: t('inline-calendar-color');
        color: t('color-white');
      }

      &:after {
        opacity: 1;
      }
    }

    .react-calendar__tile--active {
      font-weight: bold;

      @include themify() {
        background: t('accent-color') !important;
        color: t('color-white') !important;
      }

      &:after {
        opacity: 0 !important;
      }
    }

    .react-calendar__month-view__weekdays__weekday {
      text-transform: capitalize;
      text-decoration: none;
      font-size: 14px;

      @include themify() {
        color: t('color-grey');
      }

      abbr {
        text-decoration: none;
        font-weight: normal;
      }
    }


  }

  &__select {

    height: 36px;

    .ant-select {
      width: 100%;
    }

    .ant-select-selection-selected-value {
      float: none;
    }

    .ant-select-selection {
      text-align: center;
      border: none;

      @include themify() {
        color: t('color-grey');
        background-color: t('secondary-color');
      }
    }
  }
}

.ant-select-dropdown {
  @include themify() {
    background-color: t('primary-color');
    color: t('color-grey');
  }
}

.ant-select-dropdown-menu-item {
  @include themify() {
    color: t('color-grey');

    &:hover {
      background-color: t('color-dark-grey-opacity');
    }
  }
}

.ant-select-dropdown-menu-item-selected,
.ant-select-dropdown-menu-item-active {
  @include themify() {
    background-color: t('color-dark-grey-opacity') !important;
  }
}
