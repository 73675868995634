@import 'src/assets/variables';
@import 'src/assets/mixins';

.rcs-reply-dropdown {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  box-sizing: border-box;
  padding: 12px 14px;
  font-family: $roboto-font-regular;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.15px;
  border-radius: 0 0 8px 8px;
  cursor: pointer;
  transition: color 0.3s ease-in-out;
  $dropdown: &;

  @include themify() {
    color: t('color-deep-grey');
    border: 2px dashed t('color-light-grey');
    border-top: none;

    &:hover {
      color: t('color-black');
    }
  }

  &_disabled {
    cursor: not-allowed;
    opacity: 0.8;

    @include themify() {
      &:hover {
        color: t('color-deep-grey');
      }
    }
  }

  &_open {
    @include themify() {
      color: t('color-black');
    }

    #{$dropdown}__block {
      display: block;
    }
  }

  &_left {
    @include themify() {
      border: 2px dotted t('color-light-grey');
      border-top: none;
      border-left: 2px solid t('color-light-grey');
      border-radius: 0 0 8px 0;
    }
  }

  &_right {
    @include themify() {
      border: 2px dotted t('color-light-grey');
      border-top: none;
      border-right: 2px solid t('color-light-grey');
      border-radius: 0 0 0 8px;
    }
  }

  &_direction-top #{$dropdown}__block {
    bottom: 46px;
    top: unset;
  }

  &_outside {
    border-radius: 24px;

    @include themify() {
      border-top: 2px dashed t('color-light-grey');
    }
  }

  &__block {
    display: none;
    position: absolute;
    width: 100px;
    right: 0;
    top: 46px;
    overflow-y: auto;
    z-index: 100;
    border-radius: 4px;
    background-color: white;

    @include themify() {
      border: 1px solid t('color-light-grey');
    }

    &__item {
      display: flex;
      align-items: center;
      font-size: 14px;
      letter-spacing: 0.15px;
      height: 35px;
      width: 100%;
      padding-left: 9px;
      cursor: pointer;
      transition: color 0.3s ease-in-out;

      @include themify() {
        color: t('color-deep-grey');

        &:not(:last-of-type) {
          border-bottom: 1px solid t('color-light-grey');
        }

        &:hover {
          color: t('color-black');
        }
      }
    }
  }
}
