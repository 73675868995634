@import 'src/assets/variables';
@import 'src/assets/mixins';

.menu-item {
  display: flex;
  align-items: center;
  white-space: nowrap;
  padding: 11px 24px;
  cursor: pointer;
  text-decoration: none;
  position: relative;
  $menu-item: &;

  &_disabled {
    cursor: not-allowed;

    > span[class*="label"] {
      color: var(--grey40);
    }
    > svg {
      fill: var(--grey30) !important;
    }

    &:hover {
      cursor: not-allowed;
    }
  }

  &:hover #{$menu-item}__overlay {
    display: flex;
  }
  &:not(&_disabled):not(&_selected):hover {
    background-color: var(--grey10);
    text-decoration: none !important;

    svg {
      fill: var(--grey70);
    }
  }

  &_selected {
    background-color: var(--blue5);

    > svg {
      fill: var(--blue60);
    }
  }

  &__icon {
    width: 24px;
    height: 24px;
    fill: var(--grey50);
    transition: fill 0.2s;
  }

  &__label {
    margin-left: 24px;
    font-size: 14px;
    line-height: 19px;
    color: var(--grey100);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal;
  }

  &__overlay {
    position: absolute;
    left: 100%;
    top: 0;
    display: none;
    flex-direction: column;
    z-index: 5;
    background-color: var(--grey5);

    .menu-item__label {
      white-space: nowrap;
    }

    @include themify() {
      box-shadow: t('box-shadow');
    }
  }
}
