@import 'src/assets/variables';
@import 'src/assets/mixins';

.link-segment {
  fill: none;
  pointer-events: all;
  @include themify() {
    stroke: t('fff');
  }

  @keyframes animation-f6hyxo {
    0% {
      stroke-dashoffset: 24;
    }

    100% {
      stroke-dashoffset: 0;
    }
  }

  &_selected {
    stroke-dasharray: 10, 2;
    animation: animation-f6hyxo 1s linear infinite;
  }
  //stroke-dasharray: 10, 2;
  //animation: ${Keyframes} 1s linear infinite;
}

.arrow {
  polygon {
    @include themify() {
      fill: t('fff');
    }
  }
}
