@import 'src/assets/mixins';

.card {
  padding: 24px;

  @include themify() {
    background: t('secondary-color');
    box-shadow: t('box-shadow');
  }

  &__title{
    margin-bottom: 25px;
    font-size: 18px;
  }
}
