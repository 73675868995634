@import 'src/assets/variables';
@import 'src/assets/mixins';

.mkit-content-selector {
  display: flex;
  flex-direction: column;
  height: 100%;

  &__title {
    font-size: 18px;
    line-height: 25px;
    margin-bottom: 20px;

    @include themify() {
      color: t('color-white');
    }
  }

  &__upload-button {
    width: 146px;
    height: 146px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1;
    border: 1px solid var(--grey20);
    border-radius: 4px;
    cursor: pointer;
    transition: color 0.2s, border-color 0.2s;
    color: var(--grey50);

    &:hover {
      color: var(--grey100);
      border-color: var(--grey40);
    }

    input {
      display: none;
    }
  }

  &__buttons-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: auto;
  }
}
