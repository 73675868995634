.campaign-create-fallback {
  display: flex;
  flex-direction: column;
  height: 230px;
  position: relative;
  margin-bottom: 60px;
  overflow-y: visible;

  &__header {
    display: flex;
    align-items: center;

    button {
      margin: 0 8px;
    }
  }

  &__script {
    display: flex;
    flex-direction: column;
    max-width: 410px;
  }

  &__channels {
    display: flex;
    margin: 15px auto;
    position: relative;

    .campaign-create-channel {
      margin-right: 110px;

      &:last-child {
        margin-right: 0;

        .campaign-create-channel__arrow {
          display: none;
        }
      }
    }
  }
}
