@import 'src/assets/variables';
@import 'src/assets/mixins';

.security-field {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  position: relative;
  font-family: $primary-font-regular;
  $security-field: &;

  &__label {
    font-size: 14px;
    line-height: 19px;
    letter-spacing: 0.15px;
    transition: color 0.3s ease-in-out;

    @include themify() {
      color: t('color-grey');
    }
  }

  @include themify() {
    &:focus-within #{$security-field} {
      &__label {
        color: t('color-white');
      }
    }
  }

  &__input-wrapper {
    display: flex;
    min-width: 200px;
    flex: 1;
    position: relative;

    &__input {
      width: 100%;
      font-size: 14px;
      padding: 7px;
      letter-spacing: 0.15px;
      transition:
      border-color 0.3s ease-in-out,
      color 0.3s ease-in-out;
      $input: &;

      @include themify() {
        color: t('color-grey');

        &:focus {
          border-color: t('color-white');
          color: t('color-white');
        }

        &::placeholder {
          color: t('color-grey');
        }

        &:-webkit-autofill,
        &:-webkit-autofill:focus {
          -webkit-text-fill-color: t('dark-blue-color');
        }
      }

      &::placeholder {
        font-family: $primary-font-italic;
        font-size: 14px;
        line-height: 19px;
        letter-spacing: 0.15px;
      }

      &:-webkit-autofill,
      &:-webkit-autofill:focus {
        -webkit-box-shadow: none;
        transition: background-color 5000s ease-in-out 0s, color 5000s ease-in-out 0s;
      }
    }

    &__error {
      position: absolute;
      left: 0;
      top: 8px;
    }
  }

  &__reset-button {
    margin-left: 13px;
    margin-right: 5px;
    width: 24px;
    opacity: 0.6;
    transition: opacity 0.3s ease-in-out;
    cursor: pointer;

    &:hover {
      opacity: 0.8;
    }
  }

  &__show-password {
    position: absolute;
    width: 40px;
    right: 3px;
    top: 2px;
    bottom: 2px;
    cursor: pointer;
    opacity: 0.6;
    transition: opacity 0.3s ease-in-out;

    &:hover {
      opacity: 1;
    }
  }

  &_disabled #{$security-field} {
    &__label {
      // color: rgba(white, 0.2);
      opacity: 0.4;
    }

    &__input-wrapper__input {
      //color: rgba(white, 0.2);
      //border-bottom: rgba(white, 0.2) solid 1px;
      opacity: 0.4;
    }
  }

  &_white {
    box-sizing: border-box;
    border-radius: 4px;

    @include themify() {
      background: white;
      border: 1px solid t('color-border');
    }

    input {
      padding: 11px 19px;
      line-height: 21px;
      font-size: 18px;
      border-bottom: none;

      @include themify() {
        color: t('dark-blue-color');

        &:focus {
          color: t('dark-blue-color');
        }

        &::placeholder {
          color: rgba(t('dark-blue-color'), 0.8);
        }
      }

      &::placeholder {
        font-family: $primary-font-regular;
        font-size: 18px;
      }
    }
  }

  &_error {
    @include themify() {
      border-color: t('color-attention');

      #{$security-field} {
        &__label {
          color: t('color-attention');
        }

        &__input-wrapper__input {
          border-color: t('color-attention');
          color: t('color-attention');

          &::placeholder {
            color: t('color-attention');
          }
        }
      }
    }
  }
}
