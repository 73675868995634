@import 'src/assets/variables';
@import 'src/assets/mixins';

.edit-scenario-form {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  font-family: $primary-font-regular;

  @include themify() {
    color: t('color-grey');
  }

  &__header,
  &__label {
    display: flex;
    flex-direction: column;
    letter-spacing: 0.15px;
    font-size: 18px;
    line-height: 25px;

    @include themify() {
      color: t('color-white');
    }
  }

  &__label {
    font-size: 16px;
    margin: 20px 0;
  }

  &__content {
    display: flex;
    flex: 1;
    flex-direction: column;
    overflow-y: auto;
  }

  &__buttons {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    margin-top: 30px;

    &__group {
      display: flex;
    }
  }

  .form-field {
    display: flex;
    justify-content: space-between;

    & > span {
      width: 73%;
    }
  }
}
