@import 'src/assets/mixins';

.campaign-create-channel {
  text-align: center;
  position: relative;

  &__img {
    height: 47px;
    width: 47px;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;

    @include themify() {
      filter: t('img-invert');
    }
  }

  &__content {
    position: absolute;
    bottom: -10px;
    left: 50%;
    transform: translate(-50%, 100%);

    @include themify() {
      color: t('color-white');
    }

    &-wrapper {
      position: relative;
    }

    & > div {
      white-space: nowrap;
    }
  }

  &__arrow {
    &::before {
      right: -35px;
      top: 50%;
      transform: translate(100%, -50%);
      content: "";
      position: absolute;
      width: 50px;
      height: 2px;
    }

    @include themify() {
      &::before {
        background-color: t('accent-color');
      }
    }

    &::after {
      content: "";
      position: absolute;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 6px 0 6px 10px;
      right: -85px;
      top: 50%;
      transform: translate(100%, -50%);
    }

    @include themify() {
      &::after {
        border-color: transparent transparent transparent t('accent-color');
      }
    }
  }

  &__remove {
    position: absolute;
    right: -1px;
    top: -1px;
    transform: translate(100%, -100%);
    cursor: pointer;

    @include themify() {
      filter: t('img-invert');
    }
  }

  &__wrapper {
    position: relative;
  }
}
