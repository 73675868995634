@import 'src/assets/variables';
@import 'src/assets/mixins';

.search-field {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  font-family: $primary-font-regular;
  $search-field: &;

  &__label {
    font-size: 14px;
    line-height: 19px;
    letter-spacing: 0.15px;
    transition: color 0.3s ease-in-out;

    @include themify() {
      color: t('color-grey');
    }
  }

  @include themify() {
    &:focus-within #{$search-field} {
      &__label {
        color: t('color-white');
      }

      &__input-wrapper {
        border-color: t('color-white');
      }
    }

  }

  &__input-wrapper {
    display: flex;
    min-width: 200px;
    flex: 1;
    position: relative;
    transition: border-color 0.3s ease-in-out;
    padding: 8px 11px 8px 9px;

    @include themify() {
      border-bottom: t('border-style');

      &__icon {
        filter: t('img-invert');
      }
    }

    &__input {
      width: 100%;
      font-size: 14px;
      letter-spacing: 0.15px;
      line-height: 19px;
      transition:
        border-color 0.3s ease-in-out,
        color 0.3s ease-in-out;
      $input: &;

      @include themify() {
        color: t('color-grey');

        &:focus {
          color: ('color-white');
        }

        &::placeholder {
          color: t('color-grey');
        }
      }

      &::placeholder {
        font-family: $primary-font-italic;
        font-size: 14px;
        line-height: 19px;
        letter-spacing: 0.15px;
      }

      &:-webkit-autofill,
      &:-webkit-autofill:focus {
        -webkit-box-shadow: none;
        transition: background-color 5000s ease-in-out 0s, color 5000s ease-in-out 0s;
      }
    }
  }

  &_full-border {
    #{$search-field}__input-wrapper {
      @include themify() {
        border: t('border-style');
      }

      border-radius: 4px;
    }
  }

  &_disabled #{$search-field} {
    &__label {
      // color: rgba(white, 0.2);
      opacity: 0.4;
    }

    &__input-wrapper {
      //color: rgba(white, 0.2);
      //border-bottom: rgba(white, 0.2) solid 1px;
      opacity: 0.4;
    }
  }
}
