@import 'src/assets/variables';

.mkit-config-button-form {
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;

  &__title {
    margin-top: 24px;
    font-weight: 500;
  }

  &__buttons {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: auto;
  }
}
