@import 'src/assets/mixins';

.finances-header {
  display: flex;
  align-items: center;
  //justify-content: space-between;
  width: 100%;
  box-sizing: border-box;
  padding: 15px 30px;
  margin-top: 10px;

  @include themify() {
    background: t('secondary-color');
    box-shadow: t('box-shadow');
  }

  &__item {
    display: flex;
    align-items: center;
    flex: 1;

    @include themify() {
      &:not(:last-of-type) {
        padding-right: 25px;

        &::after {
          content: '';
          background: t('color-grey');
          opacity: 0.2;
          width: 1px;
          height: 24px;
          margin-left: auto;
        }
      }
    }

    &__icon {
      margin-right: 18px;

      @include generator-img-filters();
    }

    &__info {
      display: flex;
      flex-direction: column;
      letter-spacing: 0.15px;
      font-family: notosans-regular;

      &__label {
        font-size: 14px;
        line-height: 19px;

        @include themify() {
          color: t('color-grey');
        }
      }

      &__value {
        font-size: 16px;
        line-height: 22px;

        @include themify() {
          color: t('color-white');
        }
      }
    }
  }
}
