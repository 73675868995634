@import 'src/assets/variables';

.sender-groups-screen {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 22px;
    line-height: 30px;
    margin-bottom: 15px;
  }
}
