@import 'src/assets/variables';
@import 'src/assets/mixins';

.tags-field-array {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex: 1;

  &__label {
    margin-right: 12px;
    @include themify() {
      color: t('color-grey');
    }
  }

  &__content {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
    //margin: -4px;
    flex: 1;

    //& > * {
    //  margin: 4px;
    //}

    &__add {
      display: flex;
      align-items: center;
      height: 24px;
      border-radius: 8px;
      padding: 0 5px;
      white-space: nowrap;
      //font-size: 12px;
      //line-height: 16px;
      //letter-spacing: 0.15px;
      color: $color-white;

      @include themify() {
        background: t('accent-color');
      }
    }
  }
}
