@import 'src/assets/mixins';

.switch-control {

  &.ant-switch{
    border-radius: 12px;

    @include themify() {
      border-color: t('color-grey');
      background-color: t('secondary-color');
    }

    &:after{
      top: 50%;
      transform: translateY(-50%);
    }

    @include themify() {
      &:after{
        background-color: t('color-grey');
      }
    }
  }

  &.ant-switch-checked{
    @include themify() {
      border-color: t('accent-color');
      background-color: t('accent-color');
    }

    &:after{
      transform: translate(-100%, -50%);
    }

    @include themify() {
      &:after{
        background-color: t('color-white');
      }
    }
  }
}
