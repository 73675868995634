.image-array-cell-renderer {
  max-width: 0;
  display: flex;
  align-items: center;

  &__image {
    width: 24px;
    height: 24px;

    &:not(:last-child) {
      margin-right: 10px;
    }
  }
}
