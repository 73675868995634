@import 'src/assets/variables';
@import 'src/assets/mixins';

.edr-body {
  cursor: pointer;
  margin-right: 8px;

  @include themify() {
    color: t('accent-color');
  }
}
