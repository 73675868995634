@import 'src/assets/variables';
@import 'src/assets/mixins';

.multi-select-field {
  display: flex;
  width: 100%;
  align-items: center;
  box-sizing: border-box;
  position: relative;

  $field: &;

  &__button-wrapper {
    display: flex;
  }

  &__label {
    font-size: 14px;
    opacity: 0.6;
    font-family: $primary-font-regular;
  }

  &__button {
    display: flex;
    flex: 1;
    align-items: center;
    min-width: 10px;
    border-radius: 5px;
    min-height: 37px;
    flex-wrap: wrap;
    box-sizing: border-box;
    position: relative;
    z-index: 1;
    cursor: pointer;
    padding: 4px 20px 4px 9px;

    // todo убрать, когда выпилим либу antd с её вездесущими стилями
    background: transparent;

    @include themify() {
      border: t('border-style');
    }

    &__item {
      display: flex;
      border-radius: 4px;
      padding: 1px 5px;
      margin: 2px 0;
      cursor: pointer;
      position: relative;

      @include themify() {
        color: t('color-white');
        background-color: t('accent-color');
      }

      &::after {
        content: '';
        width: 10px;
        height: 10px;
        background: url("../../../assets/icons/cross.svg") center no-repeat;
        background-size: 100%;
        margin: auto 0 auto 5px;
        opacity: 0.6;
        transition: opacity 0.3s ease-in-out;
      }

      &:hover {
        &::after {
          opacity: 1;
        }
      }

      &:not(:last-of-type) {
        margin-right: 8px;
      }
    }

    &::after {
      content: '';
      position: absolute;
      right: 14px;
      height: 12px;
      width: 12px;
      background: url("../../../assets/icons/right.svg") center no-repeat;
      transform: rotate(90deg);
      background-size: 7px 12px;
      cursor: pointer;
      opacity: 0.6;
      transition:
        transform 0.3s ease-in-out,
        opacity 0.3s ease-in-out;
      margin-bottom: -1px;
    }

    &:hover {
      &::after {
        opacity: 1;
      }

      input {
        &::placeholder {
          opacity: 1;
        }
      }
    }
  }

  &__overlay {
    width: calc(75% - 24px);
    top: calc(100% + 10px);
    left: calc(25% + 24px);
    box-sizing: border-box;
    max-height: 0;
    display: flex;
    flex-direction: column;
    position: absolute;
    z-index: 20;
    visibility: hidden;
    opacity: 0;
    transition:
      visibility 0.3s ease-in-out,
      max-height 0.3s ease-in-out,
      opacity 0.3s ease-in-out,
      top 0.3s ease-in-out;
    overflow: auto;

    @include themify() {
      background-color: t('primary-color');
    }

    &__item {
      display: flex;
      align-items: center;
      flex-direction: row;
      height: 36px;
      min-height: 36px;
      font-size: 14px;
      padding: 0 9px;
      cursor: pointer;
      box-sizing: border-box;
      font-family: $primary-font-regular;
      transition: background-color 0.3s ease-in;
      // todo убрать, когда выпилим либу antd с её вездесущими стилями
      border: none;
      background-color: unset;

      @include themify() {
        color: t('color-grey');
      }

      &__text {
        text-align: left;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }

      &:hover {
        @include themify() {
          color: rgba(t('color-white'), 0.8);
          background-color: t('color-dark-grey-opacity');
        }
      }
    }
  }

  &__error-text {
    transition: opacity 0.1s ease-in-out;
    position: absolute;
    bottom: -20px;
  }

  &_disabled#{$field} &__button {
    opacity: 0.5;
    cursor: not-allowed;

    &::after {
      opacity: 0.3;
    }
  }

  &_open #{$field} {
    &__overlay {
      top: calc(100% + 10px);
      visibility: visible;
      max-height: 250px;
      opacity: 1;
      z-index: 999;
    }

    &__button::after {
      transform: rotate(270deg);
    }
  }
}
