@import 'src/assets/mixins';

.media-cell-renderer {
  &_pointer {
    cursor: pointer;
  }

  &_invert {
    @include themify() {
      filter: t('img-invert');
    }
  }
}
