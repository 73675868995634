@import 'src/assets/mixins';

.input-field{
  position: relative;

  &::placeholder {
    opacity: .6;
    font-family: notosans-italic;
    font-size: 14px;
    line-height: 19px;
    letter-spacing: 0.15px;

    @include themify() {
      color: t('color-white');
    }
  }

  &__wrapper{
    position: relative;
  }

  &__error{
    position: absolute;
    top: 100%;
    transform: translateY(5px);
    color: red;
    left: 0;
  }
}
