@import 'src/assets/mixins';

.campaign-create-controlled-outside {
  @include themify() {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    width: 100%;
    &__img {
      width: 60%;
        margin-bottom: 20px;
    }

      &__text{
          text-align: center;
          color: t('color-white');
      }
  }
}

.campaign-create-card{}
