.value-wrapper {
  display: flex;
  align-items: center;
  // justify-content: center;
  font-size: 12px;
  min-height: 35px;
  position: relative;

  &__text {
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &_overflow {
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100px;
  }

  &_tags {
    display: flex;
    width: auto;
  }

  &__icon {
    margin-left: 9px;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  &__image {
    width: 40px;
    height: 40px;
    margin: 5px 0;
  }
}
