@import 'src/assets/mixins';

.form-control {
  position: relative;

  &__error{
    position: absolute;
    left: -10px;
    top: 50%;
    transform: translate(-100%, -50%);
    background: url("../../../assets/icons/error.svg") center no-repeat;
    background-size: contain;
    width: 20px;
    height: 20px;
    cursor: pointer;

    &:hover {
      .form-control__error-message{
        opacity: 1;
      }
    }
  }

  &__error-message{
    position: absolute;
    left: 50%;
    top: -5px;
    transform: translate(-50%, -100%);
    font-size: 12px;
    line-height: 1.6;
    text-align: center;
    white-space: nowrap;
    padding: 5px;
    border-radius: 4px;
    z-index: 200;
    pointer-events: none;
    opacity: 0;
    transition: opacity .3s ease-out .1s;

    @include themify() {
      background-color: t('color-attention-2');
      color: t('color-white');
      box-shadow: t('box-shadow');
    }
  }
}
