@import 'src/assets/mixins';

.mkit-drop-file {
  @include themify() {
    display: none;
    position: absolute;
    z-index: 2;

    &_active {
      display: flex;
      left: -15px;
      top: -10px;
      bottom: -15px;
      right: 345px;
      border: 2px dashed t('color-white');
      border-radius: 10px;
    }

    &_full-screen {
      right: -15px;
    }

    &__icon {
      display: flex;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%,-50%);
    }
  }
}
