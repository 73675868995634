@import 'src/assets/variables';
@import 'src/assets/extend';
@import 'src/assets/mixins';

.mkit-carousel-rich-card {
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 12px;
  position: relative;
  overflow-x: hidden;

  &__tools {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 8px;
    transition: opacity 0.2s ease-in-out;

    &__card-width {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 43px;
    }
  }

  &__arrow-button {
    opacity: 0.4;
    cursor: pointer;
    font-size: 24px;
    position: absolute;
    top: 185px;
    right: 13px;
    transition: opacity 0.3s ease-in-out;

    @include themify() {
      color: t('color-deep-grey');
    }

    &:hover {
      opacity: 1;
    }

    &:disabled {
      cursor: not-allowed;

      &:hover {
        opacity: 0.4;
      }
    }

    &_left {
      transform: rotate(180deg);
      right: unset;
      left: 13px;
    }
  }
}
