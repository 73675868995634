@import 'src/assets/variables';

.mkit-editor-textarea {
  position: relative;
  font-family: $roboto-font-regular;
  font-size: 14px;
  letter-spacing: 0.15px;
  $textarea: &;

  &:focus-within {
    &::before {
      content: '';
      display: none;
    }

    #{$textarea}__tools {
      display: flex;
    }
  }

  &_open #{$textarea}__tools {
    display: flex;

    &__variable {
      color: white;
      background-color: $accent-color;

      &__block {
        display: flex;
      }
    }
  }

  &_left,
  &_right {
    #{$textarea}__tools__variable__block {
      top: -2px;
    }
  }

  &_bold {
    font-size: 16px;
    font-family: $roboto-font-bold;
  }

  &_italic {
    font-family: $roboto-font-italic;
  }

  &_underline {
    text-decoration: underline;
  }

  &_whats-app-text,
  &_viber-text {
    min-height: 80px;

    #{$textarea}__tools {
      &__variable {
        border-right: none;
        margin-right: 0;

        &__block {
          top: -2px;
        }
      }
    }
  }

  &_border {
    border-radius: 8px;
    padding: 12px 14px;
    min-height: 90px;
    border: 2px solid $color-light-grey;

    #{$textarea}__tools {
      top: -25px;

      &__variable {
        border-right: none;
        margin-right: 0;

        &__block {
          top: -2px;
        }
      }
    }
  }

  &__tag,
  &__variable {
    display: inline-block;
    font-family: $roboto-font-bold;
    color: $accent-color;
  }

  &__variable {
    &::before {
      content: '{{';
    }

    &::after {
      content: '}}';
    }
  }

  &__input {
    white-space: pre-wrap;
    cursor: text;
    overflow-wrap: anywhere;
    max-height: 275px;
    min-height: 42px;
    overflow-y: auto;
    outline: none;
    $input: &;

    &:empty::before {
      content: attr(data-placeholder);
      color: $color-deep-grey;
    }
  }

  &__tools {
    display: none;
    align-items: center;
    position: absolute;
    top: -40px;
    border-radius: 5px;
    z-index: 20;
    background-color: $color-white;
    border: 2px solid $accent-color;

    &__variable {
      display: flex;
      align-items: center;
      height: 28px;
      font-size: 14px;
      letter-spacing: 0.15px;
      font-weight: bold;
      font-family: $roboto-font-italic;
      padding: 0 5px;
      margin-right: 2px;
      cursor: pointer;
      position: relative;
      color: $color-deep-grey;
      border-right: 1px solid $color-deep-grey;
      $variable: &;

      &__block {
        display: none;
        flex-direction: column;
        position: absolute;
        left: 27px;
        min-width: 160px;
        max-width: 250px;
        max-height: 250px;
        top: -40px;
        overflow-y: auto;
        width: auto;
        background-color: white;
        z-index: 100;
        cursor: initial;
        border-radius: 5px;
        //padding: 14px 0;
        box-sizing: border-box;
        color: $primary-color;
        border: 2px solid $accent-color;

        ::-webkit-scrollbar,
        &::-webkit-scrollbar {
          width: 5px !important;
          height: 5px !important;
        }

        &__item {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          font-size: 12px;
          line-height: 16px;
          letter-spacing: 0.15px;
          font-family: $roboto-font-regular;
          padding: 7px 12px;
          cursor: pointer;
          text-align: left;
          transition: color 0.1s ease-in-out, background-color 0.1s ease-in-out;

          &:hover {
            color: $color-white;
            background-color: $accent-color;
          }
        }
      }
    }

    &__button {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 28px;
      height: 28px;
      border-radius: 2px;
      transition: background-color 0.3s ease-in-out;

      &_selected {
        background-color: $accent-color;
      }
    }
  }

  &__disabler {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    z-index: 20;
  }
}
