@import 'src/assets/variables';
@import 'src/assets/mixins';

.dropdown-field-array {
  display: flex;
  flex-direction: column;
  $field: &;

  &__field {
    display: flex;
    align-items: center;

    &:not(:first-child) {
      margin-top: 12px;
    }

    & > *:first-child {
      flex: 3;
    }

    &__right {
      margin-left: 12px;
      display: flex;
      flex: 7;
    }
  }

  &_equivalent #{$field}__field {
    justify-content: space-between;

    @include themify() {
      color: t('color-grey');
    }

    & > *:first-child {
      flex: 1;
      margin-right: 12px;
    }

    &__right {
      flex: 1;
    }
  }
}
