@import 'src/assets/variables';
@import 'src/assets/mixins';

.mkit-dnd-slides {
  @include themify() {
    display: flex;
    align-items: center;
    padding: 0 20px;
    margin-top: 12px;
    overflow-x: auto;
    min-height: 46px;
    //justify-content: center;

    &__item {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 36px;
      width: 36px;
      min-width: 36px;
      border: 2px solid t('color-light-grey');
      border-radius: 5px;
      margin: 0 8px;
      cursor: pointer;

      &_selected {
        border-color: t('accent-color');
      }

      &__image {
        width: 100%;
        height: 100%;
        border-radius: 3px;

        &_placeholder {
          width: 16px;
          height: 20px;
          border-radius: unset;
        }
      }
    }
  }
}
