@import 'src/assets/mixins';

.campaign-create {
  &__title {
    font-size: 22px;
    line-height: 30px;
    margin: 0 0 15px 0;
  }

  &__card{
    height: 100%;
    position: relative;

    &--controlled-outside{
      .card__title{
        @include themify() {
          color: t('color-grey');
        }
      }
    }
  }

  &__row{
    align-items: stretch;
  }

  &__col{
    flex: 1 0 auto;
    position: relative;
  }

  &__actions{
    position: fixed;
    bottom: -1px;
    right: 24px;
    display: flex;
    justify-content: flex-end;

    button:not(:last-child) {
      margin-right: 12px;
    }

    &-save{
      margin-right: 0;
      img{
        width: auto;
        height: 18px;
      }
    }
  }
}
