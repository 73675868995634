@import 'src/assets/variables';
@import 'src/assets/mixins';

.tag-field {
  display: flex;
  align-items: center;
  height: 24px;
  border-radius: 8px;
  padding: 0 5px;
  white-space: nowrap;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.15px;
  color: $color-white;

  &_pristine {
    opacity: 0.5;
  }

  @include themify() {
    background: t('accent-color');
  }

  &__icon {
    width: 24px;
    height: 24px;
    margin-right: 6px;
  }

  & > button + button {
    margin-left: -5px;
  }
}
