@import 'src/assets/variables';
@import 'src/assets/mixins';

.mkit-senders-dropdown {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding-right: 15px;
  font-family: $primary-font-regular;
  $dropdown: &;


  &_sms {
    .mkit-senders-dropdown__value-wrapper svg path {
      fill: var(--grey30);
    }
    .mkit-senders-dropdown__value-wrapper__title {
      color: var(--grey100);
    }
    .mkit-senders-dropdown__value-wrapper__placeholder {
      color: var(--grey30);
    }
  }
  &_rcs {
    .mkit-senders-dropdown__value-wrapper svg path {
      fill: var(--grey30);
    }
    .mkit-senders-dropdown__value-wrapper__title {
      color: var(--grey100);
    }
    .mkit-senders-dropdown__value-wrapper__placeholder {
      color: var(--grey30);
    }
  }
  &_viber, &_whats-app {
    .mkit-senders-dropdown__value-wrapper svg path {
      fill: var(--grey0);
    }
    .mkit-senders-dropdown__value-wrapper__title {
      color: var(--grey0);
    }
    .mkit-senders-dropdown__value-wrapper__placeholder {
      color: var(--grey0);
    }
  }

  &__error-icon {
    margin-right: 10px;
    height: 20px;
    width: 20px;
    min-width: 20px;
    min-height: 20px;
  }

  &__arrow-icon {
    display: flex;
    height: 8px;
    width: 14px;
    transform: scale(-1, 1);
    transition: transform 0.2s;
    margin-left: 10px;
    min-height: 8px;
    min-width: 14px;
  }

  &_disabled {
    cursor: not-allowed;

    #{$dropdown}__value-wrapper {
      cursor: not-allowed;
    }
  }

  &_open {
    #{$dropdown} {
      &__block {
        display: block;
      }
    }

    .mkit-senders-dropdown__arrow-icon {
      transform: scale(1, -1);
      transition: transform 0.2s;
    }
  }

  &_empty #{$dropdown}__value-wrapper {
    color: $color-attention;
  }

  &__value-wrapper {
    display: flex;
    align-items: center;
    width: 240px;
    font-size: 14px;
    line-height: 19px;
    letter-spacing: 0.15px;
    cursor: pointer;
    font-weight: bold;
    color: $color-white;

    &__title,
    &__placeholder {
      font-family: var(--inter);
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 143%;
      align-items: center;
      letter-spacing: 0.018em;
    }

    &__title {
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }

    &__icon {
      margin-right: 10px;
      height: 20px;
      width: 20px;
      min-width: 20px;
      min-height: 20px;
    }

    &__error {
      display: flex;
      align-items: center;

      svg path {
        fill: $color-attention !important;
      }
    }

    &__error-icon {
      margin-right: 5px;
    }
  }

  &__block {
    display: none;
    position: absolute;
    right: 13px;
    left: 0;
    top: 26px;
    overflow-y: auto;
    z-index: 9999999;
    max-height: 300px;

    &_new-design {
      background-color: var(--modal2) !important;
      box-shadow: var(--shadow2dp);
      border-radius: 8px;
      max-height: 154px;
      padding: 8px;
      gap: 2px;
    }

    @include themify() {
      background-color: t('color-dark-grey');
    }

    &__item {
      display: flex;
      align-items: center;
      color: $color-white;
      font-size: 14px;
      letter-spacing: 0.15px;
      height: 36px;
      width: 100%;
      padding: 0 9px;
      cursor: pointer;
      transition: background-color 0.3s ease-in-out;

      &_new-design {
        font-family: var(--inter);
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 143%;
        text-align: center;
        letter-spacing: 0.018em;
        color: var(--grey100);
        padding: 3.5px 8px;
        height: 28px;
        border-radius: 4px;

        &:hover {
          background-color: var(--grey5) !important;
        }
      }

      &:hover {
        @include themify() {
          background-color: t('color-dark-grey-opacity');
        }
      }
    }
  }
}
