@import 'src/assets/variables';
@import 'src/assets/mixins';

.mkit-expand-button {
  height: 36px;
  min-width: 110px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  font-family: $primary-font-regular;
  line-height: 19px;
  padding: 9px;
  transition:
    opacity 0.3s ease-in-out,
    background 0.3s ease-in-out;
  background-color: transparent;
  user-select: none;
  border: none;
  outline: none;
  opacity: 1;
  position: relative;
  $button: &;

  @include themify() {
    color: $color-white;
    background: t('accent-color');
  }

  &_open {
    @include themify() {
      color: t('color-black');
      background: rgba(t('accent-color'), 0.6);
    }

    #{$button}__block {
      display: block;
    }
  }

  &_disabled {
    opacity: 0.6;
    cursor: not-allowed;
  }

  &__block {
    display: none;
    position: absolute;
    width: 100%;
    right: 0;
    bottom: 41px;
    overflow-y: auto;
    z-index: 100;
    border-radius: 4px;
    padding: 0 8px;

    @include themify() {
      background: t('accent-color');
    }

    &__item {
      display: flex;
      align-items: center;
      font-size: 14px;
      letter-spacing: 0.15px;
      height: 35px;
      width: 100%;
      cursor: pointer;
      transition: color 0.3s ease-in-out;
      justify-content: center;

      @include themify() {
        color: t('color-white');

        &:hover {
          color: t('color-black');
        }

        &:not(:last-of-type) {
          border-bottom: 1px dotted t('color-light-grey');
        }
      }
    }
  }
}
