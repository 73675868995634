@import 'src/assets/variables';
@import 'src/assets/mixins';

.time-dropdown {
  display: flex;
  align-items: center;
  min-width: 1px;
  $time-picker-field: &;

  &__label {
    font-size: 14px;
    line-height: 19px;
    margin-right: 10px;

    @include themify() {
      color: t('color-grey');
    }
  }

  @include themify() {
    &__input__overlay {
      background-color: t('secondary-color');

      &__item {
        &:hover {
          background-color: t('primary-color');
        }
      }
    }
  }

  &__input {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex: 1;
    cursor: pointer;
    position: relative;
    font-family: $primary-font-regular;
    min-width: 1px;

    &__search-input {
      font-family: $primary-font-regular;
    }

    &__value {
      min-width: 17px;
      max-width: 100%;
      font-size: 14px;
      line-height: 19px;
      letter-spacing: 0.15px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    &__icon {
      width: 24px;
      height: 24px;
      margin-left: 3px;

      @include themify() {
        filter: t('img-invert');
      }
    }

    &__overlay {
      position: absolute;
      left: 0;
      right: 0;
      top: 24px;
      z-index: 2;
      display: none;
      flex-direction: column;
      max-height: 19 * 5px;
      overflow-y: auto;

      &__item {
        cursor: pointer;
        font-size: 14px;
        line-height: 19px;
        letter-spacing: 0.15px;
        font-family: $primary-font-regular;
        padding: 0 4px;
        text-align: left;
      }

      &__item-text {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }
  }

  &_open #{$time-picker-field} {
    &__input__overlay {
      display: flex;
    }
  }
}
