@import 'src/assets/mixins';

.select {
  width: 100%;
  .ant-select-selection {
    border-width: 0 0 1px 0 !important;
    border-radius: 0;
    box-shadow: none;
  }

  &.ant-select-disabled .ant-select-selection {
    opacity: 0.5;

    @include themify() {
      background-color: t('secondary-color');
    }
  }
}

.select-dropdown {
  .ant-select-dropdown-menu-item:hover:not(.ant-select-dropdown-menu-item-disabled) {
    @include themify() {
      background-color: t('dropdown-item-hover');
    }
  }
}
