.report-field {
    &__title {
        font-size: 16px;
    line-height: 40px;
    letter-spacing: 0.15px;
    margin: 0 0 12px 0;
    }
    &__bottom__group {
        display: flex;
        justify-content: flex-end;
        padding-right: 9px;
        padding-bottom: 12px;
        padding-top: 12px;
        position: sticky;
        bottom: 0;
    }
}
