@import 'src/assets/mixins';

.mkit-mask-filter {
  display: flex;
  flex-direction: column;
  width: 268px;
  padding: 12px;
  border-radius: 4px;

  @include themify() {
    background: t('primary-color');
  }

  &__header {
    font-family: 'notosans-italic';
    font-size: 14px;

    @include themify() {
      color: t('color-white');
    }
  }

  &__actions-wrapper {
    margin-top: 15px;
    display: flex;
    flex-flow: row;
    flex-wrap: nowrap;
    justify-content: space-between;
  }
}
