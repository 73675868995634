@import '../../../assets/mixins';
@import '../../../assets/variables';

.mkit-select-scenario {
  display: flex;
  flex-direction: column;
  overflow-y: hidden;
  flex: 1;
  height: 100%;

  &__preview {
    display: flex;
    height: 100%;
    flex-direction: column;
  }

  &__header {
    font-size: 18px;
    line-height: 25px;
    margin-bottom: 8px;
  }

  &__row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 12px;
  }

  &__list {
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    margin-bottom: 20px;

    &__item {
      display: flex;
      justify-content: space-between;
      padding: 13px 24px;
      transition: background-color 0.1s;
      cursor: pointer;

      &:hover {
        @include themify() {
          background-color: t('primary-color');
        }
      }

      &_selected {
        @include themify() {
          background-color: rgba(t('accent-color'), 0.2);
        }
      }
    }
  }

  &__buttons {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    margin-top: auto;
  }
}
