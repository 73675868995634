@import 'src/assets/variables';
@import 'src/assets/mixins';

//todo: выпелить импортанты после антд

.link {
  font-family: $primary-font-regular;
  font-size: 14px;
  line-height: 18px;
  cursor: pointer;
  opacity: 0.8;
  transition: opacity 0.3s ease-in-out;

  @include themify() {
    color: t('accent-color') !important;
  }

  &:hover {
    opacity: 1;
    text-decoration: underline;
  }

  &_white {
    @include themify() {
      color: t('color-white') !important;
    }
  }
}
