@import 'src/assets/mixins';
@import 'src/assets/variables';

.opt-outs-cell-renderer {
  max-width: 0;
  display: flex;
  align-items: center;

  &__wrapper {
    display: flex;
    margin-right: 10px;
    position: relative;

    &:not(:last-of-type) {
      margin-right: 6px;
    }

    &__senders-count {
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      right: -2px;
      padding: 0 3px;
      bottom: 0;
      //width: 12px;
      height: 12px;
      font-size: 8px;
      border-radius: 40%;

      @include themify() {
        background-color: t('color-attention-2');
        color: $white;
      }
    }
  }
}
