@import 'src/assets/mixins';

.form-item {
  .ant-form-item-label {
    & > label {
      @include themify() {
        color: t('color-grey');
      }
    }
  }
}
