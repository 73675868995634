@import 'src/assets/variables';
@import 'src/assets/mixins';

.mkit-rcs-type-button {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 5px;
  border-radius: 8px;
  letter-spacing: 0.15px;
  font-size: 12px;
  line-height: 16px;
  height: 72px;
  width: 72px;
  box-sizing: border-box;
  cursor: pointer;
  opacity: 0.7;
  transition: opacity 0.3s ease-in-out;
  background-color: $color-white;

  @include themify() {
    color: t('color-deep-grey');
    border: 1px dashed t('color-light-grey');
  }

  &:hover {
    opacity: 1;
  }

  &:disabled {
    cursor: not-allowed;
    opacity: 0.4;
  }

  &__icon {
    width: 100%;
    height: 16px;
    margin-bottom: 6px;
  }
}
