@import 'src/assets/variables';
@import 'src/assets/mixins';

.create-sender-group-form {
  display: flex;
  flex-direction: column;
  height: 100%;

  &__header,
  &__sub-header,
  &__label {
    line-height: 25px;

    @include themify() {
      color: t('color-white');
    }
  }

  &__header {
    font-size: 18px;
    margin-bottom: 16px;
  }

  &__sub-header {
    font-size: 16px;
    margin-top: 4px;
    margin-bottom: 13px;
  }

  &__label {
    font-size: 14px;
    margin-bottom: 19px;
  }

  &__buttons-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: auto;

    &__group {
      display: flex;
    }
  }

  &__row {
    display: flex;
    width: 100%;
    box-sizing: border-box;
    padding-left: 12px;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
  }
}
