.home-screen {
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 24px;
  min-width: 0;
  overflow-y: auto;

  &_ds {
    font-family: var(--inter) !important;
    background: var(--bg5) !important;

    ::-webkit-scrollbar {
      width: 4px !important;
      height: 4px !important;
      border-radius: 2px !important;
    }

    ::-webkit-scrollbar-track {
      background: rgba(139, 139, 139, 0.2) !important;
      border-radius: 2px !important;
    }

    ::-webkit-scrollbar-thumb {
      border-radius: 2px !important;
      background: var(--grey30) !important;
    }

    button,
    input,
    textarea {
      font-family: var(--inter) !important;
    }
  }

  &_dashboard {
    padding: 0;

    .dashboard {
      overflow: auto;
    }
  }
}

.bp3-drawer {
  font-family: var(--inter) !important;
  ::-webkit-scrollbar {
    width: 4px !important;
    height: 4px !important;
    border-radius: 2px !important;
  }

  ::-webkit-scrollbar-track {
    background: rgba(139, 139, 139, 0.2) !important;
    border-radius: 2px !important;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 2px !important;
    background: var(--grey30) !important;
  }
}

.bp3-menu {
  font-family: var(--inter) !important;

  .bp3-menu-item {
    color: var(--color100) !important;
  }
}

.bp3-input-group input {
  font-family: var(--inter) !important;
}

.dropdown-field__content__overlay__item {
  font-family: var(--inter) !important;
}

.bp3-dialog {
  font-family: var(--inter) !important;
  ::-webkit-scrollbar {
    width: 4px !important;
    height: 4px !important;
    border-radius: 2px !important;
  }

  ::-webkit-scrollbar-track {
    background: rgba(139, 139, 139, 0.2) !important;
    border-radius: 2px !important;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 2px !important;
    background: var(--grey30) !important;
  }
}
