@import 'src/assets/mixins';

.account-block {
  @include themify() {
    display: flex;
    flex-direction: column;
    flex: 1;

    &__right-component__label {
      margin-right: 13px;
      font-size: 14px;
      line-height: 19px;
      color: t('color-grey');
    }
  }
}
