@import 'src/assets/variables';
@import 'src/assets/mixins';

.menu-dropdown {
  display: flex;
  position: relative;

  &__overlay {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 32px;
    right: 0;
    overflow-y: auto;
    max-height: 36px * 5; // height * item
    padding: 4px 0;
    z-index: 2;
    width: auto;
    border-radius: 4px;

    @include themify() {
      background-color: t('primary-color');

      &::-webkit-scrollbar-thumb {
        background-color: #575962;
      }
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 5px;
    }

    &__option {
      display: flex;
      align-items: center;
      height: 32px;
      padding: 0 9px;
      cursor: pointer;
      box-sizing: border-box;
      font-size: 14px;
      white-space: nowrap;
      opacity: 0.6;
      transition: opacity 0.2s ease-in-out, background-color 0.2s ease-in-out;

      @include themify() {
        background-color: t('primary-color');
        color: t('color-white');
      }

      @include themify() {
        &:hover {
          background-color: t('color-dark-grey-opacity');
          opacity: 1;
        }
      }

      &__img {
        height: 24px;
        width: 24px;
        margin-right: 10px;

        @include generator-img-filters();
      }
    }
  }
}
