.table {
  display: grid;
  grid-template-columns: repeat(7, minmax(min-content, 1fr)) 0 minmax(min-content, 1fr);
  grid-auto-rows: min-content;
  width: 100%;
  overflow: auto;
  box-shadow: var(--shadow2dp);
  color: var(--grey100);
}

.header {
  display: contents;
}

.header > div {
  grid-column: auto;
  padding: 8px;
  white-space: nowrap;
  background-color: #f2f2f2;
  color: var(--grey50);
  position: sticky;
  top: 0;
  z-index: 1500;
  overflow: hidden;
  font-size: 14px;
  border-left: 1px solid var(--white);
  cursor: pointer;
  user-select: none;
}

