@import 'src/assets/variables';
@import 'src/assets/mixins';

.mkit-rich-card {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 12px;
  overflow-x: hidden;
  $card: &;

  @include themify() {
    color: t('color-black');
  }

  &:hover #{$card}__header-tools {
    opacity: 1;
  }

  &__header-tools {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 8px;
    opacity: 0;
    transition: opacity 0.2s ease-in-out;

    &__axis {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 68px;
    }
  }

  &__left,
  &__right,
  &__vertical {
    display: grid;
    margin: 0 auto;
    width: 306px;
  }

  &__vertical {
    grid-template-areas:
      'image'
      'text'
      'suggestions';
  }

  &__left {
    grid-template-areas:
      'image text'
      'image text'
      'image suggestions';
  }

  &__right {
    grid-template-areas:
      'text image'
      'text image'
      'suggestions image';
  }

  &__text {
    display: flex;
    flex-direction: column;
    padding: 15px;
    transition: border-color 0.3s ease-in-out;
    grid-area: text;

    @include themify() {
      border: 2px solid t('color-light-grey');
    }

    &:focus-within {
      @include themify() {
        border-color: t('accent-color');
      }
    }

    &_left,
    &_right {
      min-height: 230px;
    }
  }

  &__suggestions-inside {
    display: flex;
    flex-direction: column;
    grid-area: suggestions;
  }

  &__suggestions {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin-top: 12px;
    padding: 0 24px;

    div {
      margin: 2px 4px 2px 4px;
    }
  }

  &_in-carousel {
    padding: 0;
    overflow-x: unset;
  }

  &_small-width {
    #{$card} {
      &__vertical {
        width: 270px;
      }

      &__suggestions {
        padding: 0 42px;
      }
    }
  }
}
