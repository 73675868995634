@import 'src/assets/variables';
@import 'src/assets/mixins';

.registration-form {
  display: flex;
  width: 100%;
  flex-direction: column;
  margin-bottom: 24px;

  &__columns-wrapper {
    display: flex;
    width: 100%;
  }

  &__column-left,
  &__column-right {
    display: flex;
    flex: 1;
    flex-direction: column;
    margin-bottom: 24px;
  }

  &__column-left {
    margin-right: 13px;
    align-items: flex-end;

    &__captcha-wrapper {
      display: flex;
      align-items: center;
      width: 50%;
      height: 46px;
      justify-content: space-between;
      border-radius: 4px;

      @include themify() {
        background-color: rgba(255, 255, 255, 0.8);
      }

      &__captcha {
        margin: 0 auto;
      }
    }
  }

  &__column-right {
    margin-left: 13px;
    align-items: flex-start;
  }

  &__row {
    display: flex;
    width: 100%;
    align-items: center;
    margin-bottom: 12px;
  }
}
