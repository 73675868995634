@import 'src/assets/mixins';

.campaign-create-general{
    &__switch {
        &__label{
            display: inline-block;
            margin-left: 11px;
            font-size: 14px;
            margin-right: 11px;

            @include themify() {
                color: t('color-grey');
            }
        }
    }

    .ant-input {
        @include themify() {
            border: t('border-style');
            color: t('color-white');
            background-color: transparent;
            box-shadow: none;
        }
    }
    .ant-input-disabled {
        @include themify() {
            background-color: unset !important;
            opacity: 0.4 !important;
        }
    }
}
