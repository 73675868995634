.audit-screen-container {
  display: flex;
  flex-direction: column;
  min-height: 100%;
  width: 100%;

  .audit-screen {
    flex: 1;
  }
}
