@keyframes spinner-pulse {
  0% {
    transform: scale(1.5);
    opacity: 0.2;
  }

  100% {
    transform: scale(2);
    opacity: 0;
  }
}

@keyframes spinner-pulse2 {
  0% {
    transform: scale(0.3);
    opacity: 0.6;
  }

  100% {
    transform: scale(2);
    opacity: 0;
  }
}

@keyframes switcher {
  0% {
    transform: scaleX(0);
  }

  50% {
    transform: scaleX(1.01);
  }

  100% {
    transform: scaleX(1);
  }
}

@keyframes turner {
  from {
    transform: rotate(0turn);
  }

  to {
    transform: rotate(1turn);
  }
}

@keyframes spin {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes blinker {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}
