@import 'src/assets/variables';
@import 'src/assets/mixins';

.creating-attributes-form {
  display: flex;
  flex-direction: column;
  height: 100%;

  &__header {
    font-size: 18px;
    line-height: 25px;
    font-family: $primary-font-regular;
    margin-bottom: 8px;

    @include themify() {
      color: t('color-white');
    }
  }

  &__attributes-settings {
    margin-top: 15px;
    font-family: $primary-font-regular;
    font-size: 16px;
    line-height: 22px;

    @include themify() {
      color: t('color-white');
    }
  }

  &__no-configuration {
    font-family: $primary-font-regular;
    font-size: 14px;
    line-height: 19px;
    margin-top: 19px;
    margin-left: 10px;

    @include themify() {
      color: t('color-grey');
    }
  }

  &__buttons-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: auto;

    &__right {
      display: flex;

      & > button {
        min-width: 175px;

        &:first-child {
          margin-right: 12px;
        }
      }
    }
  }
}
