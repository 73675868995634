@import 'src/assets/variables';
@import 'src/assets/mixins';

.dropdown-report-field {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex: 1;
  $dropdown-field: &;

  &__label {
    margin-right: 12px;

    @include themify() {
      color: t('color-grey');
    }
  }

  &__content {
    display: flex;
    flex: 1;
    position: relative;

    &__dropdown-indicator {
      opacity: 0.6;
      position: absolute;
      right: 10px;
      top: 50%;
      transform: translate(0, -50%);

      @include themify() {
        filter: t('img-invert');
      }
    }
  }

  &__control {
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-width: 1px;
    height: 36px;
    flex: 1;
    padding: 0 14px 0 9px;
    cursor: pointer;
    border-radius: 4px;

    @include themify() {
      color: t('color-white');
      border: t('border-style');
    }

    &::placeholder {
      font-family: $primary-font-italic;
    }

    &:disabled {
      cursor: not-allowed;
      opacity: 0.4;
    }

    &__icon {
      max-width: 20px;
      margin-right: 10px;

      @include generator-img-filters();
    }
    &__crrosicon{
      max-width: 12px;
      margin-right: 20px;
      opacity: 0.6;

      @include generator-img-filters();
    }

    &__value {
      font-family: $primary-font-regular;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      margin-right: 5px;
      flex: 1;
      text-align: left;
      // transition: opacity 0.3s ease-in-out;

      &_placeholder {
        font-family: $primary-font-italic;
        opacity: 0.4;
      }
    }
  }

  &__overlay {
    position: absolute;
    top: 36px;
    left: 0;
    right: 0;
    overflow-y: auto;
    max-height: 36px * 5; // height * item
    z-index: 2;

    @include themify() {
      background-color: t('primary-color');

      &::-webkit-scrollbar-thumb {
        background-color: #575962;
      }
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 5px;
    }
  }

  &_active #{$dropdown-field} {
    &__control {
      &__value {
        &_placeholder {
          opacity: 0.6;
        }
      }
    }

    &__content {
      &__dropdown-indicator {
        transform: rotate(180deg) translate(0, 50%);
      }
    }
  }

  &_direction-top #{$dropdown-field}__overlay {
    bottom: 34px;
    top: unset;
  }

  &_underline #{$dropdown-field} {
    &__control {
      border: none;
      border-radius: unset;

      @include themify() {
        border-bottom: t('border-style');
      }
    }
  }

  &_without-border #{$dropdown-field} {
    &__control {
      border: none;
    }
  }
}
