@import 'src/assets/variables';
@import 'src/assets/mixins';

.create-report-block {
  @include themify() {
    display: flex;
    flex-direction: column;
    flex: 1;

    &__card {
      display: flex;
      flex: 1;
      padding: 24px;
      background: t('secondary-color');
      box-shadow: t('box-shadow');
      margin-top: 10px;

      &__title {
        color: t('color-white');
        font-size: 18px;
        line-height: 25px;
        margin-bottom: 24px;
      }

      &__item {
        display: flex;
        flex-direction: column;
        flex: 1;
        overflow-y: hidden;

        &__list {
          height: 100%;
          overflow-y: auto;
        }
      }

      &__line {
        height: 100%;
        width: 1px;
        background: t('color-grey');
        opacity: 0.4;
        margin: 0 24px;
      }
    }
  }
}
