@import 'src/assets/variables';
@import 'src/assets/mixins';

.mkit-media-miniature-block {
  display: grid;
  width: 100%;
  grid-row-gap: 24px;
  grid-template-columns: repeat(auto-fill, minmax(165px, 1fr));
  grid-auto-columns: auto;
  margin-top: 35px;
  overflow-y: auto;

  &__full {
    display: flex;
    flex: 1;
    max-height: 75vh;
  }

  &__item {
    width: 165px;
    height: 165px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    box-sizing: border-box;
    $item: &;

    &_small {
      width: 146px;
      height: 146px;
    }

    &_selected {
      @include themify() {
        border: 2px solid t('color-white') !important;
      }
    }

    &_border {
      @include themify() {
        border: 1px solid t('color-basic-inactive');
      }

      #{$item}__image {
        width: 50%;
        height: 50%;
        object-fit: initial;

        @include themify() {
          filter: t('img-invert');
        }
      }
    }

    &:hover #{$item}__control-wrapper {
      opacity: 1;
    }

    &__image {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    &__control-wrapper {
      display: flex;
      flex-direction: column;
      position: absolute;
      width: 100%;
      height: 100%;
      background: url("../../../assets/icons/control-bg.svg") center no-repeat;
      background-size: 100% 100%;
      z-index: 2;
      top: 0;
      left: 0;
      opacity: 0;
      transition: opacity 0.3s ease-in-out;
      box-sizing: border-box;
      padding: 8px 12px;

      &__text {
        font-size: 12px;
        line-height: 16px;
        letter-spacing: 0.15px;
        font-family: notosans-regular;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        color: rgba($color-white, 0.9);
      }

      &__buttons-wrapper {
        display: flex;
        align-items: center;
        margin: auto auto 0;
      }
    }
  }
}
