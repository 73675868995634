@import 'src/assets/variables';
@import 'src/assets/mixins';

.auth-field {
  display: flex;
  flex-direction: column;
  min-width: 200px;
  $auth-field: &;

  &__label {
    font-size: 14px;
    margin-bottom: 12px;
  }

  &__input {
    width: 100%;
    box-sizing: border-box;
    padding: 11px 19px;
    line-height: 22px;
    font-size: 18px;
    letter-spacing: 0.15px;
    transition: color 0.3s ease-in-out;
    border-radius: 4px;

    @include themify() {
      background: white;
      color: t('dark-blue-color');
      border: 1px solid t('color-border');

      &::placeholder {
        color: rgba(t('dark-blue-color'), 0.8);
      }

      &:-webkit-autofill,
      &:-webkit-autofill:focus {
        -webkit-text-fill-color: t('dark-blue-color');
      }
    }

    &:-webkit-autofill,
    &:-webkit-autofill:focus {
      -webkit-box-shadow: none;
      transition: background-color 5000s ease-in-out 0s, color 5000s ease-in-out 0s;
    }
  }

  &_error #{$auth-field}__input {
    @include themify() {
      border-color: t('color-attention');
      color: t('color-attention');

      &::placeholder {
        color: t('color-attention');
      }
    }
  }
}
