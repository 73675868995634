@import 'src/assets/variables';
@import 'src/assets/mixins';

.rates-group {
  width: 100%;
  display: flex;
  flex-direction: column;

  &__button {
    display: flex;
    cursor: pointer;

    @include themify() {
      color: t('accent-color');
    }

    &__right-icon {
      margin-left: 8px;
    }
  }

  &__items {
    display: flex;
    flex-direction: column;

    &__item {
      &__left-text {
        @include themify() {
          color: t('accent-color');
        }
      }
    }
  }
}
