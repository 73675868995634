@import 'src/assets/variables';
@import 'src/assets/mixins';

.condition-node {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  background-color: grey;
  border-radius: 6px;
  border: 2px solid;
  min-width: 300px;
  max-width: 400px;
  $condition-node: &;

  @include themify() {
    background-color: t('fff-07-222430');
    border-color: t('fff-00-222430');
  }

  &__label {
    position: absolute;
    top: -28px;
    background: #1bd741;
    border: 2px solid;
    border-radius: 8px 8px 0 0;
    font-weight: 600;
    font-size: 10px;
    line-height: 14px;
    padding: 5px 48px;
    left: 50%;
    transform: translate(-50%);
    text-transform: uppercase;
    white-space: nowrap;
    color: $primary;

    &_start {
      background-color: $green;
    }

    &_failover {
      background-color: $orange;
    }

    &_response-timeout {
      background-color: $pink;
    }

    &_stuck-timeout {
      background-color: $blue;
    }

    &_opt-out {
      background-color: $red;
    }

    @include themify() {
      border-color: t('fff-00-222430');
    }
  }

  &__header {
    display: flex;
    align-items: center;
    position: relative;
    padding: 9px 15px;
    border-radius: 4px 4px 0 0;

    @include themify() {
      background-color: t('222430-08-222430');
      border-bottom: 1px solid t('color-white');
    }

    &__ports-in {
      display: flex;
      justify-content: space-between;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;

      &__left {
        justify-content: flex-start;
        align-items: center;
        margin-left: -1px;

        .port {
          transform: translate(-50%, 0);
        }
      }

      &__center {
        justify-content: center;
        align-self: flex-start;
        margin-top: -1px;

        .port {
          transform: translate(0, -50%);
        }
      }

      &__right {
        justify-content: flex-end;
        align-items: center;
        margin-right: -1px;

        .port {
          transform: translate(50%, 0);
        }
      }

      &__left,
      &__center,
      &__right {
        display: flex;
        flex: 1;

        .port-in__circle {
          opacity: 0;
        }

        &:hover .port-in__circle {
          opacity: 1;
        }
      }
    }

    &__icon {
      width: 24px;
      height: 24px;
      margin-right: 8px;
      pointer-events: none;

      &_condition {
        filter: brightness(0) invert(1);
      }
    }

    &__label {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      color: $white;
    }
  }

  &__item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-size: 10px;
    line-height: 14px;
    padding: 8px 16px;
    position: relative;

    &__text {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    @include themify() {
      border-bottom: 1px solid t('fff-00-222430');
    }

    &__condition {
      display: flex;
      align-items: center;

      &:not(:first-child) {
        margin-top: 8px;
      }

      &__type {
        font-family: $primary-font-bold;
        margin-right: 19px;
      }

      &__value {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }

  &__else-block {
    display: flex;
    align-items: center;
    padding: 8px 16px;
    font-family: $primary-font-bold;
    font-size: 10px;
    line-height: 14px;
  }

  &__item,
  &__else-block {
    position: relative;
    color: $primary;
  }

  &__port-in {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 11px;
    left: -9px;
  }

  &:hover #{$condition-node} {
    &__clone,
    &__delete {
      opacity: 1;
    }
  }

  &__clone,
  &__delete {
    position: absolute;
    opacity: 0;
    top: -34px;
    right: -30px;
    height: 32px;
    width: 32px;
    background-position: center;
    background-repeat: no-repeat;
    cursor: pointer;

    &_hover {
      opacity: 1;
    }

    @include themify() {
      filter: t('img-white');
    }
  }

  &__clone {
    right: 0;
    top: -30px;
    height: 24px;
    width: 24px;
    background-size: 16px 16px;
    background-image: url('../../../assets/icons/copy.svg');
  }

  &__delete {
    background-image: url('../../../assets/icons/cross-dark.svg');
  }

  //& > button {
  //  position: absolute;
  //  top: -30px;
  //  right: -30px;
  //}

  &_selected {
    border-radius: 10px;

    @include themify() {
      box-shadow: -5px 10px 65px 15px t('accent-color');
      border-color: t('accent-color');
    }
  }
}
