@import 'src/assets/variables';
@import 'src/assets/mixins';

.basic-field {
  display: flex;
  width: 100%;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  font-family: $primary-font-regular;
  $basic-field: &;

  &__label {
    font-size: 14px;
    line-height: 19px;
    letter-spacing: 0.15px;
    transition: color 0.3s ease-in-out;
    margin-right: 12px;

    @include themify() {
      color: t('color-grey');
    }
  }

  @include themify() {
    &:focus-within #{$basic-field} {
      &__label {
        color: t('color-white');
      }
    }
  }

  &__input-wrapper {
    display: flex;
    min-width: 200px;
    flex: 1;
    position: relative;

    &__input {
      width: 100%;
      font-size: 14px;
      padding: 7px;
      letter-spacing: 0.15px;
      transition:
        border-color 0.3s ease-in-out,
        color 0.3s ease-in-out;
      $input: &;

      @include themify() {
        color: t('color-grey');
        border-bottom: t('border-style');

        &:focus {
          border-color: t('color-white');
          color: t('color-white');
        }

        &::placeholder {
          color: t('color-grey');
        }
      }

      &::placeholder {
        font-family: $primary-font-italic;
        font-size: 14px;
        line-height: 19px;
        letter-spacing: 0.15px;
      }

      &:-webkit-autofill,
      &:-webkit-autofill:focus {
        -webkit-box-shadow: none;
        transition: background-color 5000s ease-in-out 0s, color 5000s ease-in-out 0s;
      }
    }

    &__error {
      position: absolute;
      left: -25px;
      top: 8px;
    }
  }

  &__reset-button {
    margin-left: 13px;
    margin-right: 5px;
    width: 24px;
    opacity: 0.6;
    transition: opacity 0.3s ease-in-out;
    cursor: pointer;

    &:hover {
      opacity: 0.8;
    }
  }

  &_without-error #{$basic-field}__input-wrapper {
    padding-left: 0;
  }

  &_disabled #{$basic-field} {
    &__input-wrapper__input {
      cursor: not-allowed;
      opacity: 0.4;
    }
  }

  &_with-border {
    #{$basic-field} {
      &__label {
        display: flex;
        flex: 3;
        margin-right: 24px;
      }

      &__input-wrapper {
        flex: 7;

        &__error {
          top: 7px;
        }
      }
    }

    input {
      border-radius: 4px;

      @include themify() {
        border: t('border-style');
      }
    }
  }

  &_border {
    #{$basic-field}__input-wrapper__input {
      border-radius: 4px;

      @include themify() {
        border: t('border-style');
      }
    }
  }

  &_white {
    box-sizing: border-box;
    border-radius: 4px;

    @include themify() {
      background: t('color-white');
      border: 1px solid t('color-border');
    }

    input {
      padding: 11px 19px;
      line-height: 22px;
      border-bottom: none;

      @include themify() {
        color: t('dark-blue-color');

        &:focus {
          color: t('dark-blue-color');
        }

        &::placeholder {
          color: t('dark-blue-color');
        }
      }
    }
  }

  &_error {
    @include themify() {
      border-color: t('color-attention');

      #{$basic-field} {
        &__label {
          color: t('color-attention');
        }

        &__input-wrapper__input {
          border-color: t('color-attention');
          color: t('color-attention');
        }
      }
    }
  }
}
