@import 'src/assets/variables';
@import 'src/assets/mixins';

.query-error-modal {
  display: flex;
  flex-direction: column;

  &__description {
    display: flex;
    flex: 1;
    padding: 24px 0;
    font-family: $primary-font-regular;
    font-size: 14px;
    line-height: 22px;
    white-space: pre-wrap;

    @include themify() {
      color: t('color-grey');
    }
  }
}
