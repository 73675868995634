@import 'src/assets/variables';
@import 'src/assets/mixins';

.text-area {
  display: flex;
  align-items: center;
  position: relative;
  $textarea: &;

  &__label {
    display: flex;
    align-self: flex-start;
    flex: 1;
    font-size: 14px;
    line-height: 19px;
    letter-spacing: 0.15px;
    transition: color 0.3s ease-in-out;
    margin-right: 12px;

    @include themify() {
      color: t('color-grey');
    }
  }

  &__input {
    resize: none;
    font-size: 14px;
    line-height: 16px;
    font-family: $roboto-font-regular;
    letter-spacing: 0.15px;
    transition: color 0.3s ease-in-out;
    width: 100%;
    color: $color-white_light;

    & > * {
      padding: 20px;
    }

    @include themify() {
      &::placeholder {
        color: t('color-deep-grey');
      }
    }
  }

  &_border #{$textarea}__input {
    border-radius: 8px;
    padding: 12px 14px;

    @include themify() {
      border: 2px solid t('color-light-grey');
    }
  }

  &_with-border #{$textarea} {
    &__label {
      margin-top: 8px;
    }

    &__input {
      padding: 7px;
      border-radius: 4px;

      @include themify() {
        color: t('color-grey');
        border: t('border-style');
      }
    }
  }
}
