.dialog-screen {
  display: flex;
  flex-direction: column;
  min-height: 100%;
  width: 100%;

  .conversations-layout {
    flex: 1;
    max-height: calc(100vh - 48px);
  }
}
