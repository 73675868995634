@import 'src/assets/variables';
@import 'src/assets/mixins';

.mkit-suggestion-item {
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  padding: 12px 5px;
  box-sizing: border-box;
  letter-spacing: 0.15px;
  font-family: $roboto-font-regular;
  font-size: 14px;
  line-height: 16px;
  border-radius: 26px;
  min-width: 80px;
  min-height: 50px;
  user-select: none;
  background-color: white;
  $suggestion: &;

  @include themify() {
    border: 2px solid $color-light-grey;
  }

  &_with-icon {
    min-width: 110px;
    justify-content: flex-start;

    #{$suggestion}__input {
      margin-top: 2px;
    }
  }

  &_purple {
    border: none;
    padding: 15px 5px;

    @include themify() {
      background-color: t('color-purple');
    }

    #{$suggestion} {
      &__input {
        font-weight: 600;
        font-size: 16px;

        @include themify() {
          color: $color-white;
        }
      }

      &__control-wrapper {
        min-width: 30px;
        right: 5px;
        filter: grayscale(1);

        @include themify() {
          background-color: transparent;
        }
      }
    }
  }

  &_inside {
    width: 100%;
    max-width: 500px;
    justify-content: center;
    min-height: 42px;
    border-radius: 0;

    @include themify() {
      color: t('accent-color');
      border-bottom: none;
      border-top: none;
    }

    @include themify() {
      &::after {
        content: '';
        position: absolute;
        bottom: 1px;
        height: 1px;
        width: 80%;
        margin: 0 auto;
        background-color: t('color-light-grey');
      }
    }

    &:last-of-type {
      @include themify() {
        border-bottom: 2px solid t('color-light-grey');
      }

      &::after {
        content: none;
      }
    }

    #{$suggestion} {
      &__input {
        @include themify() {
          color: t('accent-color');
        }
      }

      &__control-wrapper {
        right: 8px;
        top: 5px;
        bottom: 5px;
      }
    }
  }

  &_last-vertical {
    border-radius: 0 0 8px 8px;
  }

  &_last-left {
    border-radius: 0 0 8px 0;
  }

  &_last-right {
    border-radius: 0 0 0 8px;
  }

  &__icon {
    margin-right: 8px;
    padding-left: 5px;
    height: 20px;
  }

  &__input {
    min-width: 40px;
    font-size: 14px;
    line-height: 16px;
    font-family: $roboto-font-regular;
    letter-spacing: 0.15px;
    text-align: center;
    outline: none;
    white-space: nowrap;

    @include themify() {
      color: $secondary-color;
    }
  }

  &__control-wrapper {
    position: absolute;
    right: 2px;
    bottom: 3px;
    top: 3px;
    display: flex;
    align-items: center;
    border-radius: 24px;
    transition: opacity 0.3s ease-in-out;
    padding: 0 4px;
    z-index: 21;
    min-width: 25px;

    @include themify() {
      background-color: $color-white;
    }
  }

  &__disabler {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    z-index: 20;
  }
}
