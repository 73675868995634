@import 'src/assets/mixins';

.checkable-tag {
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  border-radius: 2px;
  padding: 3px 5px;
  opacity: 0.5;
  cursor: pointer;
  background: #575962;

  @include themify() {
    color: t('color-white');
  }

  &.ant-tag-checkable-checked{
    opacity: 1;
    font-weight: bold;

    @include themify() {
      color: $color-white;
      background: t('accent-color');
    }
  }
}
