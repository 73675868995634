.rate-plans-layout {
  display: flex;
  flex: 1;
  position: relative;
  z-index: 1;

  > div {
    display: flex;
    flex: 1;
    max-width: 100%;
  }
}
