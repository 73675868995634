@import 'src/assets/variables';
@import 'src/assets/mixins';

.mkit-tag-wrapper {
  display: flex;
  align-items: center;

  &_hidden {
    &::after {
      content: "";
      width: 10px;
      height: 24px;
      opacity: 0.6;
      border-top-right-radius: 12.5px;
      border-bottom-right-radius: 12.5px;
      transform: translateX(-6px);
      transition: all 0.3s ease-in-out;
    }

    @include themify() {
      &::after {
        background: #7ce95b;
        border-right: 1.5 px solid t('color-white');
      }
    }
  }

  &__tag {
    height: 24px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    border-radius: 8px;
    padding: 3px 5px;
    word-break: keep-all;
    cursor: default;
    font-family: $primary-font-regular;
    font-size: 12px;
    line-height: 16px;
    transition: all 0.3s ease-in-out;
    white-space: nowrap;

    @include themify() {
      color: $color-white;
      background-color: t('accent-color');

      &_primary {
        color: t('accent-color');
      }
    }

    &_disabled {
      opacity: 0.7;
    }

    //&:hover {
    //  color: rgba(255, 255, 255, 0.8);
    //  border: 1.5px solid rgba(255, 255, 255, 0.8);
    //
    //  .tag-wrapper__tag__img {
    //    opacity: 0.8;
    //  }
    //}

    span {
      margin-right: 5px;
    }

    img {
      //opacity: 0.6;
      //transition: opacity 0.3s ease-in-out;
    }

    &__img {
      height: 12px;
      margin: 0 5px;
    }

    &__close-img {
      width: 14px;
      height: 14px;
      cursor: pointer;

      @include themify() {
        filter: t('img-white');
      }

      &:hover {
        opacity: 0.8;
      }
    }
  }
}
