@import 'src/assets/mixins';

.media-header {
  @include themify() {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-family: notosans-regular;
    font-size: 22px;
    line-height: 30px;
    color: t('color-white');
    margin-bottom: 15px;

    &__right {
      display: flex;
      align-items: center;

      &__dropdown {
        opacity: 0.6;
        transition: opacity 0.3s ease-in-out;
        cursor: pointer;

        &:hover {
          opacity: 1;
        }
      }
    }
  }
}
