@import 'src/assets/variables';

.financial-documents-layout {
  display: flex;
  flex: 1;
  position: relative;

  > div {
    display: flex;
    flex: 1;
  }
}
