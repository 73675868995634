@import 'src/assets/mixins';

.campaign-create-launch-time{
    &__type {
        display: flex;
        align-items: center;
        justify-content: space-between;

        &__label {
            font-size: 14px;
            line-height: 19px;
            margin-right: 8px;

            @include themify() {
                color: t('color-grey');
            }
        }

        &__buttons {
            width: 100%;
            max-width: 282px;
            display: flex;

            button {
                flex: 1;
            }

            & > button:not(:last-child) {
                margin-right: 12px;
            }
        }
    }

    &__time {
        margin-top: 12px;
    }

    .inline-calendar {
        margin-top: 5px;
    }

    &__disabled-block{
        opacity: .6;
        pointer-events: none;
    }

    &__switch {
        &__label{
            display: inline-block;
            margin-left: 11px;
            font-size: 14px;
            margin-right: 11px;

            @include themify() {
                color: t('color-grey');
            }
        }
    }

    &__recurrent{
        .datepicker{
            width: 100%;
        }

        &__text{
            font-size: 16px;
            line-height: 22px;
            margin: 18px 0;

            @include themify() {
                color: t('color-grey');
            }
        }

        &__switch{
            padding-top: 6px;
            display: flex;
            align-items: center;

            @include themify() {
                color: t('color-grey');
            }

            &__control{
                margin: 0 8px!important;
            }
        }

        &__block-title{
            margin: 14px 0;

            @include themify() {
                color: t('color-grey');
            }
        }

        &__tags{
            margin: 10px 0;
            .checkable-tag{
                width: calc(100% / 7 - 1.88px);
                margin-right: 2px;
                margin-bottom: 2px;
                &:nth-child(7n + 7){
                    margin-right: 0;
                }
            }
        }
    }
}
