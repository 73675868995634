@import 'src/assets/mixins';

.menu-item{
  &__img {
    height: 24px;
    width: 24px;
    // @include themify() {
    //   filter: t('img-invert');
    // }

    @include generator-img-filters();
  }
}

.ant-dropdown-menu-item {
  display: flex;
  align-items: center;
}
